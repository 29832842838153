import React, { useContext, useEffect, useState } from 'react';
import { ILanguageMetadata, ILanguageService, QuinoCoreServiceSymbols } from '@quino/core';
import { SHARED_SERVICE_IDENTIFIER } from '../../../../../../ioc/sharedIdentifiers';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { SelectBox } from 'devextreme-react/select-box';
import { useService } from '../../../../../../ioc/hook/useService';
import { IProfileService } from '../../../../../../api/IProfileService';
import { ProfileContext } from '../../../../../../contexts/profileContext';
import { ILocationService } from '../../../../../../navigation';
import { GlobalErrorContext } from '../../../../../Error/GlobalErrorContext';

type TStyles = {
    throbbingContainer: string;
    formControl: string;
};

const styles: TStyles = require('./LanguageSwitcher.less');

export const LanguageSwitcher: React.FunctionComponent = () => {
    const languageService = useService<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService);
    const profileService = useService<IProfileService>(SHARED_SERVICE_IDENTIFIER.IPROFILESERVICE);
    const locationService = useService<ILocationService>(
        SHARED_SERVICE_IDENTIFIER.ILOCATIONSERVICE
    );

    const [profileData, setProfileData] = useContext(ProfileContext);
    const [, setGlobalError] = useContext(GlobalErrorContext);

    const [languageCode, setLanguageCode] = useState(profileData.languageCode);
    const [isLoading, setIsLoading] = useState(false);
    const [languages, setLanguages] = useState<ILanguageMetadata[]>();

    useEffect(
        () => {
            (async () => {
                try {
                    const languages = await languageService.getLanguageListAsync();
                    setLanguages(languages);
                } catch (e) {
                    setGlobalError({ error: e, title: 'Language Switcher Error', logout: false });
                }
            })();
        },
        [languageService]
    );

    const handleChange = async (languageCode: string) => {
        if (await locationService.canChangeLocation()) {
            setLanguageCode(languageCode);
            setIsLoading(true);
            try {
                await profileService.updateProfileInformationAsync({
                    languageCode,
                });
                setProfileData && setProfileData({ ...profileData, languageCode });
            } catch (e) {
                setGlobalError({ error: e, title: 'Language Switcher Error', logout: false });
            } finally {
                window.location.reload();
            }
        }
    };

    if (!languages) {
        return (
            <div className={styles.throbbingContainer}>
                <LoadIndicator height={32} width={32} />
            </div>
        );
    }

    if (languages.length <= 1) {
        return null;
    }

    return (
        <div className={styles.formControl}>
            <SelectBox
                value={languageCode}
                disabled={isLoading}
                onValueChanged={(event: any) => handleChange(event.value)}
                dataSource={languages}
                displayExpr={'caption'}
                valueExpr={'name'}
            />
        </div>
    );
};
