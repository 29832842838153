import { TSharedTranslationKey } from 'performa-shared';

export const defaultPerformaNetTranslations = {
    'literal.CustomLiterals.PNet.Documents.Heading':
        'Für die Bearbeitung der PerformaNET-Dokumente wurde ein separates Browserfenster geöffnet.',
    'literal.CustomLiterals.PNet.Documents.Text':
        'Sollte sich das Fenster nicht automatisch öffnen, so klicken Sie bitte auf ',
    'literal.CustomLiterals.PNet.Documents.LinkText': 'diesen Link',
    'literal.CustomLiterals.PNet.Documents.NoDocumentStorage':
        'Für Ihren Kundenbereich ist keine Dokumentenablage konfiguriert.',
    'literal.CustomLiterals.PNet.MainMenu.Projects': 'Projektmanagement',
    'literal.CustomLiterals.PNet.MainMenu.ActualProjects': 'Projekte',
    'literal.CustomLiterals.PNet.MainMenu.Current': 'Aktuell',
    'literal.CustomLiterals.PNet.MainMenu.Events': 'Events',
    'literal.CustomLiterals.PNet.MainMenu.Participations': 'Teilnahmen',
    'literal.CustomLiterals.PNet.MainMenu.Operation': 'Betrieb',
    'literal.CustomLiterals.PNet.MainMenu.Tickets': 'Tickets',
    'literal.CustomLiterals.PNet.MainMenu.Appointments': 'Termine',
    'literal.CustomLiterals.PNet.MainMenu.TestCases': 'Testfälle',
    'literal.CustomLiterals.PNet.MainMenu.Documents': 'Dokumente',
    'literal.CustomLiterals.PNet.MainMenu.LogBook': 'Logbuch',
    'literal.CustomLiterals.PNet.MainMenu.Monitor': 'Monitor',
    'literal.CustomLiterals.PNet.MainMenu.Licences': 'Lizenzübersicht',
    'literal.CustomLiterals.PNet.MainMenu.Incident': 'Anfragen',
    'literal.CustomLiterals.PNet.MainMenu.MaintenanceCheck': 'Wartungscheck',
    'literal.CustomLiterals.PNet.MainMenu.RemainingWork': 'Restarbeiten',
    'literal.CustomLiterals.PNet.MainMenu.Specialities': 'Spezialitäten',
    'literal.CustomLiterals.PNet.MainMenu.Contact': 'Ansprechpartner',
    'literal.CustomLiterals.PNet.MainMenu.ThirdParty': 'Drittpartner',
    'literal.CustomLiterals.PNet.MainMenu.Quote': 'Angebote',
    'literal.CustomLiterals.PNet.MainMenu.Product': 'Produkt',
    'literal.CustomLiterals.PNet.MainMenu.PerformXWikipedia': 'PerformX Wikipedia',
    'literal.CustomLiterals.PNet.Link.Heading':
        'Der Link wurde in einem separatem Browserfenster geöffnet.',
};

export type TPerformaNetTranslationKey =
    | TSharedTranslationKey
    | keyof typeof defaultPerformaNetTranslations;

/**
 * proxy function to type check translation key
 * @param key
 */
export const tKey = (key: TPerformaNetTranslationKey): TPerformaNetTranslationKey => key;
