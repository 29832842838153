// noinspection SpellCheckingInspection
export const SHARED_SERVICE_IDENTIFIER = {
    ISETTINGS: Symbol.for('ISettings'),
    IPERFORMAURLMANAGER: Symbol.for('IPerformaUrlManager'),
    IPERFORMASERVER: Symbol.for('IPerformaServer'),
    ICONFIGURATIONSERVICE: Symbol.for('IConfigurationService'),
    ISESSIONPERSISTER: Symbol.for('ISessionPersister'),
    ILOGINWRAPPERSTATEHELPER: Symbol.for('ILoginWrapperStateHelper'),
    IPERFORMAAUTHSERVICE: Symbol.for('IPerformaAuthService'),
    ISESSIONSERVICE: Symbol.for('IPerformaSessionService'),
    ICRMCONTEXTWRAPPERSTATEHELPER: Symbol.for('ICrmContextWrapperStateHelper'),
    IIMAGEFETCHER: Symbol.for('IImageFetcher'),
    IFORGOTPASSWORDSTATEHELPER: Symbol.for('IForgotPasswordStateHelper'),
    IPASSWORDRESETSTATEHELPER: Symbol.for('IPasswordResetStateHelper'),
    IPASSWORDVALIDATOR: Symbol.for('IPasswordValidator'),
    IPROFILESERVICE: Symbol.for('IProfileService'),
    IROUTEHELPER: Symbol.for('IRouteHelper'),
    IROUTEEXTENSIONS: Symbol.for('IRouteExtensions'),
    IAUTHORIZATIONFACTORY: Symbol.for('IAuthorizationFactory'),
    IPASSWORDVALIDATORCONFIG: Symbol.for('IPasswordValidatorConfig'),
    IPASSWORDPOLICY: Symbol.for('IPasswordPolicy'),
    WINDOW: Symbol.for('Window'),
    IVERSIONINFOSERVICE: Symbol.for('IVersionInformationService'),
    IFRONTENDVERSIONINFO: Symbol.for('IFrontendVersionInfo'),
    ICHANGEPASSWORDSTATEHELPER: Symbol.for('IChangePasswordStateHelper'),
    IPROFILEFORMSTATEHELPER: Symbol.for('IProfileFormStateHelper'),
    ILAYOUTLOADER: Symbol.for('ILayoutLoader'),
    ILAYOUTCATALOGMANAGER: Symbol.for('ILayoutCatalogManager'),
    ICURRENTUSERPROFILECACHE: Symbol.for('ICurrentUserProfileCache'),
    ICUSTOMACTIONNAMESREADER: Symbol.for('ICustomActionNamesReader'),
    ICUSTOMACTIONPARSER: Symbol.for('ICustomActionParser'),
    IENTITYACTIONSEXTRACTOR: Symbol.for('IEntityActionsExtractor'),
    ICUSTOMACTIONSERVICE: Symbol.for('ICustomActionService'),
    ICONTEXTROUTECHECKER: Symbol.for('IContextRouteChecker'),
    ICONFIGURATIONLOADER: Symbol.for('IConfigurationLoader'),
    ILAYOUTNAMERESOLVER: Symbol.for('ILayoutNameResolver'),
    IDATAENTRYSTATUSSERVICE: Symbol.for('IDataEntryStatusService'),
    ISTORAGESERVICE: Symbol.for('IStorageService'),
    IPERFORMASTORAGE: Symbol.for('IPerformaStorage'),
    IPERFORMAFILEHANDLER: Symbol.for('IPerformaFileHandler'),
    IPERFORMASTORE: Symbol.for('IPerformaStore'),
    PERFORMADATAGRIDCONFIGURATIONSERVICE: Symbol.for('PerformaDataGridConfigurationService'),
    ILOCATIONSERVICE: Symbol.for('ILocationService'),
    IUPLOADERRORREGISTRATION: Symbol.for('IUploadErrorRegistration'),
    ILOGINFORMEXTENSION: Symbol.for('ILoginFormExtension'),
};
