import React from 'react';
import { PageRoot } from 'performa-shared';
import { getPerformaNetSiteMap } from './core/PerformaNetSiteMap';
import { PnetLoggedOutDecorator, PnetLogo } from './Components';

export class App extends React.Component {
    render() {
        return (
            <PageRoot
                headerIcon={PnetLogo}
                siteMap={getPerformaNetSiteMap()}
                loggedOutPageDecorator={PnetLoggedOutDecorator}
            />
        );
    }
}
