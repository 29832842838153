import React from 'react';
import { Button } from 'devextreme-react/button';

type TStyles = {
    button: string;
    root: string;
    wideModeRoot: string;
    icon: string;
    listItemText: string;
};

const styles: TStyles = require('./MenuItem.less');

interface IProps {
    caption: string;
    isWideMode: boolean;
    icon?: () => React.ReactElement;
    onClick?: () => unknown;
}

export const MenuItem: React.FC<IProps> = (props) => {
    return (
        <Button className={styles.button} onClick={props.onClick} stylingMode={'text'}>
            <div className={`${styles.root} ${props.isWideMode ? '' : styles.wideModeRoot}`}>
                <div className={styles.icon}>{props.icon && props.icon()}</div>
                {props.isWideMode && <div className={styles.listItemText}>{props.caption}</div>}
            </div>
        </Button>
    );
};
