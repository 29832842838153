import { IPasswordPolicy } from './IPasswordPolicy';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { IPasswordValidatorConfig } from '../IPasswordValidatorConfig';
import { QuinoCoreServiceSymbols } from '@quino/core';
import { IPerformaI18n } from '../../lang';

@injectable()
export class SpecialCharacterPolicy implements IPasswordPolicy {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPASSWORDVALIDATORCONFIG)
        private config: IPasswordValidatorConfig,
        @inject(QuinoCoreServiceSymbols.II18N) private i18n: IPerformaI18n
    ) {}

    async applyAsync(password: string): Promise<string[]> {
        if (
            (await this.doesNotNeedSpecialCharsAsync()) ||
            (await this.hasSpecialCharAsync(password))
        ) {
            return [];
        }

        const specialChars = await this.getSpecialCharsAsync();

        return [
            this.i18n.t('literal.CustomLiterals.PasswordPolicy.Errors.MissingSpecialCharacter', {
                allowedSpecialChars: specialChars.join(''),
            }),
        ];
    }

    private async doesNotNeedSpecialCharsAsync() {
        return !(await this.config.needsSpecialCharacterAsync());
    }

    private async hasSpecialCharAsync(password: string) {
        const specialChars = await this.getSpecialCharsAsync();
        return specialChars.some((specialChar: string) => {
            return password.indexOf(specialChar) > -1;
        });
    }

    private async getSpecialCharsAsync() {
        return await this.config.getAllowedSpecialCharactersAsync();
    }
}
