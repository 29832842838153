import {
    getAspectOrDefault,
    IFieldValidator,
    II18n,
    IMetaProperty,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { inject, injectable } from 'inversify';
import { PerformaValidationErrorCodes } from './PerformaValidationErrorCodes';
import { tKey } from '../../lang/TranslationKeys';
import { IValidationContext } from '@quino/core/dist/validations/IValidationContext';
import { IFieldValidationResult } from '@quino/core/dist/validations/IFieldValidationResult';

@injectable()
export class IbanValidator implements IFieldValidator {
    constructor(@inject(QuinoCoreServiceSymbols.II18N) private i18n: II18n) {}

    validate(
        field: IMetaProperty,
        value: any,
        context: IValidationContext
    ): IFieldValidationResult {
        if (!getAspectOrDefault(field, 'IBAN')) {
            return {};
        }

        if (!value) {
            return {};
        }

        if (typeof value !== 'string') {
            return this.getError(field);
        }

        if (!IbanValidator.validateFormat(value)) {
            return this.getError(field);
        }

        if (!IbanValidator.validateChecksum(value)) {
            return this.getError(field);
        }

        if (!IbanValidator.validateLengthForCountry(value)) {
            return this.getError(field);
        }

        return {};
    }

    private static validateLengthForCountry(data: string) {
        const iban = data.replace(/\s/g, '');
        const ibanLength = iban.length;
        const ibanCountry = iban.substr(0, 2);
        const ibanCountryLength = IbanValidator.getIbanLengthByCountry(ibanCountry);
        if (ibanCountryLength < 0) {
            // IBAN length can not be checked, e.g for countries where the IBAN is under development
            // https://en.wikipedia.org/wiki/International_Bank_Account_Number#IBAN_formats_by_country
            return true;
        }
        return ibanCountryLength == ibanLength;
    }

    /**
     * Regex taken from https://stackoverflow.com/a/44657292
     */
    private static validateFormat(data: string) {
        const regExPattern = /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/;
        return regExPattern.test(data);
    }

    private static validateChecksum(data: string) {
        return IbanValidator.iso7064Mod97_10(IbanValidator.iso13616Prepare(data)) === 1;
    }

    private getError(field: IMetaProperty) {
        return {
            fieldErrors: [
                {
                    fieldName: field.name,
                    errorCode: PerformaValidationErrorCodes.InvalidIban,
                    errorMessage: this.i18n.t(
                        tKey('literal.CustomLiterals.Validations.InvalidIban')
                    ),
                },
            ],
        };
    }

    /**
     * Code taken from https://github.com/arhs/iban.js
     *
     * Prepare an IBAN for mod 97 computation by moving the first 4 chars to the end and transforming the letters to
     * numbers (A = 10, B = 11, ..., Z = 35), as specified in ISO13616.
     *
     * @param {string} iban the IBAN
     * @returns {string} the prepared IBAN
     */
    private static iso13616Prepare(iban: string) {
        const A = 'A'.charCodeAt(0);
        const Z = 'Z'.charCodeAt(0);

        iban = iban.replace(/\s/g, '');
        iban = iban.toUpperCase();
        iban = iban.substr(4) + iban.substr(0, 4);

        return iban
            .split('')
            .map(function(n) {
                const code = n.charCodeAt(0);
                if (code >= A && code <= Z) {
                    // A = 10, B = 11, ... Z = 35
                    return code - A + 10;
                } else {
                    return n;
                }
            })
            .join('');
    }

    /**
     * Code taken from https://github.com/arhs/iban.js
     *
     * Calculates the MOD 97 10 of the passed IBAN as specified in ISO7064.
     *
     * @param iban
     * @returns {number}
     */
    // eslint-disable-next-line @typescript-eslint/camelcase
    private static iso7064Mod97_10(iban: string) {
        let remainder = iban;

        while (remainder.length > 2) {
            const block = remainder.slice(0, 9);
            remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
        }

        return parseInt(remainder, 10) % 97;
    }

    /**
     * See link for reference: https://en.wikipedia.org/wiki/International_Bank_Account_Number#IBAN_formats_by_country
     *
     * Returns the length of the iban code for a country
     *
     * @param countryCode
     * @returns {number}
     */
    private static getIbanLengthByCountry(countryCode: string) {
        switch (countryCode.toUpperCase()) {
            case 'AL':
                return 28;
            case 'AD':
                return 24;
            case 'AT':
                return 20;
            case 'AZ':
                return 28;
            case 'BH':
                return 22;
            case 'BY':
                return 28;
            case 'BE':
                return 16;
            case 'BA':
                return 20;
            case 'BR':
                return 29;
            case 'BG':
                return 22;
            case 'CR':
                return 22;
            case 'HR':
                return 21;
            case 'CY':
                return 28;
            case 'CZ':
                return 24;
            case 'DK':
                return 18;
            case 'DO':
                return 28;
            case 'TL':
                return 23;
            case 'EG':
                return 29;
            case 'SV':
                return 28;
            case 'EE':
                return 20;
            case 'FO':
                return 18;
            case 'FI':
                return 18;
            case 'FR':
                return 27;
            case 'GE':
                return 22;
            case 'DE':
                return 22;
            case 'GI':
                return 23;
            case 'GR':
                return 27;
            case 'GL':
                return 18;
            case 'GT':
                return 28;
            case 'HU':
                return 28;
            case 'IS':
                return 26;
            case 'IQ':
                return 23;
            case 'IE':
                return 22;
            case 'IL':
                return 23;
            case 'IT':
                return 27;
            case 'JO':
                return 30;
            case 'KZ':
                return 20;
            case 'XK':
                return 20;
            case 'KW':
                return 30;
            case 'LV':
                return 21;
            case 'LB':
                return 28;
            case 'LY':
                return 25;
            case 'LI':
                return 21;
            case 'LT':
                return 20;
            case 'LU':
                return 20;
            case 'MK':
                return 19;
            case 'MT':
                return 31;
            case 'MR':
                return 27;
            case 'MU':
                return 30;
            case 'MC':
                return 27;
            case 'MD':
                return 24;
            case 'ME':
                return 22;
            case 'NL':
                return 18;
            case 'NO':
                return 15;
            case 'PK':
                return 24;
            case 'PS':
                return 29;
            case 'PL':
                return 28;
            case 'PT':
                return 25;
            case 'QA':
                return 29;
            case 'RO':
                return 24;
            case 'LC':
                return 32;
            case 'SM':
                return 27;
            case 'ST':
                return 25;
            case 'SA':
                return 24;
            case 'RS':
                return 22;
            case 'SC':
                return 31;
            case 'SK':
                return 24;
            case 'SI':
                return 19;
            case 'ES':
                return 24;
            case 'SE':
                return 24;
            case 'CH':
                return 21;
            case 'TN':
                return 24;
            case 'TR':
                return 26;
            case 'UA':
                return 29;
            case 'AE':
                return 23;
            case 'GB':
                return 22;
            case 'VA':
                return 22;
            case 'VG':
                return 24;
            default:
                return -1;
        }
    }
}
