import { IconName } from './IconName';
import React from 'react';

interface IProps {
    name: IconName;
}

export const Icon: React.FC<IProps> = (props) => {
    return <i className={'material-icons'}>{props.name}</i>;
};
