import React, { useContext } from 'react';
import { AvatarSize } from '../../../CrmContext/CrmContextAvatar';
import { CrmContextAvatar } from '../../../CrmContext/CrmContextAvatar';
import { MenuItem } from './MenuItem';
import { CrmContext } from '../../../../contexts/crmContext/CrmContext';

interface IProps {
    isWideMode: boolean;
}

export const CrmContextMenuItem = ({ isWideMode }: IProps) => {
    const crmContext = useContext(CrmContext);

    const getIsCrmContextSelectable = (): boolean => crmContext.availableCrmContexts.length > 1;

    const { activeCrmContext } = crmContext;
    if (!activeCrmContext) {
        return null;
    }

    return (
        <MenuItem
            icon={() => (
                <CrmContextAvatar crmContext={activeCrmContext} avatarSize={AvatarSize.Small} />
            )}
            caption={activeCrmContext ? activeCrmContext.shortCaption : ''}
            isWideMode={isWideMode}
            onClick={getIsCrmContextSelectable() ? crmContext.showContextSelector : undefined}
        />
    );
};
