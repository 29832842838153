import { useEffect, useState } from 'react';
import { useService } from '../../ioc/hook/useService';
import { IConfigurationService } from '../IConfigurationService';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';

export const useWeakValidationMetaClasses = () => {
    const configurationService = useService<IConfigurationService>(
        SHARED_SERVICE_IDENTIFIER.ICONFIGURATIONSERVICE
    );

    const [weakValidationMetaClasses, setWeakValidationMetaClasses] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            const weakValidationCSV = await configurationService.getStringValueAsync(
                'client.AllowSavingUnvalidatedDataOnEntities'
            );

            if (weakValidationCSV) {
                setWeakValidationMetaClasses(weakValidationCSV.split(';'));
            }
        })();
    }, []);

    return weakValidationMetaClasses;
};
