import { ILayoutNameResolver } from './ILayoutNameResolver';
import { IDataService, QuinoCoreServiceSymbols } from '@quino/core';
import { IConfigurationService } from '../configuration';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';

@injectable()
export class LayoutNameResolver implements ILayoutNameResolver {
    private layoutNameCache: Map<string, Promise<string>>;

    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.ICONFIGURATIONSERVICE)
        private configService: IConfigurationService,
        @inject(QuinoCoreServiceSymbols.IDataService) private dataService: IDataService
    ) {
        this.layoutNameCache = new Map();
    }

    getDetailLayoutNameAsync(
        entityName: string,
        primaryKey: string | null,
        fallbackLayoutName: string
    ): Promise<string> {
        const cacheKey = `${entityName}/${primaryKey}/${fallbackLayoutName}`;
        if (!this.layoutNameCache.has(cacheKey)) {
            const layoutNameRequest = this.getDetailLayoutNameRequest(
                entityName,
                primaryKey,
                fallbackLayoutName
            );
            this.layoutNameCache.set(cacheKey, layoutNameRequest);
        }

        return this.layoutNameCache.get(cacheKey) as Promise<string>;
    }

    clearCache(): void {
        this.layoutNameCache.clear();
    }

    async getDetailLayoutNameRequest(
        entityName: string,
        primaryKey: string | null,
        fallbackLayoutName: string
    ): Promise<string> {
        const layoutFieldName = await this.getLayoutFieldName();
        if (!layoutFieldName) {
            return fallbackLayoutName;
        }

        const layouts = await this.getObjectAsync(entityName, primaryKey);

        return layouts[layoutFieldName] || fallbackLayoutName;
    }

    private async getLayoutFieldName() {
        return await this.configService.getStringValueAsync('client.Forms.FormSelectionFieldName');
    }

    private async getObjectAsync(entityName: string, primaryKey: string | null): Promise<{}> {
        return await this.dataService.getObjectAsync<{}>(entityName, primaryKey, null);
    }
}
