import React from 'react';
import { ISiteMap } from '../../../../navigation/ISiteMap';
import { VersionInfo } from '../../../VersionInfo/VersionInfo';
import { CrmContextMenuItem } from './CrmContextMenuItem';
import { SiteMapNode } from './SiteMapNode';

type TStyles = {
    divider: string;
};

const styles: TStyles = require('./MainMenu.less');

interface IProps {
    siteMap: ISiteMap;
    isWideMode: boolean;
}

export class MainMenu extends React.Component<IProps> {
    render() {
        const { siteMap, isWideMode } = this.props;

        return (
            <nav
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflowX: 'hidden',
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
                <CrmContextMenuItem isWideMode={isWideMode} />
                {siteMap.nodes.map((node, index) => (
                    <React.Fragment key={index}>
                        {!isWideMode && <hr className={styles.divider} />}
                        <SiteMapNode node={node} isWideMode={isWideMode} />
                    </React.Fragment>
                ))}
                <div style={{ flex: 1 }} />
                <div>
                    <VersionInfo />
                </div>
            </nav>
        );
    }
}
