import { inject, injectable } from 'inversify';
import {
    IExpressionEvaluator,
    IGenericObject,
    IMetaElement,
    IReadOnlyCalculator,
    isIMetaProperty,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { IPerformaAuthorizationService } from './IPerformaAuthorisationService';

@injectable()
export class PerformaReadOnlyCalculator implements IReadOnlyCalculator {
    constructor(
        @inject(QuinoCoreServiceSymbols.IExpressionEvaluator)
        private evaluator: IExpressionEvaluator,
        @inject(QuinoCoreServiceSymbols.IAuthorizationService)
        private authorizationService: IPerformaAuthorizationService
    ) {}

    calculate(element: IMetaElement, object: IGenericObject): boolean {
        const canUpdate = this.authorizationService.getAuthorization(object.metaClass).canUpdate();

        if (isIMetaProperty(element)) {
            return (
                !canUpdate ||
                (element.readOnly != null &&
                    this.evaluator.evaluate<boolean>(element.readOnly, object))
            );
        }

        return false;
    }
}
