import { IPerformaListState } from '../../Components/DataBrowser/IPerformaListState';
import { connect } from 'react-redux';
import { TRootReducerState } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import { TActionType } from '../types';

// State

type TState = { [key: string]: IPerformaListState };

export const initialListStates: TState = {};

// Actions

export const listStateActions = {
    updateListState: (siteMapPath: string, listState: IPerformaListState) =>
        ({
            type: 'listStates/UPDATE_LIST_STATE',
            siteMapPath,
            listState,
        } as const),
};

type TAction = TActionType<typeof listStateActions>;

// Reducer

export const listStatesReducer = (state: TState = initialListStates, action: TAction): TState => {
    switch (action.type) {
        case 'listStates/UPDATE_LIST_STATE':
            const { siteMapPath, listState } = action;
            return {
                ...state,
                [siteMapPath]: {
                    ...state[siteMapPath],
                    ...listState,
                },
            };
        default:
            return { ...state };
    }
};

// Connector

const mapStateToProps = ({ listStates }: TRootReducerState) => ({ listStates });
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(listStateActions, dispatch);

export const connectListStates = connect(
    mapStateToProps,
    mapDispatchToProps
);

export type TConnectedListStates = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

// Selectors

export const getListStateFor = (siteMapPath?: string) => (state: TRootReducerState) =>
    siteMapPath && state.listStates[siteMapPath];
