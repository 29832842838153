import React from 'react';
import { AppConfigConsumer } from './AppConfigContext';
import { HOC } from '../../util/types';
import { IAppConfig } from './IAppConfig';

export const withAppConfig: HOC<IAppConfig> = (InnerComponent: any) => (props) => (
    <AppConfigConsumer>
        {(appConfig: IAppConfig) => <InnerComponent {...appConfig} {...props} />}
    </AppConfigConsumer>
);
