import React, { PropsWithChildren, useEffect } from 'react';
import {
    IQuinoComponentProps,
    ObjectBookmark,
    QuinoLabeled,
    useLabelSettings,
    useMetadata,
    useMetaPropertyValueState,
    useRerender,
    useService,
    useValidation,
    useValueList,
} from '@quino/ui';
import {
    getAspectOrDefault,
    getElementPath,
    getMetaProperty,
    IExpressionEvaluator,
    IOptionValue,
    ISearchModeAspect,
    QuinoCoreServiceSymbols,
    SearchModeAspectIdentifier,
} from '@quino/core';
import { SelectBox } from 'devextreme-react/select-box';
import {
    FilteredDropDownAspectIdentifier,
    IFilteredDropDownAspect,
} from '../../meta/aspects/IFilteredDropDownAspect';

export function FilteredDropDown(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
    const { bookmark, actions } = props;
    const metaProperty = getMetaProperty(props.element);
    const { description, name, caption } = metaProperty;
    const labelSettings = useLabelSettings(props.element);
    const { readOnly, required, enabled } = useMetadata(metaProperty, bookmark.genericObject);
    const elementPath = getElementPath(metaProperty, true);
    const [isValid, errorMessages] = useValidation(bookmark, name);
    const fetchValueList = !readOnly && enabled;
    const valueList = useValueList(metaProperty, bookmark.genericObject, fetchValueList);
    const [value, setValue] = useMetaPropertyValueState<any>(metaProperty, bookmark);
    const rerender = useRerender();

    const searchModeAspect = getAspectOrDefault<ISearchModeAspect>(
        metaProperty,
        SearchModeAspectIdentifier
    );
    const filteredDropDownAspect = getAspectOrDefault<IFilteredDropDownAspect>(
        metaProperty,
        FilteredDropDownAspectIdentifier
    );
    const expressionEvaluator = useService<IExpressionEvaluator>(
        QuinoCoreServiceSymbols.IExpressionEvaluator
    );

    const filteredValueList = (valueList: IOptionValue[]) => {
        if (!filteredDropDownAspect) return valueList;
        const filter = expressionEvaluator.evaluate<string>(
            filteredDropDownAspect.filtereditemvaluesexpression,
            bookmark.genericObject
        );
        return valueList.filter((option) => !filter.includes(option.value));
    };

    useEffect(
        () => {
            if (bookmark.fieldErrors.get(elementPath) != null) {
                actions.validate(bookmark).then(rerender);
            }
        },
        [value]
    );

    useEffect(
        () => {
            const valueListContainsValue =
                valueList.filter((optionValue) => optionValue.value === value).length > 0;
            if (!valueListContainsValue) {
                setValue(undefined);
            }
        },
        [valueList]
    );

    return (
        <QuinoLabeled
            label={caption!}
            required={required}
            hint={description}
            errorMessages={errorMessages}
        >
            <SelectBox
                searchEnabled={true}
                searchMode={
                    searchModeAspect && searchModeAspect.useContains ? 'contains' : 'startswith'
                }
                readOnly={readOnly != null ? readOnly : false}
                focusStateEnabled={!readOnly}
                disabled={!enabled}
                items={filteredValueList(valueList)}
                valueExpr={'value'}
                displayExpr={'caption'}
                placeholder={''}
                value={value}
                hint={labelSettings.showHint ? description : ''}
                onValueChanged={(e) => setValue(e.value)}
                isValid={isValid}
            />
        </QuinoLabeled>
    );
}
