import { IRequestBuilder, QuinoCoreServiceSymbols } from '@quino/core';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IPerformaUrlManager } from './IPerformaUrlManager';
import { ICustomActionService } from './ICustomActionService';

/**
 * Default implementation for @see ICustomActionService.
 */
@injectable()
export class CustomActionService implements ICustomActionService {
    constructor(
        @inject(QuinoCoreServiceSymbols.IRequestBuilder) private requestBuilder: IRequestBuilder,
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMAURLMANAGER)
        private urlManager: IPerformaUrlManager
    ) {}

    /**
     * @inheritDoc
     */
    async executeCustomActionAsync(
        customActionName: string,
        entityName: string,
        primaryKey: string
    ): Promise<void> {
        const url = this.urlManager.getExecuteCustomActionUrl(customActionName);
        await this.requestBuilder
            .create(url, 'post')
            .requiresAuthentication()
            .setJsonPayload({
                EntityName: entityName,
                PrimaryKey: primaryKey,
            })
            .fetch();
    }
}
