import {
    getAspectOrDefault,
    IFieldValidator,
    II18n,
    IMetaProperty,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { inject, injectable } from 'inversify';
import { PerformaValidationErrorCodes } from './PerformaValidationErrorCodes';
import { tKey } from '../../lang/TranslationKeys';
import { IValidationContext } from '@quino/core/dist/validations/IValidationContext';
import { IFieldValidationResult } from '@quino/core/dist/validations/IFieldValidationResult';

@injectable()
export class DateInFutureValidator implements IFieldValidator {
    constructor(@inject(QuinoCoreServiceSymbols.II18N) private i18n: II18n) {}

    validate(
        field: IMetaProperty,
        value: any,
        context: IValidationContext
    ): IFieldValidationResult {
        if (!value) {
            return {};
        }

        if (!getAspectOrDefault(field, 'DateInFuture')) {
            return {};
        }

        if (typeof value !== 'string') {
            return this.getError(field);
        }

        const date = new Date(value);

        if (!DateInFutureValidator.isValidDate(date)) {
            return this.getError(field);
        }

        if (!DateInFutureValidator.isFutureDate(date)) {
            return this.getError(field);
        }

        return {};
    }

    private static isFutureDate(date: Date): boolean {
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 999);
        return endOfDay < date;
    }

    private static isValidDate(date: unknown): boolean {
        return date instanceof Date && !isNaN(date.getTime());
    }

    private getError(field: IMetaProperty) {
        return {
            fieldErrors: [
                {
                    fieldName: field.name,
                    errorCode: PerformaValidationErrorCodes.DateNotInFuture,
                    errorMessage: this.i18n.t(
                        tKey('literal.CustomLiterals.Validations.DateNotInFuture')
                    ),
                },
            ],
        };
    }
}
