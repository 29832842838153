import React, { PropsWithChildren, useEffect, useState } from 'react';
import { IConfiguration } from '../../configuration/IConfiguration';
import { IConfigurationService } from '../../configuration/IConfigurationService';
import { ConfigurationContext } from './ConfigurationContext';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { AppLoadingScreen } from '../../Components/PageRoot/AppLoadingScreen';
import { useService } from '../../ioc/hook/useService';
import { ServerNotReachableTimeout } from '../../Components/ServerNotReachable';

interface IProps {}

export const ContextProvider: React.FunctionComponent<IProps> = (
    props: PropsWithChildren<IProps>
) => {
    const configurationService = useService<IConfigurationService>(
        SHARED_SERVICE_IDENTIFIER.ICONFIGURATIONSERVICE
    );

    const [configuration, setConfiguration] = useState<IConfiguration | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const config = await configurationService.getConfigurationAsync();
            setConfiguration(config);
        })();
    }, []);

    if (!configuration) {
        return (
            <>
                <AppLoadingScreen />
                <ServerNotReachableTimeout />
            </>
        );
    }

    return (
        <ConfigurationContext.Provider value={configuration}>
            {props.children}
        </ConfigurationContext.Provider>
    );
};
