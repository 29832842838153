import React, { useContext } from 'react';
import { ProfileContext } from '../../../../../../contexts/profileContext';
import { Avatar } from '../../../../../Util/Avatar';
import { Button } from 'devextreme-react/button';

interface IStyles {
    button: string;
    label: string;
}

const styles: IStyles = require('./UserMenuButton.less');

interface IProps {
    onClick: () => void;
}

export const UserMenuButton: React.FunctionComponent<IProps> = (props: IProps) => {
    const { onClick } = props;
    const [profileData] = useContext(ProfileContext);
    const { firstName, lastName } = profileData;

    const fullName = firstName + ' ' + lastName;
    const shortName = (firstName && firstName.charAt(0)) + (lastName && lastName.charAt(0));

    return (
        <Button
            stylingMode={'outlined'}
            onClick={onClick}
            type={'normal'}
            className={styles.button}
        >
            <Avatar label={shortName} size={32} />
            <div className={styles.label}>{fullName}</div>
        </Button>
    );
};
