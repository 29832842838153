import * as React from 'react';
import { useEffect } from 'react';
import { Markdown } from '../Util/Markdown/Markdown';
import { useDispatch, useSelector } from 'react-redux';
import {
    apiActions,
    isServerReachable as isServerReachableSelector,
} from '../../redux/api/apiStore';
import { useI18n } from '../../lang/useI18n';

type TStyles = {
    divContainer: string;
};

const styles: TStyles = require('./ServerNotReachable.less');

interface IProps {
    reason?: string;
}

export const serverNotReachableReloadTimeoutMS = 10000;

export const ServerNotReachable: React.FC<IProps> = (props) => {
    const i18n = useI18n();

    const isServerReachable = useSelector(isServerReachableSelector);
    const dispatch = useDispatch();

    const reloadIfServerIsReachable = () => {
        if (isServerReachable) {
            window.location.reload();
        }
    };

    useEffect(reloadIfServerIsReachable, [isServerReachable]);

    const requestHealthCheck = () => {
        dispatch(apiActions.requestHealthCheck());
    };

    useEffect(() => {
        const interval = window.setInterval(requestHealthCheck, serverNotReachableReloadTimeoutMS);
        return () => window.clearInterval(interval);
    }, []);

    const { reason } = props;

    return (
        <div className={styles.divContainer}>
            <Markdown>{i18n.t('literal.CustomLiterals.ServerNotReachable')}</Markdown>
            <i>{reason}</i>
        </div>
    );
};
