import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Dashboard } from '../../Components/Dashboard/Dashboard';
import { IAppConfig } from '../../contexts/appConfigContext/IAppConfig';
import { MainContent } from '../../Components/MainContent/MainContent';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { withAppConfig } from '../../contexts/appConfigContext/withAppConfig';
import { CrmContextWrapper } from '../CrmContext/Wrapper/CrmContextWrapper';
import { PerformaAppBar } from './Components/PerformaAppBar/PerformaAppBar';
import { Sidebar } from './Components/Sidebar/Sidebar';
import { AuthorizationContextWrapper } from '../Authorization/AuthorizationContextWrapper';
import { lazyInject } from '@quino/core';
import { IRouteHelper } from '../../navigation/IRouteHelper';
import { ChangePasswordDialog } from './Components/ChangePasswordDialog/ChangePasswordDialog';
import { ProfilePage } from '../Profile/ProfilePage';
import { UserSettingsForm } from '../UserSettings/UserSettingsForm';
import { CrmContextDialogWrapper } from '../../crmContext/CrmContextDialogWrapper';
import { UserSettingsDialogWrapper } from '../UserSettings/UserSettingsDialogWrapper';
import { UserSettingsContextProvider } from '../UserSettings/UserSettingsContext/UserSettingsContextProvider';
import classNames from 'classnames';

type TStyles = {
    root: string;
    bottomArea: string;
    content: string;
    contentWithPadding: string;
    devexpressFixes: string;
};

const styles: TStyles = require('./MainLayout.less');

interface IProps extends IAppConfig {}

interface IState {
    open: boolean;
    dialog?: React.ComponentType<{
        onClose?: () => void;
    }>;
}

class MainLayoutComponent extends React.Component<IProps, IState> {
    @lazyInject(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER)
    private routeHelper: IRouteHelper;

    constructor(props: IProps) {
        super(props);
        this.state = {
            open: true,
        };
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    showChangePassword = () => {
        this.setState({ dialog: ChangePasswordDialog });
    };

    closeDialog = () => {
        this.setState({ dialog: undefined });
    };

    render() {
        const { open } = this.state;

        return (
            <CrmContextWrapper>
                <CrmContextDialogWrapper>
                    <AuthorizationContextWrapper>
                        <UserSettingsContextProvider>
                            <UserSettingsDialogWrapper>
                                {this.renderDialog()}
                                <div
                                    id={'crmcontext-root'}
                                    className={classNames(styles.root, styles.devexpressFixes)}
                                >
                                    <PerformaAppBar
                                        isDrawerOpen={open}
                                        onDrawerOpen={this.handleDrawerOpen}
                                        onDrawerClose={this.handleDrawerClose}
                                        onChangePasswordClick={this.showChangePassword}
                                    />
                                    {this.renderContent()}
                                </div>
                            </UserSettingsDialogWrapper>
                        </UserSettingsContextProvider>
                    </AuthorizationContextWrapper>
                </CrmContextDialogWrapper>
            </CrmContextWrapper>
        );
    }

    private renderStartComponent() {
        const { startPage } = this.props;
        if (startPage) {
            return startPage;
        }

        return <Dashboard />;
    }

    private renderContent = () => {
        const { siteMap } = this.props;
        const { open } = this.state;

        return (
            <div id={'drawer-main-container'} className={styles.bottomArea}>
                <Sidebar open={open} siteMap={siteMap}>
                    <main className={`${styles.content} dx-theme-background-color`}>
                        <div className={styles.contentWithPadding}>
                            {this.renderRouteBasedContent()}
                        </div>
                    </main>
                </Sidebar>
            </div>
        );
    };

    private renderRouteBasedContent = () => {
        const { siteMap } = this.props;

        return (
            <Switch>
                <Route
                    exact
                    path={this.routeHelper.getProfileUrl()}
                    render={() => <ProfilePage />}
                />
                <Route
                    exact
                    path={this.routeHelper.getSettingsUrl()}
                    render={() => <UserSettingsForm showLogoutButton={false} />}
                />
                <Route
                    exact
                    path={this.routeHelper.getRoutePathForRoot()}
                    render={() => this.renderStartComponent()}
                />
                <Route
                    exact
                    path={this.routeHelper.getRoutePathForRootWithCrmContext()}
                    render={() => this.renderStartComponent()}
                />
                <Route
                    path={this.routeHelper.getRoutePathForList()}
                    render={(props: any) => <MainContent {...props} siteMap={siteMap} />}
                />
            </Switch>
        );
    };

    private renderDialog() {
        if (!this.state.dialog) {
            return null;
        }

        return React.createElement(this.state.dialog, {
            onClose: this.closeDialog,
        });
    }
}

export const MainLayout = withAppConfig(MainLayoutComponent);
