import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { lazyInject, QuinoCoreServiceSymbols } from '@quino/core';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { IProfileService } from '../../../api/IProfileService';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Translation } from '@quino/ui';
import { tKey } from '../../../lang/TranslationKeys';
import { Link } from 'react-router-dom';
import { IRouteHelper } from '../../../navigation';
import { Button } from 'devextreme-react/button';
import { ErrorMessage } from '../../Util/ErrorMessage';
import { Typography } from '../../Util/Devextreme/Typography';
import { IPerformaI18n } from '../../../lang/IPerformaI18n';

type TStyles = {
    authFormRoot: string;
};

const styles: TStyles = require('../AuthForm.less');

interface IState {
    state: 'loading' | 'success' | 'error';
}

interface IProps extends RouteComponentProps {}

class ConfirmEmailComponent extends React.Component<IProps, IState> {
    @lazyInject(SHARED_SERVICE_IDENTIFIER.IPROFILESERVICE)
    private profileService: IProfileService;
    @lazyInject(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER)
    private routeHelper: IRouteHelper;
    @lazyInject(QuinoCoreServiceSymbols.II18N)
    private i18n: IPerformaI18n;

    constructor(props: IProps) {
        super(props);
        this.state = { state: 'loading' };
    }

    async componentDidMount() {
        try {
            const token: string = this.props.match.params['token'];
            await this.profileService.confirmEmailAsync({ secret: token });
            this.setState({
                state: 'success',
            });
        } catch (error) {
            this.setState({ state: 'error' });
        }
    }

    render() {
        return (
            <div className={styles.authFormRoot}>
                <div>
                    <Typography>
                        <h5>
                            <Translation
                                i18nKey={tKey('literal.CustomLiterals.ConfirmEmail.Title')}
                            />
                        </h5>
                    </Typography>
                    {this.renderContent()}
                </div>
            </div>
        );
    }

    renderContent() {
        switch (this.state.state) {
            case 'loading':
                return this.renderLoading();
            case 'success':
                return this.renderSuccess();
            case 'error':
                return this.renderError();
        }
    }

    private renderLoading = () => <LoadIndicator height={32} width={32} />;

    private renderSuccess = () => (
        <>
            <Typography>
                <Translation i18nKey={tKey('literal.CustomLiterals.ConfirmEmail.Success')} />
            </Typography>
            {this.renderBackButton()}
        </>
    );

    private renderError = () => (
        <>
            <ErrorMessage>
                <Translation i18nKey={tKey('literal.CustomLiterals.ConfirmEmail.Error')} />
            </ErrorMessage>
            {this.renderBackButton()}
        </>
    );

    private renderBackButton = () => (
        <div>
            <Link to={this.routeHelper.getLoginUrl()}>
                <Button
                    type={'default'}
                    text={this.i18n.t('literal.CustomLiterals.Login.GoToLogin')}
                />
            </Link>
        </div>
    );
}

export const ConfirmEmail = withRouter(ConfirmEmailComponent);
