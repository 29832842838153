import { inject, injectable } from 'inversify';
import { ICrmContext } from 'performa-shared';
import { IDocumentsStateHelper } from './IDocumentsStateHelper';
import { IDocumentsState } from './IDocumentsState';
import { PNET_SERVICE_IDENTIFIER } from '../../ioc/pnetIdentifiers';
import { IDocumentsUrlProvider } from '../../core/urlProviders/IDocumentsUrlProvider';

@injectable()
export class DocumentsStateHelper implements IDocumentsStateHelper {
    constructor(
        @inject(PNET_SERVICE_IDENTIFIER.IDOCUMENTSURLPROVIDER)
        private documentsUrlProvider: IDocumentsUrlProvider
    ) {}

    public startLoading(oldState: IDocumentsState): IDocumentsState {
        return {
            ...oldState,
            isLoading: true,
        };
    }

    public stopLoading(oldState: IDocumentsState): IDocumentsState {
        return {
            ...oldState,
            isLoading: false,
        };
    }

    public async loadSharePointUrlAsync(
        crmContext: ICrmContext,
        oldState: IDocumentsState
    ): Promise<IDocumentsState> {
        return {
            ...oldState,
            sharePointUrl: await this.documentsUrlProvider.getUrlAsync(crmContext),
            loadedCrmContext: crmContext,
        };
    }
}
