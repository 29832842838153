import React, { useState } from 'react';
import { Button } from 'devextreme-react/button';
import { ContextMenu } from 'devextreme-react/context-menu';
import { IMetaLayout, LayoutType } from '@quino/core';
import { useI18n } from '../../lang/useI18n';

interface IProps {
    layoutName?: string;
    listLayouts?: IMetaLayout[];
    onLayoutSelected: (layoutName: string) => void;
}

export const DataListHeaderListSelection: React.FunctionComponent<IProps> = (props) => {
    const i18n = useI18n();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const close = () => {
        setIsMenuOpen(false);
    };

    const handleSelect = (layoutName: string) => {
        close();
        props.onLayoutSelected(layoutName);
    };

    const { layoutName } = props;
    const filteredListLayouts = (props.listLayouts || []).filter(
        (l: IMetaLayout) => l.name !== LayoutType.List
    );

    if (filteredListLayouts.length < 2) {
        return null;
    }
    return (
        <>
            <Button
                id={'performa_header_filter_button'}
                stylingMode={'text'}
                hint={i18n.t('literal.CustomLiterals.DataList.Filter')}
                onClick={() => setIsMenuOpen((value) => !value)}
                icon={'filter'}
                height={48}
                width={48}
            />

            <ContextMenu
                position={{ at: 'left bottom' }}
                visible={isMenuOpen}
                dataSource={filteredListLayouts}
                displayExpr={'caption'}
                onItemClick={({ itemData }) => handleSelect(itemData.name)}
                selectedItem={filteredListLayouts.find((layout) => layout.name === layoutName)}
                target={'#performa_header_filter_button'}
                onHiding={close}
            />
        </>
    );
};
