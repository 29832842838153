import React from 'react';
import {
    IAuthenticationService,
    IRequestDecoratorProvider,
    IRequestFactory,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { tKey } from '../../../lang/TranslationKeys';
import { useService } from '../../../ioc/hook/useService';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { IPerformaStorage } from '../../../storage/IPerformaStorage';
import { FileUploader } from 'devextreme-react/file-uploader';
import { useI18n } from '../../../lang/useI18n';
import { isPerformaException } from '../../../api/DTOs/IPerformaExceptionDTO';
import { dxElement } from 'devextreme/core/element';

type TStyles = {
    performaFileUploader: string;
};

const styles: TStyles = require('./PerformaFileUploader.less');

interface IPerformaFileUploaderProps {
    readonly: boolean;
    fileContext?: string;
    acceptedMimeTypes?: string;
    maxFileSizeInBytes?: number;
    onUploadedFile: (id: string) => void;
    onUploadStateChanged: (uploadsInProgress: number) => void;
}

export const PerformaFileUploader: React.FC<IPerformaFileUploaderProps> = React.memo(
    (props) => {
        const {
            readonly,
            fileContext,
            acceptedMimeTypes,
            maxFileSizeInBytes,
            onUploadedFile,
            onUploadStateChanged,
        } = props;

        let uploadsInProgress = 0;

        const uploadFinished = () => {
            uploadsInProgress -= 1;
            onUploadStateChanged(uploadsInProgress);
        };

        const uploadError = (
            element: dxElement | undefined,
            request: XMLHttpRequest | undefined,
            file: File | undefined
        ) => {
            if (element && request && file) {
                const responseObject = JSON.parse(request.response);
                if (isPerformaException(responseObject)) {
                    const errorText = responseObject.title;
                    const filesContainer = element.getElementsByClassName(
                        'dx-fileuploader-files-container'
                    )[0];
                    const filesInsideContainer = filesContainer.getElementsByClassName(
                        'dx-fileuploader-file-container'
                    );
                    for (let i = 0; i < filesInsideContainer.length; i++) {
                        const fileInsideContainer = filesInsideContainer[i];
                        const fileName = fileInsideContainer.getElementsByClassName(
                            'dx-fileuploader-file-name'
                        )[0].innerHTML;
                        if (fileName === file.name) {
                            const fileStatusMessageDivArray = fileInsideContainer.getElementsByClassName(
                                'dx-fileuploader-file-status-message'
                            );
                            const fileStatusMessageDiv =
                                fileStatusMessageDivArray[fileStatusMessageDivArray.length - 1];
                            fileStatusMessageDiv.classList.add('upload-error');
                            setTimeout(function() {
                                fileStatusMessageDiv.innerHTML = errorText;
                                fileInsideContainer.classList.add('dx-fileuploader-invalid');
                            }, 500);
                        }
                    }
                }
            }
            uploadFinished();
        };

        const uploadStarted = () => {
            uploadsInProgress += 1;
            onUploadStateChanged(uploadsInProgress);
        };

        const performaStorage = useService<IPerformaStorage>(
            SHARED_SERVICE_IDENTIFIER.IPERFORMASTORAGE
        );
        const i18n = useI18n();
        const authenticationService = useService<IAuthenticationService>(
            QuinoCoreServiceSymbols.IAuthenticationService
        );
        const decoratorProvider = useService<IRequestDecoratorProvider>(
            QuinoCoreServiceSymbols.IRequestDecoratorProvider
        );
        const requestFactory = useService<IRequestFactory>(QuinoCoreServiceSymbols.IRequestFactory);

        const headers = () => {
            const request = requestFactory.create(performaStorage.getUploadUrl(), {
                method: 'POST',
            });
            decoratorProvider.getInstances().forEach((decorator) => decorator.decorate(request));
            authenticationService.authenticateRequest(request);

            return {
                'ACCEPT-LANGUAGE': request.headers.get('ACCEPT-LANGUAGE'),
                Authorization: request.headers.get('Authorization'),
                filecontext: fileContext,
            };
        };

        const fadeOutFileUploadedContainer = () => {
            const fileContainers = document.getElementsByClassName(
                'dx-fileuploader-file-container'
            );
            for (let i = 0; i < fileContainers.length; ++i) {
                const element = fileContainers[i];
                if (element.classList.contains('dx-fileuploader-invalid')) {
                    continue;
                }
                element.classList.add('animate-hide-file-container');
                setTimeout(function() {
                    element.classList.add('remove-file-container');
                }, 3000);
            }
        };

        return (
            <FileUploader
                name={'formFile'}
                className={styles.performaFileUploader}
                disabled={readonly}
                uploadHeaders={headers()}
                uploadUrl={performaStorage.getUploadUrl()}
                uploadMode={'instantly'}
                onUploaded={(e) => {
                    uploadFinished();
                    uploadsInProgress === 0 && setTimeout(fadeOutFileUploadedContainer, 1000);
                    onUploadedFile(e.request!.responseText);
                }}
                onUploadStarted={() => uploadStarted()}
                onUploadAborted={() => uploadFinished()}
                onUploadError={(e) => uploadError(e.element, e.request, e.file)}
                multiple={true}
                maxFileSize={maxFileSizeInBytes}
                invalidMaxFileSizeMessage={i18n.t(
                    tKey('literal.CustomLiterals.Validations.InvalidFileUploadMaxFileSize'),
                    {
                        maxSizeMB: maxFileSizeInBytes && maxFileSizeInBytes / (1024 * 1024),
                    }
                )}
                accept={acceptedMimeTypes}
                allowedFileExtensions={(acceptedMimeTypes && acceptedMimeTypes.split(', ')) || []}
                invalidFileExtensionMessage={i18n.t(
                    tKey('literal.CustomLiterals.Validations.InvalidFileType'),
                    {
                        acceptedFiles: acceptedMimeTypes,
                    }
                )}
                selectButtonText={i18n.t(tKey('literal.CustomLiterals.Upload.UploadButton'))}
                labelText={i18n.t(tKey('literal.CustomLiterals.Upload.DropField'))}
            />
        );
    },
    (prevProps, nextProps) => {
        return JSON.stringify(prevProps) === JSON.stringify(nextProps);
    }
);
