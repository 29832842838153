import { ICrmContextIdentifier } from './ICrmContextIdentifier';

export class CrmContextIdentifierSerializer {
    public static serialize(crmContextIdentifier: ICrmContextIdentifier): string {
        return `${crmContextIdentifier.entityName}-${crmContextIdentifier.id}`;
    }

    public static deserialize(value: string): ICrmContextIdentifier | undefined {
        const regex = /^(.+?)-(.+)$/;
        const match = regex.exec(value);
        if (!match || match.length !== 3) {
            return undefined;
        }

        return {
            entityName: match[1],
            id: match[2],
        };
    }
}
