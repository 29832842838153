import { inject, injectable } from 'inversify';
import { IDataService, QuinoCoreServiceSymbols } from '@quino/core';
import { ICrmContext } from 'performa-shared';
import { IAccounts } from '../model';
import { IDocumentsUrlProvider } from './IDocumentsUrlProvider';

@injectable()
export class CrmContextSharePointUrlProvider implements IDocumentsUrlProvider {
    constructor(@inject(QuinoCoreServiceSymbols.IDataService) private dataService: IDataService) {}

    public async getUrlAsync(crmContext: ICrmContext): Promise<string | null> {
        const crmContextEntity = crmContext.context.entityName;
        const crmContextId = crmContext.context.id;

        const crmContextAccount = await this.dataService.getObjectAsync<IAccounts>(
            crmContextEntity,
            'accountid=' + crmContextId,
            'Portal_DocumentLink'
        );

        if (crmContextAccount && crmContextAccount.px_portaldocuments) {
            return crmContextAccount.px_portaldocuments;
        }

        return null;
    }
}
