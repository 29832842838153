import React, { PropsWithChildren, useEffect } from 'react';
import { ServerNotReachable } from './ServerNotReachable';
import { useDispatch, useSelector } from 'react-redux';
import {
    apiActions,
    isHealthCheckRequested as isHealthCheckRequestedSelector,
    isServerReachable as isServerReachableSelector,
} from '../../redux/api/apiStore';
import { useService } from '../../ioc/hook/useService';
import { IPerformaServer } from '../../api/IPerformaServer';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';

/**
 * The health check is only considered successful, if it succeeds before this timeout
 */
const healthCheckTimeoutMS = 10000;

interface IProps {
    pageDecorator?: React.FunctionComponent<any>;
}

export const ServerNotReachableChecker: React.FunctionComponent<IProps> = (
    props: PropsWithChildren<IProps>
) => {
    const performaServer = useService<IPerformaServer>(SHARED_SERVICE_IDENTIFIER.IPERFORMASERVER);

    const isServerReachable = useSelector(isServerReachableSelector);
    const isHealthCheckRequested = useSelector(isHealthCheckRequestedSelector);
    const dispatch = useDispatch();

    function setServerReachable(flag: boolean) {
        dispatch(apiActions.setServerReachable(flag));
    }

    async function performHealthCheck() {
        if (isHealthCheckRequested) {
            const request = performaServer.isServerReachable();

            const timeout = new Promise((resolve, reject) =>
                window.setTimeout(reject, healthCheckTimeoutMS)
            );

            try {
                await Promise.race([request, timeout]);
                setServerReachable(true);
            } catch (e) {
                setServerReachable(false);
            }
        }
    }

    useEffect(
        () => {
            performHealthCheck();
        },
        [isHealthCheckRequested]
    );

    if (!isServerReachable) {
        const Decorator = props.pageDecorator || React.Fragment;

        return (
            <>
                <Decorator>
                    <ServerNotReachable />
                </Decorator>
            </>
        );
    } else {
        return <>{props.children}</>;
    }
};
