import React, { useContext, useEffect, useState } from 'react';
import { LoginContext } from '../../contexts/loginContext';
import { MainLayout } from '../MainLayout';
import { LoggedOutPageRoot } from './LoggedOutPageRoot';
import { BannerTextDecorator } from '../BannerTextDecorator';
import { AppLoadingScreen } from './AppLoadingScreen';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { IAppConfig } from '../../contexts/appConfigContext/IAppConfig';
import { withProfileContextProvider } from '../../contexts/profileContext/withProfileContextProvider';
import { ServerRestart } from '../ServerRestart/ServerRestart';
import { AppConfigProvider } from '../../contexts/appConfigContext/AppConfigContext';
import { IWithConfigurationContext } from '../../contexts/configurationContext/IWithConfigurationContext';
import { ContextProvider } from '../../contexts/configurationContext/withConfigurationContextProvider';
import { withConfigurationContext } from '../../contexts/configurationContext/withConfigurationContext';
import { withLoginWrapper } from '../Auth/LoginWrapper';
import { ServerNotReachableChecker } from '../ServerNotReachable/ServerNotReachableChecker';
import { ServerNotReachableTimeout } from '../ServerNotReachable';
import { BrowserCheck } from '../BrowserCheck/BrowserCheck';
import { IQuinoLabelConfigurationService, QuinoUIServiceSymbols, useService } from '@quino/ui';
import { ILanguageService, IMetadataTree, QuinoCoreServiceSymbols } from '@quino/core';
import { SessionProvider } from '../../session/SessionProvider';
import { withStoreProvider } from '../../redux/withStoreProvider';
import { withWrapper } from '../Util/withWrapper';
import { ContainerContextProvider } from '../../ioc/sharedContainer';
import { useI18n } from '../../lang/useI18n';
import { ISessionService } from '../../session';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc';
import { GlobalErrorContext } from '../Error/GlobalErrorContext';
import { withGlobalErrorContextProvider } from '../Error/withGlobalErrorContextProvider';

const quinoStyles = require('../../../../node_modules/@quino/ui/dist/index.css');
console.assert(quinoStyles, 'Could not load CSS for @quino/ui');

type TStyles = {
    root: string;
};

const styles: TStyles = require('./PageRoot.less');

interface IProps extends IAppConfig, IWithConfigurationContext {}

const PageRootComponent: React.FunctionComponent<IProps> = (props: IProps) => {
    const { isLoggedIn } = useContext(LoginContext);
    const [, setGlobalError] = useContext(GlobalErrorContext);
    const metaDataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
    const labelConfigurationService = useService<IQuinoLabelConfigurationService>(
        QuinoUIServiceSymbols.IQuinoLabelConfigurationService
    );
    const sessionService = useService<ISessionService>(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE);
    const languageService = useService<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService);
    const i18n = useI18n();

    const [translationsLoaded, setTranslationsLoaded] = useState(false);
    const [languageDetected, setLanguageDetected] = useState(false);
    const [metadataTreeInitialized, setMetadataTreeInitialized] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const locale = await languageService.getCurrentLanguageAsync();
                sessionService.updateSessionState({ locale: locale });
                setLanguageDetected(true);
            } catch (e) {
                setGlobalError({ error: e, title: 'Language Service Error', logout: true });
            }
        })();
    }, []);

    useEffect(
        () => {
            if (languageDetected) {
                (async () => {
                    try {
                        await i18n.loadTranslationsAsync();
                        setTranslationsLoaded(true);
                    } catch (e) {
                        setGlobalError({
                            error: e,
                            title: 'Translation Service Error',
                            logout: true,
                        });
                    }
                })();
            }
        },
        [languageDetected]
    );

    useEffect(
        () => {
            if (isLoggedIn && translationsLoaded && languageDetected) {
                (async () => {
                    await labelConfigurationService.getLabelSettings(null);

                    try {
                        await metaDataTree.initialize(() => setMetadataTreeInitialized(true));
                    } catch (e) {
                        setGlobalError({
                            error: e,
                            title: 'Metadata-Tree Service Error',
                            logout: true,
                        });
                    }
                })();
            }
        },
        [isLoggedIn, translationsLoaded, languageDetected]
    );

    if (
        isLoggedIn === undefined ||
        (isLoggedIn && !metadataTreeInitialized) ||
        !translationsLoaded
    ) {
        return (
            <ServerNotReachableChecker pageDecorator={props.loggedOutPageDecorator}>
                <AppLoadingScreen />
                <ServerNotReachableTimeout />
            </ServerNotReachableChecker>
        );
    }

    return (
        <AppConfigProvider value={props}>
            <ServerNotReachableChecker pageDecorator={props.loggedOutPageDecorator}>
                <BrowserCheck pageDecorator={props.loggedOutPageDecorator}>
                    <div className={`app ${styles.root}`}>
                        <BannerTextDecorator />
                        <Switch>
                            <Route exact path="/admin/server-restart" component={ServerRestart} />
                            <Route>{isLoggedIn ? <MainLayout /> : <LoggedOutPageRoot />}</Route>
                        </Switch>
                    </div>
                </BrowserCheck>
            </ServerNotReachableChecker>
        </AppConfigProvider>
    );
};

export const PageRoot = withStoreProvider(
    withWrapper(BrowserRouter)(
        withWrapper(ContainerContextProvider)(
            withWrapper(SessionProvider)(
                withLoginWrapper(
                    withGlobalErrorContextProvider(
                        withWrapper(ContextProvider)(
                            withConfigurationContext(withProfileContextProvider(PageRootComponent))
                        )
                    )
                )
            )
        )
    )
);
