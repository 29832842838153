import {
    getAspectOrDefault,
    IFieldValidator,
    II18n,
    IMetaProperty,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { inject, injectable } from 'inversify';
import { PerformaValidationErrorCodes } from './PerformaValidationErrorCodes';
import { tKey } from '../../lang/TranslationKeys';
import { IValidationContext } from '@quino/core/dist/validations/IValidationContext';
import { IFieldValidationResult } from '@quino/core/dist/validations/IFieldValidationResult';

@injectable()
export class SocialSecurityNumberValidator implements IFieldValidator {
    constructor(@inject(QuinoCoreServiceSymbols.II18N) private i18n: II18n) {}

    validate(
        field: IMetaProperty,
        value: any,
        context: IValidationContext
    ): IFieldValidationResult {
        if (!value) {
            return {};
        }

        if (!getAspectOrDefault(field, 'SocialSecurityNumber')) {
            return {};
        }

        if (typeof value !== 'string') {
            return this.getError(field);
        }

        if (!SocialSecurityNumberValidator.validateFormat(value)) {
            return this.getError(field);
        }

        if (!SocialSecurityNumberValidator.validateChecksum(value)) {
            return this.getError(field);
        }

        return {};
    }

    private static validateFormat(data: string) {
        const regExPattern = /^756\.\d{4}\.\d{4}\.\d{2}$/g;
        return regExPattern.test(data);
    }

    private static validateChecksum(data: string) {
        const checksum = data
            .match(/(\d)/g)!
            .map((match) => parseInt(match, 10))
            .map((digit, i) => (i % 2 !== 0 ? digit * 3 : digit))
            .reduce((sum, digit) => sum + digit, 0);

        return checksum % 10 === 0;
    }

    private getError(field: IMetaProperty) {
        return {
            fieldErrors: [
                {
                    fieldName: field.name,
                    errorCode: PerformaValidationErrorCodes.InvalidSocialSecurityNumber,
                    errorMessage: this.i18n.t(
                        tKey('literal.CustomLiterals.Validations.InvalidSocialSecurityNumber')
                    ),
                },
            ],
        };
    }
}
