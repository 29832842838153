import { inject, injectable } from 'inversify';
import { IGenericObject, IRequestBuilder, QuinoCoreServiceSymbols } from '@quino/core';
import { ICrmContext } from '../crmContext/ICrmContext';
import { ICrmContextIdentifier } from '../crmContext/ICrmContextIdentifier';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IPerformaServer } from './IPerformaServer';
import { IPerformaUrlManager } from './IPerformaUrlManager';
import { IAuthorizationInformation } from '../authorization/IAuthorizationInformation';
import { IBackendVersionInfo } from '../versionInfo/IBackendVersionInfo';
import { IRestartServerDTO } from './DTOs/IRestartServerDTO';
import { TConfigurationDTO } from '../configuration/types';

@injectable()
export class PerformaServer implements IPerformaServer {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMAURLMANAGER)
        private urlManager: IPerformaUrlManager,
        @inject(QuinoCoreServiceSymbols.IRequestBuilder) private requestBuilder: IRequestBuilder
    ) {}

    async getConfigurationAsync(): Promise<TConfigurationDTO> {
        const url = this.urlManager.getConfigurationUrl();
        return await this.requestBuilder
            .create(url, 'get')
            .requiresAuthentication()
            .fetchJson();
    }

    async getContextListAsync(forceReload?: boolean): Promise<ICrmContext[]> {
        const url = this.urlManager.getContextListUrl(forceReload);
        return await this.requestBuilder
            .create(url, 'get')
            .requiresAuthentication()
            .fetchJson<ICrmContext[]>();
    }

    async setContextAsync(context: ICrmContextIdentifier): Promise<boolean> {
        const url = this.urlManager.getSetContextUrl();
        return await this.requestBuilder
            .create(url, 'post')
            .requiresAuthentication()
            .setJsonPayload(context)
            .fetchJson();
    }

    async getTokenValidAsync(): Promise<boolean> {
        const url = this.urlManager.getCheckTokenUrl();
        return await this.requestBuilder
            .create(url, 'get')
            .requiresAuthentication()
            .fetchJson();
    }

    async getAuthorizationAsync(): Promise<IAuthorizationInformation[]> {
        const url = this.urlManager.getAuthorizationUrl();
        return await this.requestBuilder
            .create(url, 'get')
            .requiresAuthentication()
            .fetchJson();
    }

    async getVersionInfoAsync(): Promise<IBackendVersionInfo> {
        const url = this.urlManager.getVersionInfoUrl();
        return await this.requestBuilder.create(url, 'get').fetchJson();
    }

    async restartServerAsync(data: IRestartServerDTO): Promise<void> {
        const url = this.urlManager.getRestartServerUrl();
        await this.requestBuilder
            .create(url, 'post')
            .setJsonPayload(data)
            .fetch();
    }

    async sendLogoutRequest(): Promise<Response> {
        const url = this.urlManager.getLogoutUrl();
        // requiresAuthentication() is necessary to send the token with the request even the api does not require it
        return await this.requestBuilder
            .create(url, 'post')
            .requiresAuthentication()
            .fetch();
    }

    async isServerReachable(): Promise<any> {
        const url = this.urlManager.getHealthCheck();
        await this.requestBuilder.create(url, 'get').fetch();
    }

    async updateObjectAsync<TPayload>(
        className: string,
        primaryKey: string,
        layout: string,
        data: Partial<IGenericObject>
    ): Promise<TPayload> {
        const url = this.urlManager.updateObjectUrl(className, primaryKey, layout);
        return await this.requestBuilder
            .create(url, 'patch')
            .setJsonPayload(data)
            .requiresAuthentication()
            .fetchJson<any>();
    }

    async insertObjectAsync<TPayload>(
        className: string,
        layout: string,
        data: Partial<IGenericObject>
    ): Promise<TPayload> {
        const url = this.urlManager.insertObjectUrl(className, layout);
        return await this.requestBuilder
            .create(url, 'post')
            .setJsonPayload(data)
            .requiresAuthentication()
            .fetchJson<any>();
    }
}
