import React, { useContext, useEffect, useState } from 'react';
import { HOC } from '../../util/types';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { IProfileService } from '../../api/IProfileService';
import { useService } from '../../ioc/hook/useService';
import { DefaultProfileContext, ProfileContext } from './ProfileContext';
import { IProfileContext } from './IProfileContext';
import { ISessionService } from '../../session/ISessionService';
import { LoginContext } from '../loginContext/LoginContext';
import { GlobalErrorContext } from '../../Components/Error/GlobalErrorContext';

const ProfileContextProviderComponent: React.FunctionComponent = (props: any) => {
    const profileService = useService<IProfileService>(SHARED_SERVICE_IDENTIFIER.IPROFILESERVICE);
    const sessionService = useService<ISessionService>(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE);
    const { isLoggedIn } = useContext(LoginContext);

    const [profileData, setProfileData] = useState<IProfileContext>(DefaultProfileContext);
    const [, setError] = useContext(GlobalErrorContext);

    useEffect(
        () => {
            (async () => {
                if (!isLoggedIn) {
                    return;
                }
                try {
                    const profileData = await profileService.getProfileInformationAsync();
                    setProfileData({
                        firstName: profileData.firstName,
                        lastName: profileData.lastName,
                        emailAddress: profileData.mailAddress,
                        showFieldDescriptions: profileData.showFieldDescriptions,
                        languageCode: profileData.languageCode,
                    });
                } catch (e) {
                    setError({ error: e, title: 'Profile Error', logout: true });
                }
            })();
        },
        [profileService, isLoggedIn]
    );

    useEffect(
        () => {
            sessionService.updateSessionState({ locale: profileData.languageCode });
        },
        [profileData.languageCode]
    );

    return (
        <ProfileContext.Provider value={[profileData, setProfileData]}>
            {props.children}
        </ProfileContext.Provider>
    );
};

export const withProfileContextProvider: HOC = (InnerComponent) => (props) => (
    <ProfileContextProviderComponent>
        <InnerComponent {...props} />
    </ProfileContextProviderComponent>
);
