import { IAuthenticationService, QuinoCoreServiceSymbols } from '@quino/core';
import { IPerformaAuthService } from './IPerformaAuthService';
import { ISessionService } from '../session/ISessionService';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IPerformaServer } from '../api/IPerformaServer';
import { ICurrentUserProfileCache } from '../api/ICurrentUserProfileCache';

@injectable()
export class AuthService implements IPerformaAuthService {
    constructor(
        @inject(QuinoCoreServiceSymbols.IAuthenticationService)
        private quinoAuthService: IAuthenticationService,
        @inject(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE) private sessionService: ISessionService,
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMASERVER) private server: IPerformaServer,
        @inject(SHARED_SERVICE_IDENTIFIER.ICURRENTUSERPROFILECACHE)
        private currentUserProfileCache: ICurrentUserProfileCache
    ) {}

    async loginAsync(username: string, password: string) {
        const authenticationResult = await this.quinoAuthService.loginAsync({ username, password });
        return authenticationResult.success;
    }

    async logoutAsync() {
        this.server.sendLogoutRequest();
        this.currentUserProfileCache.clear();

        await this.quinoAuthService.logoutAsync();

        this.sessionService.updateSessionState({ activeCrmContext: undefined });

        return true;
    }

    async verifyTokenAsync(): Promise<boolean> {
        try {
            await this.server.getTokenValidAsync();
        } catch (e) {
            return false;
        }

        return true;
    }

    async isLoggedInAsync() {
        return await this.quinoAuthService.isLoggedIn();
    }
}
