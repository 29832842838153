import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IPerformaServer } from '../api';
import { IConfigurationLoader } from './IConfigurationLoader';
import { TConfigurationDTO } from './types';

@injectable()
export class ConfigurationLoader implements IConfigurationLoader {
    private configurationRequest?: Promise<TConfigurationDTO>;

    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMASERVER) private performaServer: IPerformaServer
    ) {}

    async loadConfigurationMapAsync(): Promise<TConfigurationDTO> {
        try {
            if (!this.configurationRequest) {
                this.configurationRequest = this.performaServer.getConfigurationAsync();
            }
            return await this.configurationRequest;
        } catch (e) {
            return {};
        }
    }
}
