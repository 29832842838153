import { ApplicationInsights } from '@microsoft/applicationinsights-web';

/**
 * Register application insight if the instrumentation key is available.
 */
export const tryRegisterAppInsights = () => {
    const win = window as any;
    if (window && win.clientConfig && win.clientConfig.instrumentationKey) {
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: win.clientConfig.instrumentationKey,
            },
        });

        appInsights.loadAppInsights();
        appInsights.trackPageView();
    }

    console.warn('No valid configuration for app-insights found. Skipping initialization.');
};
