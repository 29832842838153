/**
 * Java equivalent hashCode from: https://stackoverflow.com/a/8831937.
 * This hast is quite weak don't use for security reasons.
 * @param object of which the hash should be generated from
 */
export const hashCode = (object: any): number => {
    const s = JSON.stringify(object);
    let hash = 0;
    for (let i = 0; i < s.length; i++) {
        const char = s.charCodeAt(i);
        // tslint:disable-next-line
        hash = (hash << 5) - hash + char;
        // tslint:disable-next-line
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};
