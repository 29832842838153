import React from 'react';
import { ICrmContext } from '../../../crmContext/ICrmContext';
import { ILocationService } from '../../../navigation/ILocationService';
import { CrmContext } from '../../../contexts/crmContext/CrmContext';
import { ICrmContextWrapperState as IState } from './CrmContextWrapperStateHelper';
import { ICrmContextWrapperStateHelper } from './ICrmContextWrapperStateHelper';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { lazyInject } from '@quino/core';
import { CrmContextUrlAdjuster } from './CrmContextUrlAdjuster';

interface IProps {}

export class CrmContextWrapper extends React.Component<IProps, IState> {
    @lazyInject(SHARED_SERVICE_IDENTIFIER.ICRMCONTEXTWRAPPERSTATEHELPER)
    private stateHelper: ICrmContextWrapperStateHelper;
    @lazyInject(SHARED_SERVICE_IDENTIFIER.ILOCATIONSERVICE)
    private locationService: ILocationService;

    constructor(props: IProps) {
        super(props);

        this.state = {
            ...this.stateHelper.getInitialState(),
            trySetActiveCrmContextAsync: this.setActiveCrmContextAsync,
            showContextSelector: () => {
                this.setState({ isContextSelectorVisible: true });
            },
            hideContextSelector: () => {
                this.setState({ isContextSelectorVisible: false });
            },
            reloadCrmContextsAsync: () => {
                this.loadAvailableCrmContextsAsync(true);
            },
        };
    }

    async componentDidMount() {
        await this.loadAvailableCrmContextsAsync();

        const currentActiveCrmContext = this.state.activeCrmContext;
        const currentActiveCrmContextIsNotValid =
            currentActiveCrmContext &&
            this.state.availableCrmContexts.filter(
                (context) => context.context.id === currentActiveCrmContext.context.id
            ).length < 1;
        if (currentActiveCrmContextIsNotValid) {
            this.setState({ ...this.state, activeCrmContext: undefined });
            this.state.showContextSelector();
        }
    }

    private loadAvailableCrmContextsAsync = async (forceReload?: boolean) => {
        await this.setState(this.stateHelper.startLoading);
        this.setState(
            await this.stateHelper.loadAvailableCrmContextsAsync(this.state, forceReload)
        );
        this.setState(this.stateHelper.stopLoading);
    };

    private setActiveCrmContextAsync = async (crmContext?: ICrmContext) => {
        this.state.hideContextSelector();
        if (await this.locationService.canChangeLocation()) {
            await this.changeCrmContextAsync(crmContext);
        }
    };

    private changeCrmContextAsync = async (crmContext?: ICrmContext) => {
        await this.setState(this.stateHelper.startLoading);
        await this.setState(
            await this.stateHelper.setActiveCrmContextAsync(this.state, crmContext)
        );
        await this.setState(this.stateHelper.stopLoading);
    };

    render() {
        return (
            <CrmContext.Provider value={this.state}>
                <CrmContextUrlAdjuster />
                {this.props.children}
            </CrmContext.Provider>
        );
    }
}
