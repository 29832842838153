import React from 'react';
import {
    ILogger,
    IMetaElement,
    isIMetaGroup,
    QuinoCoreServiceSymbols,
    IVisibleCalculator,
} from '@quino/core';
import { useService } from '../../ioc/hook/useService';
import { IQuinoComponentProps, ObjectBookmark, QuinoUIServiceSymbols } from '@quino/ui';
import { IComponentFactory } from '@quino/ui/dist/components/ComponentFactory/IComponentFactory';

export const NoCaptionGroupBox: React.FunctionComponent<IQuinoComponentProps<ObjectBookmark>> = (
    props
) => {
    const factory = useService<IComponentFactory>(QuinoUIServiceSymbols.IComponentFactory);
    const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
    const visibilityCalculator = useService<IVisibleCalculator>(
        QuinoCoreServiceSymbols.IVisibleCalculator
    );

    if (isIMetaGroup(props.element)) {
        const containVisibleChildren =
            props.element.elements.filter((child: IMetaElement) =>
                visibilityCalculator.calculate(child, props.bookmark.genericObject)
            ).length > 0;

        return (
            <div
                className="dx-form-group-with-caption dx-form-group"
                key={props.element.name + '_no_caption_group_box'}
            >
                {containVisibleChildren && (
                    <div className="dx-form-group-content">
                        {props.element.elements.map((child: IMetaElement) =>
                            factory.create(child, props.bookmark, props.actions)
                        )}
                    </div>
                )}
            </div>
        );
    }

    logger.logError(
        `Trying to render a group with a non-group element. Rendering fragment for: [${
            props.element
        }]`
    );

    return <></>;
};
