import { IMetaAspect } from '@quino/core';

/**
 * The identifier for retrieving a default layout marker aspect.
 */
export const DefaultLayoutMarkerAspectIdentifier = 'DefaultElement';

/**
 * Default layout marker aspects
 */
export interface IDefaultLayoutMarkerAspect extends IMetaAspect {}
