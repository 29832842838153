import React from 'react';
import { HOC } from '../../util/types';
import { ConfigurationContext } from './ConfigurationContext';
import { IWithConfigurationContext } from './IWithConfigurationContext';

export const withConfigurationContext: HOC<IWithConfigurationContext> = (InnerComponent: any) => (
    props
) => (
    <ConfigurationContext.Consumer>
        {(configuration) => <InnerComponent configuration={configuration} {...props} />}
    </ConfigurationContext.Consumer>
);
