import React, { useEffect, useRef, useState } from 'react';
import { Translation } from '@quino/ui';
import { tKey } from '../../../../lang/TranslationKeys';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import { IChangePasswordState } from './IChangePasswordState';
import { IChangePasswordStateHelper } from './IChangePasswordStateHelper';
import { SHARED_SERVICE_IDENTIFIER } from '../../../../ioc/sharedIdentifiers';
import { PasswordTextField } from '../../../Auth/PasswordTextField/PasswordTextField';
import { ErrorMessage } from '../../../Util/ErrorMessage';
import { errorMessageProps } from '../../../Util/Devextreme/DevextremeHelpers';
import { PopupButtonBar } from '../../../Util/Devextreme/PopupButtonBar';
import { useI18n } from '../../../../lang/useI18n';
import { useService } from '../../../../ioc/hook/useService';

type TStyles = {
    changePasswordTextField: string;
    changePasswordButtonIcon: string;
};

const styles: TStyles = require('./ChangePasswordDialog.less');

interface IProps {
    onClose?: () => void;
}

export const ChangePasswordDialog: React.FC<IProps> = (props) => {
    const i18n = useI18n();
    const stateHelper = useService<IChangePasswordStateHelper>(
        SHARED_SERVICE_IDENTIFIER.ICHANGEPASSWORDSTATEHELPER
    );

    const [inProgress, setInProgress] = useState(false);

    const [state, setState] = useState<IChangePasswordState>({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        success: false,
        isValid: true,
    });

    const popupRef = useRef<Popup>(null);

    const repaint = () => {
        popupRef.current && popupRef.current.instance.repaint();
    };
    useEffect(repaint, [inProgress]);

    const updateField = (fieldName: keyof IChangePasswordState) => ({ event }: any) =>
        setState({ ...state, [fieldName]: event.target.value } as any);

    const submit = async (event: Pick<Event, 'preventDefault'>) => {
        event.preventDefault();
        setInProgress(true);

        const validatedState = await stateHelper.validateAsync(state);
        setState(validatedState);

        const submittedState = await stateHelper.submitAsync(validatedState);
        setState(submittedState);

        setInProgress(false);
    };

    const renderSuccessMessage = () => {
        return (
            <>
                <Translation
                    i18nKey={tKey('literal.CustomLiterals.ChangePassword.SuccessMessage')}
                />
                <PopupButtonBar>
                    <Button
                        type={'default'}
                        onClick={props.onClose}
                        text={i18n.t('literal.CustomLiterals.ChangePassword.Confirm')}
                    />
                </PopupButtonBar>
            </>
        );
    };

    const renderForm = () => {
        const {
            oldPassword,
            newPassword,
            confirmNewPassword,
            oldPasswordError,
            newPasswordError,
            confirmNewPasswordError,
            generalError,
        } = state;

        return (
            <>
                <form autoComplete="off" onSubmit={submit} noValidate>
                    <PasswordTextField
                        id="oldPassword"
                        value={oldPassword}
                        placeholder={i18n.t('literal.CustomLiterals.ChangePassword.OldPassword')}
                        width={'100%'}
                        onInput={updateField('oldPassword')}
                        className={styles.changePasswordTextField}
                        {...errorMessageProps(oldPasswordError)}
                    />
                    <PasswordTextField
                        id="newPassword"
                        value={newPassword}
                        placeholder={i18n.t('literal.CustomLiterals.ChangePassword.NewPassword')}
                        width={'100%'}
                        onChange={updateField('newPassword')}
                        className={styles.changePasswordTextField}
                        {...errorMessageProps(newPasswordError)}
                    />
                    <PasswordTextField
                        id="confirmNewPassword"
                        value={confirmNewPassword}
                        placeholder={i18n.t(
                            'literal.CustomLiterals.ChangePassword.ConfirmNewPassword'
                        )}
                        width={'100%'}
                        onChange={updateField('confirmNewPassword')}
                        className={styles.changePasswordTextField}
                        {...errorMessageProps(confirmNewPasswordError)}
                    />
                </form>
                {generalError && <ErrorMessage>{generalError}</ErrorMessage>}
                <LoadPanel visible={inProgress} />
                <PopupButtonBar>
                    <Button
                        onClick={props.onClose}
                        stylingMode={'outlined'}
                        type={'default'}
                        disabled={inProgress}
                        icon={'close'}
                        text={i18n.t('literal.CustomLiterals.ChangePassword.Cancel')}
                    />
                    <Button
                        onClick={({ event }) => submit(event as Event)}
                        stylingMode={'contained'}
                        type={'default'}
                        disabled={inProgress}
                        icon={'save'}
                        text={i18n.t('literal.CustomLiterals.ChangePassword.Submit')}
                    />
                </PopupButtonBar>
            </>
        );
    };

    return (
        <Popup
            onHiding={props.onClose}
            visible={true}
            title={i18n.t('literal.CustomLiterals.ChangePassword.Title')}
            height={'auto'}
            width={'auto'}
            dragEnabled={false}
            ref={popupRef}
        >
            <div>{state.success ? renderSuccessMessage() : renderForm()}</div>
        </Popup>
    );
};
