import { ICrmContext } from '../../crmContext/ICrmContext';

export class CrmContextSearchService {
    static filterContextsForSearchString(
        crmContexts: ICrmContext[],
        searchString: string
    ): ICrmContext[] {
        return crmContexts.filter((context) =>
            // Remove accents/diacritics: https://stackoverflow.com/a/37511463
            CrmContextSearchService.normalize(context.caption).includes(
                CrmContextSearchService.normalize(searchString)
            )
        );
    }

    static normalize(value: string): string {
        return value
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .toLowerCase();
    }
}
