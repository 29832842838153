import {
    getAspectOrDefault,
    getMetaProperty,
    IExpressionEvaluator,
    IFieldValidator,
    II18n,
    IMetaAspect,
    IMetaProperty,
    QuinoCoreServiceSymbols,
    RequiredRuleAspectIdentifier,
} from '@quino/core';
import { inject, injectable } from 'inversify';
import { PerformaValidationErrorCodes } from './PerformaValidationErrorCodes';
import { tKey } from '../../lang/TranslationKeys';
import {
    IUploadSettingsAspect,
    UploadSettingsAspectIdentifier,
} from '../../meta/aspects/IUploadSettingsAspect';
import { IValidationContext } from '@quino/core/dist/validations/IValidationContext';
import { IFieldValidationResult } from '@quino/core/dist/validations/IFieldValidationResult';
import { IFilesSaveValue } from '../../Components/Form/Upload/Upload';

@injectable()
export class FileUploadValidator implements IFieldValidator {
    constructor(
        @inject(QuinoCoreServiceSymbols.II18N) private i18n: II18n,
        @inject(QuinoCoreServiceSymbols.IExpressionEvaluator)
        private expressionEvaluator: IExpressionEvaluator
    ) {}

    validate(
        field: IMetaProperty,
        value: any,
        context: IValidationContext
    ): IFieldValidationResult {
        if (field.controlName.toLocaleLowerCase() === 'upload') {
            const parsedData: IFilesSaveValue | undefined = value ? JSON.parse(value) : undefined;
            const metaProperty = getMetaProperty(field);
            const uploadSettingsAspect = getAspectOrDefault<IUploadSettingsAspect>(
                metaProperty,
                UploadSettingsAspectIdentifier
            );
            const requiredRuleAspect = getAspectOrDefault(field, RequiredRuleAspectIdentifier);

            if (!parsedData) {
                if (
                    this.isRequiredField(requiredRuleAspect, field, context) &&
                    uploadSettingsAspect &&
                    uploadSettingsAspect.minfiles &&
                    uploadSettingsAspect.minfiles > 0
                ) {
                    return this.getMinFilesError(field, uploadSettingsAspect.minfiles);
                }
                if (this.isRequiredField(requiredRuleAspect, field, context)) {
                    return this.getMinFilesError(field, 1);
                }
                return {};
            }

            if (parsedData.uploadInProgress) {
                return this.getUploadInProgressError(field);
            }

            if (
                (this.isRequiredField(requiredRuleAspect, field, context) &&
                    uploadSettingsAspect &&
                    !FileUploadValidator.validateMinNumberOfUploads(
                        uploadSettingsAspect.minfiles,
                        parsedData.numberOfFiles
                    )) ||
                (this.isRequiredField(requiredRuleAspect, field, context) &&
                    parsedData.numberOfFiles < 1)
            ) {
                return this.getMinFilesError(
                    field,
                    uploadSettingsAspect ? uploadSettingsAspect.minfiles : 1
                );
            }

            if (
                uploadSettingsAspect &&
                !FileUploadValidator.validateMaxNumberOfUploads(
                    uploadSettingsAspect.maxfiles,
                    parsedData.numberOfFiles
                )
            ) {
                return this.getMaxFilesError(field, uploadSettingsAspect.maxfiles);
            }
        }

        return {};
    }

    isRequiredField = (
        validationRule: IMetaAspect | null,
        field: IMetaProperty,
        context: IValidationContext
    ) => {
        if (field.required) {
            return this.expressionEvaluator.evaluate<boolean>(
                field.required,
                context != null ? context.data : {}
            );
        }

        return validationRule;
    };

    private static validateMinNumberOfUploads(minFiles?: number, numberOfFiles?: number) {
        numberOfFiles = numberOfFiles ? numberOfFiles : 0;

        if (minFiles) {
            if (numberOfFiles < minFiles) {
                return false;
            }
        }

        return true;
    }

    private static validateMaxNumberOfUploads(maxFiles?: number, numberOfFiles?: number) {
        numberOfFiles = numberOfFiles ? numberOfFiles : 0;

        if (maxFiles) {
            if (numberOfFiles > maxFiles) {
                return false;
            }
        }

        return true;
    }

    private getMinFilesError(field: IMetaProperty, minFiles?: number) {
        return {
            fieldErrors: [
                {
                    fieldName: field.name,
                    errorCode: PerformaValidationErrorCodes.InvalidFileUploadNumber,
                    errorMessage: this.i18n.t(
                        tKey('literal.CustomLiterals.Validations.InvalidFileUploadMinNumber'),
                        {
                            minFiles: minFiles && minFiles,
                        }
                    ),
                },
            ],
        };
    }

    private getMaxFilesError(field: IMetaProperty, maxFiles?: number) {
        return {
            fieldErrors: [
                {
                    fieldName: field.name,
                    errorCode: PerformaValidationErrorCodes.InvalidFileUploadNumber,
                    errorMessage: this.i18n.t(
                        tKey('literal.CustomLiterals.Validations.InvalidFileUploadMaxNumber'),
                        {
                            maxFiles: maxFiles && maxFiles,
                        }
                    ),
                },
            ],
        };
    }

    private getUploadInProgressError(field: IMetaProperty) {
        return {
            fieldErrors: [
                {
                    fieldName: field.name,
                    errorCode: PerformaValidationErrorCodes.FileInProgress,
                    errorMessage: this.i18n.t(
                        tKey('literal.CustomLiterals.Validations.FileInProgress')
                    ),
                },
            ],
        };
    }
}
