import { inject, injectable } from 'inversify';
import { IQuinoMetaPanelActions } from '@quino/ui/dist/components/QuinoMetaPanel/IQuinoMetaPanelActions';
import {
    IDataDiffer,
    IDataService,
    IGenericObject,
    II18n,
    IValidationResult,
    IValidator,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { IBookmark, IBookmarkFactory, ObjectBookmark, QuinoUIServiceSymbols } from '@quino/ui';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { IPerformaServer } from '../../api/IPerformaServer';
import { ILayoutCatalogManager } from './ILayoutCatalogManager';
import { isPerformaBadRequestClientError } from '../../api/PerformaBadRequestClientError';
import { IRouteHelper } from '../../navigation/IRouteHelper';
import { CrmContextIdentifierSerializer } from '../../crmContext/CrmContextIdentifierSerializer';

@injectable()
export class PerformaMetaPanelActions implements IQuinoMetaPanelActions {
    constructor(
        @inject(QuinoCoreServiceSymbols.IDataService) private dataService: IDataService,
        @inject(QuinoCoreServiceSymbols.IDataDiffer) private dataDiffer: IDataDiffer,
        @inject(QuinoCoreServiceSymbols.II18N) private i18n: II18n,
        @inject(QuinoCoreServiceSymbols.IValidator) private validator: IValidator,
        @inject(QuinoUIServiceSymbols.IBookmarkFactory) private bookmarkFactory: IBookmarkFactory,
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMASERVER) private performaServer: IPerformaServer,
        @inject(SHARED_SERVICE_IDENTIFIER.ILAYOUTCATALOGMANAGER)
        private layoutCatalogManager: ILayoutCatalogManager,
        @inject(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER) private routeHelper: IRouteHelper,
    ) {}

    delete = async (data: IGenericObject): Promise<void> => {
        try {
            await this.dataService.deleteObjectsAsync(data.metaClass, [data.primaryKey!]);
        } catch (error) {
            throw new Error(this.i18n.t('literal.CustomLiterals.Detail.ErrorCouldNotDeleteData'));
        }
    };

    drilldown = async (data: IGenericObject, context?: any, event?: any): Promise<IBookmark> => {
        if (this.drilldownInProgress) {
            return Promise.reject('Debounced drilldown');
        }
        try {
            this.drilldownInProgress = true;
            const { metaClass, primaryKey } = data;
            const { formLayouts } = await this.layoutCatalogManager.getLayoutsAsync(metaClass);
            const defaultLayout = this.layoutCatalogManager.getDefaultLayout(
                formLayouts || [],
                context // defaultDetailLayoutName
            );
            const genericObject = await this.dataService.getObjectAsync<IGenericObject>(
                metaClass,
                primaryKey!,
                defaultLayout.name
            );

            const layoutOverwrite = await this.layoutCatalogManager.getLayoutForObject(
                genericObject,
                formLayouts || [],
                context // defaultDetailLayoutName
            );

            let object = genericObject;
            let layout = defaultLayout;
            if (defaultLayout.name !== layoutOverwrite.name) {
                layout = layoutOverwrite;
                object = await this.dataService.getObjectAsync<IGenericObject>(
                    metaClass,
                    primaryKey!,
                    layoutOverwrite.name
                );
            }

            const objectBookmark = this.bookmarkFactory.createObject(object, layout);
            const crmContext = CrmContextIdentifierSerializer.deserialize(location.pathname.split('/')[1]);

            if (event && event.ctrlKey && crmContext) {
                const url = this.routeHelper.getUrl(
                    crmContext,
                    objectBookmark.genericObject.metaClass,
                    objectBookmark.genericObject.primaryKey
                );
                window.open(url, '_blank')
                return Promise.reject('Opened Detail in new Tab');
            }

            return Promise.resolve(objectBookmark);
        } catch (e) {
            return Promise.reject(e);
        } finally {
            this.drilldownInProgress = false;
        }
    };

    save = async (
        data: IGenericObject,
        original: IGenericObject,
        context?: any
    ): Promise<IGenericObject> => {
        try {
            const changedValues = this.dataDiffer.getChangedValues<IGenericObject>(original, data);
            let genericObject: IGenericObject;

            // TODO: remove 'null' check if https://secure.encodo.ch/jira/browse/QNOWEB-159 is fixed
            if (data.primaryKey && data.primaryKey !== 'null') {
                genericObject = await this.performaServer.updateObjectAsync<IGenericObject>(
                    data.metaClass,
                    data.primaryKey,
                    context.layout.name,
                    changedValues
                );
            } else {
                genericObject = await this.performaServer.insertObjectAsync<IGenericObject>(
                    data.metaClass,
                    context.layout.name,
                    {
                        ...changedValues,
                        ...(context.foreignKey ? context.foreignKey : {}),
                    }
                );
            }

            if (data.metaClass === 'contact' && context.reloadCrmContextsAsync) {
                await context.reloadCrmContextsAsync();
            }

            return genericObject;
        } catch (error) {
            let message: string;
            if (error && error.message) {
                message = this.i18n.t(
                    'literal.CustomLiterals.Detail.ErrorCouldNotSaveDataWithMessage',
                    {
                        errorMessage: error.message,
                    }
                );
            } else {
                message = this.i18n.t('literal.CustomLiterals.Detail.ErrorCouldNotSaveData');
            }

            if (isPerformaBadRequestClientError(error)) {
                throw error;
            }

            throw new Error(message);
        }
    };

    validate = (data: IBookmark): Promise<IValidationResult> => {
        if (data instanceof ObjectBookmark) {
            data.fieldErrors.clear();
            data.generalErrors.splice(0, data.generalErrors.length);

            const validationResult = this.validator.validate(data.layout, data.genericObject);
            if (validationResult.generalErrors != null) {
                validationResult.generalErrors.forEach((x) => data.generalErrors.push(x));
            }
            if (validationResult.fieldErrors != null) {
                validationResult.fieldErrors.forEach((x) => {
                    if (
                        data.fieldErrors.has(x.fieldName) &&
                        data.fieldErrors
                            .get(x.fieldName)!
                            .filter((value) => value.errorMessage === x.errorMessage).length === 0
                    ) {
                        data.fieldErrors.get(x.fieldName)!.push(x);
                    } else {
                        data.fieldErrors.set(x.fieldName, [x]);
                    }
                });
            }

            return Promise.resolve(validationResult);
        }

        return Promise.reject('Cant validate bookmark type');
    };

    private drilldownInProgress = false;
}
