import React, { useContext } from 'react';
import { PopupButtonBar } from '../Util/Devextreme/PopupButtonBar';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { useI18n } from '../../lang/useI18n';
import { LoginContext } from '../../contexts/loginContext/LoginContext';
import { GlobalErrorContext } from './GlobalErrorContext';

export interface IErrorPopup {
    title: string;
    error: Error;
    logout: boolean;
}

type TStyles = {
    errorPopup: string;
};

const styles: TStyles = require('./ErrorPopup.less');

export const ErrorPopup = (props: { error: IErrorPopup }) => {
    const { error, logout, title } = props.error;
    const i18n = useI18n();
    const loginContext = useContext(LoginContext);
    const [, setGlobalError] = useContext(GlobalErrorContext);

    return (
        <Popup
            className={styles.errorPopup}
            visible={error !== undefined}
            showTitle={true}
            title={title}
            closeOnOutsideClick={false}
            showCloseButton={false}
            width={400}
            height={'auto'}
            key={'errorPopup_' + title + error.toString()}
        >
            <span style={{ wordWrap: 'break-word' }}>{error.toString()}</span>
            <PopupButtonBar>
                <Button
                    onClick={() => {
                        setGlobalError(undefined);
                        logout && loginContext.onTryLogoutAsync();
                    }}
                    type={'default'}
                    text={i18n.t('literal.CustomLiterals.Confirm')}
                />
            </PopupButtonBar>
        </Popup>
    );
};
