import React from 'react';
import { TUserMenuItem } from './Components/UserMenu/UserMenu';
import { UserMenu } from './Components/UserMenu/UserMenu';
import { UserMenuButton } from './Components/UserMenuButton/UserMenuButton';
import { IAppConfig } from '../../../../contexts/appConfigContext/IAppConfig';
import { ILoginContextState } from '../../../../contexts/loginContext/ILoginContextState';
import { IPerformaI18n } from '../../../../lang/IPerformaI18n';
import { Typography } from '../../../Util/Devextreme/Typography';
import { withAppConfig } from '../../../../contexts/appConfigContext/withAppConfig';
import { withLoginContext } from '../../../../contexts/loginContext/withLoginContext';
import { RouteComponentProps, withRouter } from 'react-router';
import { IRouteHelper } from '../../../../navigation/IRouteHelper';
import { lazyInject, QuinoCoreServiceSymbols } from '@quino/core';
import { SHARED_SERVICE_IDENTIFIER } from '../../../../ioc/sharedIdentifiers';
import { tKey } from '../../../../lang/TranslationKeys';
import { Translation } from '@quino/ui';
import { LanguageSwitcher } from './Components/LanguageSwitcher/LanguageSwitcher';
import { SidebarCloser } from './Components/SidebarCloser/SidebarCloser';
import { Button } from 'devextreme-react/button';
import { ILocationService } from '../../../../navigation/ILocationService';

interface IStyles {
    root: string;
    grow: string;
    appBarWrapper: string;
    appBar: string;
    appTitle: string;
    menuButton: string;
    logo: string;
    hide: string;
    toolbar: string;
}
const styles: IStyles = require('./PerformaAppBar.less');

interface IProps extends IAppConfig, RouteComponentProps<any>, ILoginContextState {
    isDrawerOpen: boolean;
    onDrawerOpen: () => void;
    onDrawerClose: () => void;
    onChangePasswordClick: () => void;
}

interface IState {
    userMenuOpen: boolean;
}

class PerformaAppBarComponent extends React.Component<IProps, IState> {
    @lazyInject(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER)
    private routeHelper: IRouteHelper;
    @lazyInject(QuinoCoreServiceSymbols.II18N)
    private i18n: IPerformaI18n;
    @lazyInject(SHARED_SERVICE_IDENTIFIER.ILOCATIONSERVICE)
    private locationService: ILocationService;

    private userMenuButton: any;

    constructor(props: IProps) {
        super(props);
        this.state = { userMenuOpen: false };
    }

    handleUserMenuClick = () => {
        this.setState({ userMenuOpen: !this.state.userMenuOpen });
    };

    handleUserMenuClose = () => {
        this.setState({ userMenuOpen: false });
    };

    handleUserMenuItemClick = (menuItem: TUserMenuItem) => {
        this.setState({ userMenuOpen: false });
        switch (menuItem) {
            case 'SignOffMenuItem':
                this.locationService.performAction(this.props.onTryLogoutAsync);
                break;
            case 'ProfileMenuItem':
                this.locationService.navigateToUrl(
                    this.props.history,
                    this.routeHelper.getProfileUrl()
                );
                break;
            case 'SettingsMenuItem':
                this.locationService.navigateToUrl(
                    this.props.history,
                    this.routeHelper.getSettingsUrl()
                );
                break;
            case 'ChangePasswordMenuItem':
                this.props.onChangePasswordClick();
                break;
        }
    };

    handleNavigateToRoot = () => {
        this.locationService.navigateToUrl(this.props.history, this.routeHelper.getStartUrl());
    };

    render() {
        const { isDrawerOpen, onDrawerOpen } = this.props;
        const { userMenuOpen } = this.state;

        return (
            <div className={`${styles.appBarWrapper}`}>
                <SidebarCloser
                    isDrawerOpen={this.props.isDrawerOpen}
                    onClick={this.props.onDrawerClose}
                />
                <div className={styles.appBar}>
                    <Button
                        stylingMode={'text'}
                        hint={this.i18n.t('literal.CustomLiterals.Header.OpenSidebar')}
                        onClick={onDrawerOpen}
                        className={styles.menuButton}
                        style={{ display: isDrawerOpen ? 'none' : 'block' }}
                        icon={'menu'}
                        width={48}
                        height={48}
                    />

                    {this.props.headerIcon && (
                        <span
                            className={styles.logo}
                            onClick={this.handleNavigateToRoot}
                            style={{ cursor: 'pointer' }}
                        >
                            {React.createElement(this.props.headerIcon)}
                        </span>
                    )}

                    <Typography>
                        <h6 className={styles.appTitle}>
                            <Translation
                                i18nKey={tKey('literal.CustomLiterals.Application.Title')}
                            />
                        </h6>
                    </Typography>

                    <div className={styles.grow} />

                    <LanguageSwitcher />

                    <div ref={(ref) => (this.userMenuButton = ref)}>
                        <UserMenuButton onClick={this.handleUserMenuClick} />
                    </div>
                </div>

                <UserMenu
                    isOpen={userMenuOpen}
                    anchorElement={this.userMenuButton}
                    onClose={this.handleUserMenuClose}
                    onClickMenuItem={(menuItem) => this.handleUserMenuItemClick(menuItem)}
                />
            </div>
        );
    }
}

export const PerformaAppBar = withAppConfig(withLoginContext(withRouter(PerformaAppBarComponent)));
