import { HOC } from '../util/types';
import { Provider } from 'react-redux';
import { performaStore } from './store';
import React from 'react';

export const withStoreProvider: HOC = (InnerComponent) => (props) => (
    <Provider store={performaStore}>
        <InnerComponent {...props} />
    </Provider>
);
