import React from 'react';
import { withConfigurationContext } from '../../contexts/configurationContext/withConfigurationContext';
import { IWithConfigurationContext } from '../../contexts/configurationContext/IWithConfigurationContext';

type TStyles = {
    root: string;
    message: string;
};

const styles: TStyles = require('./BannerTextDecorator.less');

interface IProps extends IWithConfigurationContext {}

const BannerTextDecoratorComponent: React.FunctionComponent<IProps> = ({ configuration }) => {
    const bannerText = configuration.getStringValue('client.UI.BannerCaption');

    if (!bannerText) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.message}>{bannerText}</div>
        </div>
    );
};

export const BannerTextDecorator = withConfigurationContext(BannerTextDecoratorComponent);
