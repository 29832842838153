import { inject, injectable } from 'inversify';
import { IPerformaStorage } from './IPerformaStorage';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IPerformaFileHandler } from './IPerformaFileHandler';
import { IFileMetadata } from '../api/DTOs/IFileMetadata';
import cloneDeep from 'lodash/cloneDeep';
import { isPerformaServerError, PerformaServerError } from '../api/PerformaServerError';

interface IPerformaFileHandlerState {
    initial: IFileMetadata[];
    new: IFileMetadata[];
    deleted: IFileMetadata[];
}

@injectable()
export class PerformaFileHandler implements IPerformaFileHandler {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMASTORAGE)
        private performaStorage: IPerformaStorage
    ) {}

    private files: IPerformaFileHandlerState = { initial: [], new: [], deleted: [] };

    async init(entity: string, primaryKey: string, context: string): Promise<IFileMetadata[]> {
        let fileHandlerInitialMetadata = await this.performaStorage.getStorageMetadataForOwner(
            entity,
            primaryKey
        );

        // filter files for context
        fileHandlerInitialMetadata = fileHandlerInitialMetadata.filter(
            (f) => f.customAttributes['context'] && f.customAttributes['context'] === context
        );

        this.files = { initial: fileHandlerInitialMetadata, new: [], deleted: [] };
        return this.returnFiles();
    }

    async addFile(id: string): Promise<IFileMetadata[]> {
        const newFileMetadata = await this.performaStorage.getMetaDataAsync(id);
        this.files.new.push(newFileMetadata);

        return this.returnFiles();
    }

    deleteFile(id: string): IFileMetadata[] {
        const newFileToDelete = this.files.new.find((metadata) => metadata.id === id);
        if (newFileToDelete) {
            // if file is a new file, file can just be removed and deleted
            this.files.new = this.files.new.filter((metadata) => metadata.id !== id);
            this.performaStorage.deleteStorage(id);
        } else {
            const initialFileToDelete = this.files.initial.find((metadata) => metadata.id === id);
            if (initialFileToDelete) {
                // if file is an old file, file should be deleted on commit
                this.files.deleted.push(initialFileToDelete);
            }
        }

        return this.returnFiles();
    }

    async commitAllFiles(
        entity: string,
        primaryKey: string,
        context?: string
    ): Promise<{ fileMetaData: IFileMetadata[]; errors: PerformaServerError[] }> {
        const errors: PerformaServerError[] = [];
        for (const metadata of this.files.new) {
            try {
                await this.performaStorage.setOwnerAndContextAsync(
                    metadata.id,
                    entity,
                    primaryKey,
                    context
                );
                this.files.initial.push(metadata);
            } catch (e) {
                if (isPerformaServerError(e)) {
                    errors.push(e);
                }
            }
        }

        this.files.new = [];

        const deletePromises = this.files.deleted.map((metadata) => {
            this.performaStorage.deleteStorage(metadata.id);
            this.files.initial = this.files.initial.filter(
                (initialMetadata) => initialMetadata.id !== metadata.id
            );
        });
        await Promise.all(deletePromises);
        this.files.deleted = [];

        return { fileMetaData: this.returnFiles(), errors: errors };
    }

    reset(): IFileMetadata[] {
        const deletePromises = this.files.new.map((metadata) =>
            this.performaStorage.deleteStorage(metadata.id)
        );
        Promise.all(deletePromises);

        this.files = { initial: this.files.initial, new: [], deleted: [] };
        return this.returnFiles();
    }

    returnFiles(): IFileMetadata[] {
        let currentFiles = this.files.initial.filter(
            (initialMetadata) =>
                !this.files.deleted.some(
                    (deletedMetadata) => deletedMetadata.id === initialMetadata.id
                )
        );
        currentFiles = currentFiles.concat(this.files.new);

        return cloneDeep(currentFiles);
    }
}
