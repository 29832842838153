import React from 'react';
import { ISiteMap, ISiteMapItem } from '../../navigation';

export interface ISiteMapState {
    currentSiteMapItem?: ISiteMapItem;
    siteMap: ISiteMap;
    onSetCurrentSiteMapItem: (siteMapItem: ISiteMapItem) => void;
    siteMapContainsCurrentEntity: (entity: string) => boolean;
}

// noinspection SpellCheckingInspection
export const SiteMapContext = React.createContext<ISiteMapState>({
    currentSiteMapItem: undefined,
    siteMap: { nodes: [] },
    onSetCurrentSiteMapItem: () => {},
    siteMapContainsCurrentEntity: () => {
        return false;
    },
});
