import {
    getAspectOrDefault,
    IFieldValidator,
    II18n,
    IMetaProperty,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { inject, injectable } from 'inversify';
import { PerformaValidationErrorCodes } from './PerformaValidationErrorCodes';
import { tKey } from '../../lang/TranslationKeys';
import { IValidationContext } from '@quino/core/dist/validations/IValidationContext';
import { IFieldValidationResult } from '@quino/core/dist/validations/IFieldValidationResult';

@injectable()
export class BirthdateValidator implements IFieldValidator {
    constructor(@inject(QuinoCoreServiceSymbols.II18N) private i18n: II18n) {}

    private minAge = 15;
    private maxAge = 100;

    validate(
        field: IMetaProperty,
        value: any,
        context: IValidationContext
    ): IFieldValidationResult {
        if (!value) {
            return {};
        }

        if (!getAspectOrDefault(field, 'Birthdate')) {
            return {};
        }

        if (typeof value !== 'string') {
            return this.getError(field);
        }

        const date = new Date(value);

        if (!BirthdateValidator.isValidDate(date)) {
            return this.getError(field);
        }

        if (!this.isValidBirthdate(date)) {
            return this.getError(field);
        }

        return {};
    }

    private isValidBirthdate(date: Date): boolean {
        let diffYear = (new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24 * 365.25);
        diffYear = Math.floor(diffYear);
        return this.minAge <= diffYear && diffYear <= this.maxAge;
    }

    private static isValidDate(date: unknown): boolean {
        return date instanceof Date && !isNaN(date.getTime());
    }

    private getError(field: IMetaProperty) {
        return {
            fieldErrors: [
                {
                    fieldName: field.name,
                    errorCode: PerformaValidationErrorCodes.InvalidBirthdate,
                    errorMessage: this.i18n.t(
                        tKey('literal.CustomLiterals.Validations.InvalidBirthdate'),
                        { minAge: this.minAge, maxAge: this.maxAge }
                    ),
                },
            ],
        };
    }
}
