import { CrmContextIdentifierSerializer } from '../crmContext/CrmContextIdentifierSerializer';
import { ICrmContextState } from '../contexts/crmContext';
import { IContextRouteChecker } from './IContextRouteChecker';
import { injectable } from 'inversify';

@injectable()
export class ContextRouteChecker implements IContextRouteChecker {
    async changeCrmContextIfNecessaryAsync(
        crmContextState: ICrmContextState,
        serializedCrmContext: string = ''
    ) {
        const urlCrmContextIdentifier = CrmContextIdentifierSerializer.deserialize(
            serializedCrmContext
        );
        if (!urlCrmContextIdentifier) {
            return;
        }

        if (
            crmContextState.activeCrmContext &&
            urlCrmContextIdentifier.id === crmContextState.activeCrmContext.context.id
        ) {
            return;
        }

        const urlCrmContext = crmContextState.availableCrmContexts.find(
            (c) => c.context.id === urlCrmContextIdentifier.id
        );

        if (urlCrmContext) {
            crmContextState.trySetActiveCrmContextAsync(urlCrmContext);
        }
    }
}
