export enum FieldDescriptionVisibility {
    Always = 'Always',
    OnHover = 'OnHover',
    Never = 'Never',
}

export interface IProfileFormState {
    inProgress: boolean;
    errorMessage?: string;
    allowSetPassword: boolean;
    isValid: boolean;
    isEdited: boolean;
    firstName: string;
    firstNameError?: string;
    lastName: string;
    lastNameError?: string;
    emailAddress: string;
    emailAddressError?: string;
    showFieldDescriptions: FieldDescriptionVisibility;
    languageCode: string;
    languageCodeError?: string;
    password: string;
    passwordError?: string;
    passwordConfirmation: string;
    passwordConfirmationError?: string;
}
