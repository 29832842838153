import { inject, injectable } from 'inversify';
import {
    FieldValidationErrorCodes,
    getAspectOrDefault,
    IExpressionEvaluator,
    IFieldValidationResult,
    IFieldValidator,
    IMetaAspect,
    IMetaProperty,
    IValidationContext,
    IValidationMessageProvider,
    QuinoCoreServiceSymbols,
    RequiredRuleAspectIdentifier,
} from '@quino/core';

@injectable()
export class PerformaRequiredRuleValidator implements IFieldValidator {
    constructor(
        @inject(QuinoCoreServiceSymbols.IValidationMessageProvider)
        private msgProvider: IValidationMessageProvider,
        @inject(QuinoCoreServiceSymbols.IExpressionEvaluator)
        private expressionEvaluator: IExpressionEvaluator
    ) {}

    validate(
        field: IMetaProperty,
        value: any,
        context: IValidationContext
    ): IFieldValidationResult {
        const aspect = getAspectOrDefault(field, RequiredRuleAspectIdentifier);

        if (
            field.controlName.toLocaleLowerCase() === 'upload' ||
            this.isNotRequiredField(aspect, field, context) ||
            this.valueIsNotEmpty(value)
        ) {
            return {};
        }

        return {
            fieldErrors: [
                {
                    fieldName: field.name,
                    errorCode: FieldValidationErrorCodes.REQUIRED,
                    errorMessage: this.msgProvider.getRequiredError(),
                },
            ],
        };
    }

    isNotRequiredField = (
        validationRule: IMetaAspect | null,
        field: IMetaProperty,
        context: IValidationContext
    ) => {
        if (field.required) {
            return !this.expressionEvaluator.evaluate<boolean>(
                field.required,
                context != null ? context.data : {}
            );
        }

        return !validationRule;
    };

    valueIsNotEmpty = (value: any) => value !== undefined && value !== null && value !== '';
}
