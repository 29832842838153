import React, { useContext, useEffect, useState } from 'react';
import { IDataEntryStatusService } from '../../../api/IDataEntryStatusService';
import { IUserSettingsContextState } from './IUserSettingsContextState';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc';
import { AppLoadingScreen } from '../../PageRoot/AppLoadingScreen';
import { useService } from '../../../ioc/hook/useService';
import { ConfigurationContext } from '../../../contexts/configurationContext/ConfigurationContext';
import { UserSettingsContext } from './UserSettingsContext';
import { ServerNotReachableTimeout } from '../../ServerNotReachable';
import { UserSettingsErrorPopup } from './UserSettingsErrorPopup';

export const UserSettingsContextProvider: React.FunctionComponent = ({ children }) => {
    const dataEntryStatusService = useService<IDataEntryStatusService>(
        SHARED_SERVICE_IDENTIFIER.IDATAENTRYSTATUSSERVICE
    );

    const [contextState, setContextState] = useState<IUserSettingsContextState>();
    const [error, setError] = useState<boolean>(false);

    const configuration = useContext(ConfigurationContext);
    const isSelfRegistrationAllowed = (): boolean => {
        return configuration.getBooleanValueOrDefault(
            'client.Registration.AllowSelfRegistration',
            false
        );
    };

    const reloadContextState = async () => {
        try {
            const dto = await dataEntryStatusService.getDataEntryStatusAsync();

            setContextState({
                entityName: dto.entityName,
                additionalDataEntryRequired: dto.additionalDataEntryRequired,
                entityObject: dto.entityObject,
                layoutName: dto.formViewName,
            });
        } catch (e) {
            setError(true);
        }
    };

    const isUserSettingsContextRequired = isSelfRegistrationAllowed();

    useEffect(
        () => {
            isUserSettingsContextRequired && reloadContextState();
        },
        [dataEntryStatusService]
    );

    if (!isUserSettingsContextRequired) {
        return <>{children}</>;
    }

    if (!contextState) {
        if (error) {
            return <UserSettingsErrorPopup />;
        }

        return (
            <>
                <AppLoadingScreen />
                <ServerNotReachableTimeout />
            </>
        );
    }

    return (
        <UserSettingsContext.Provider value={[contextState, { reload: reloadContextState }]}>
            {children}
        </UserSettingsContext.Provider>
    );
};
