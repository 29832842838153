import React from 'react';
import { LoadIndicator } from 'devextreme-react/load-indicator';

export const AppLoadingScreen = () => (
    <div
        style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            padding: '20px',
        }}
    >
        <LoadIndicator height={50} width={50} />
    </div>
);
