/**
 * The identifier for retrieving a settings upload aspect.
 */
import { IExpression, IMetaAspect } from '@quino/core';

export const FilteredDropDownAspectIdentifier = 'ControlParameters';

/**
 * Upload settings which contains the context, minimum and maximum files to upload
 */
export interface IFilteredDropDownAspect extends IMetaAspect {
    filtereditemvaluesexpression: IExpression;
}
