import React, { useContext } from 'react';
import { Popover } from 'devextreme-react/popover';
import { ProfileContext } from '../../../../../../contexts/profileContext';
import { ConfigurationContext } from '../../../../../../contexts/configurationContext/ConfigurationContext';
import { UserSettingsContext } from '../../../../../UserSettings/UserSettingsContext/UserSettingsContext';
import { IUserSettingsContextState } from '../../../../../UserSettings/UserSettingsContext/IUserSettingsContextState';
import { Icon } from '../../../../../Util/Icon';
import { Avatar } from '../../../../../Util/Avatar';
import { UserMenuItem } from './UserMenuItem';
import { useI18n } from '../../../../../../lang/useI18n';

export type TUserMenuItem =
    | 'ChangePasswordMenuItem'
    | 'ProfileMenuItem'
    | 'SettingsMenuItem'
    | 'SignOffMenuItem';

type TStyles = {
    popover: string;
    userAvatar: string;
    userMenuList: string;
    divider: string;
    marginBottom: string;
    topDiv: string;
    bottomDiv: string;
};

const styles: TStyles = require('./UserMenu.less');

interface IProps {
    isOpen: boolean;
    anchorElement: HTMLElement;
    onClose: () => void;
    onClickMenuItem: (menuItem: TUserMenuItem) => void;
}

export const UserMenu: React.FunctionComponent<IProps> = (props: IProps) => {
    const i18n = useI18n();

    const { isOpen, anchorElement, onClose, onClickMenuItem } = props;

    const [profileData] = useContext(ProfileContext);
    const { firstName, lastName, emailAddress } = profileData;

    const configuration = useContext(ConfigurationContext);
    const isSelfRegistrationAllowed = (): boolean => {
        return configuration.getBooleanValueOrDefault(
            'client.Registration.AllowSelfRegistration',
            false
        );
    };

    const userSettingsContext = useContext(UserSettingsContext);
    const tryGetUserSettings = (): IUserSettingsContextState | false => {
        return !!userSettingsContext && userSettingsContext.length > 0 && userSettingsContext[0];
    };

    const showSettingsMenu = (): boolean => {
        const userSettings = tryGetUserSettings();
        return userSettings && isSelfRegistrationAllowed() && !!userSettings.layoutName;
    };

    const disableSettingsMenu = (): boolean => {
        const userSettings = tryGetUserSettings();
        return !userSettings || !userSettings.entityName || !userSettings.entityObject;
    };

    return (
        <Popover
            target={anchorElement}
            position={'bottom'}
            visible={isOpen}
            onHiding={onClose}
            className={styles.popover}
        >
            <div className={styles.userMenuList}>
                <UserMenuItem
                    onClick={() => onClickMenuItem('ProfileMenuItem')}
                    icon={() => (
                        <Avatar
                            label={
                                (firstName && firstName.charAt(0)) +
                                (lastName && lastName.charAt(0))
                            }
                        />
                    )}
                >
                    <div>
                        <div className={styles.topDiv}>{firstName + ' ' + lastName}</div>
                        <div className={styles.bottomDiv}>{emailAddress}</div>
                    </div>
                </UserMenuItem>
                <hr className={styles.divider} />
                <UserMenuItem
                    icon={() => <Icon name={'person'} />}
                    caption={i18n.t('literal.CustomLiterals.UserMenu.Profile')}
                    onClick={() => onClickMenuItem('ProfileMenuItem')}
                />
                <UserMenuItem
                    icon={() => <Icon name={'edit'} />}
                    caption={i18n.t('literal.CustomLiterals.UserMenu.ChangePassword')}
                    onClick={() => onClickMenuItem('ChangePasswordMenuItem')}
                />
                {showSettingsMenu() && (
                    <UserMenuItem
                        disabled={disableSettingsMenu()}
                        icon={() => <Icon name={'settings'} />}
                        caption={i18n.t('literal.CustomLiterals.UserMenu.Settings')}
                        onClick={() => onClickMenuItem('SettingsMenuItem')}
                    />
                )}
                <hr className={styles.divider} />
                <UserMenuItem
                    icon={() => <Icon name={'exit_to_app'} />}
                    caption={i18n.t('literal.CustomLiterals.UserMenu.SignOut')}
                    onClick={() => onClickMenuItem('SignOffMenuItem')}
                />
            </div>
        </Popover>
    );
};
