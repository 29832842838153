/**
 * Add the 'Performa-context' header to all requests
 */
import { IRequestDecorator } from '@quino/core';
import { ISessionService } from '../session/ISessionService';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { inject } from 'inversify';

export class PerformaContextHeaderDecorator implements IRequestDecorator {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE) private sessionService: ISessionService
    ) {}

    decorate = (request: Request): void => {
        const { activeCrmContext } = this.sessionService.getSessionState();
        if (!activeCrmContext) {
            return;
        }
        const jsonValue = JSON.stringify(activeCrmContext.context);
        request.headers.set('Performa-Context', jsonValue);
    };
}
