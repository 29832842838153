import React from 'react';
import { DataBrowser, ISiteMap } from 'performa-shared';
import { Documents } from '../Components';
import { tKey } from '../lang/TranslationKeys';
import { ExternalLink } from '../Components/ExternalLink/ExternalLink';

export const getPerformaNetSiteMap = (): ISiteMap => ({
    nodes: [
        {
            id: 'aktuell',
            translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.Current'),
            items: [
                {
                    path: 'px_event',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.Events'),
                    icon: 'event',
                    main: () => (
                        <DataBrowser
                            entityName="px_event"
                            defaultListLayoutName="Portal_Aktive_Events"
                            defaultDetailLayoutName="Portal_Read"
                        />
                    ),
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('px_event').canRead(),
                },
                {
                    path: 'px_eventmember',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.Participations'),
                    icon: 'event_seat',
                    main: () => (
                        <DataBrowser
                            entityName="px_eventmember"
                            defaultListLayoutName="Portal_Aktive_Buchungen"
                            defaultDetailLayoutName="Portal_Buchung"
                        />
                    ),
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('px_eventmember').canRead(),
                },
            ],
        },
        {
            id: 'projekte',
            translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.Projects'),
            items: [
                {
                    path: 'px_projecttasks',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.Tickets'),
                    icon: 'receipt',
                    main: () => <DataBrowser entityName="px_projecttasks" />,
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('px_projecttasks').canRead(),
                },
                {
                    path: 'px_meeting',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.Appointments'),
                    icon: 'calendar_today',
                    main: () => <DataBrowser entityName="px_meeting" />,
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('px_meeting').canRead(),
                },
                {
                    path: 'px_testcases',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.TestCases'),
                    icon: 'done_all',
                    main: () => <DataBrowser entityName="px_testcases" />,
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('px_testcases').canRead(),
                },
                {
                    path: 'Dokumente',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.Documents'),
                    icon: 'folder',
                    main: () => <Documents key={'documents'} />,
                },
            ],
        },
        {
            id: 'betrieb',
            translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.Operation'),
            items: [
                {
                    path: 'px_logbook',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.LogBook'),
                    icon: 'assignment',
                    main: () => <DataBrowser entityName="px_logbook" />,
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('px_logbook').canRead(),
                },
                {
                    path: 'px_monitor',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.Monitor'),
                    icon: 'miscellaneous_services',
                    main: () => <DataBrowser entityName="px_monitor" />,
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('px_monitor').canRead(),
                },
                {
                    path: 'incident',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.Incident'),
                    icon: 'feedback',
                    main: () => <DataBrowser entityName="incident" />,
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('incident').canRead(),
                },
                {
                    path: 'px_wartungsueberpruefung',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.MaintenanceCheck'),
                    icon: 'handyman',
                    main: () => (
                        <DataBrowser
                            entityName="px_wartungsueberpruefung"
                            defaultListLayoutName={'Portal_Wartungscheck'}
                            defaultDetailLayoutName={'Portal_Wartungscheck'}
                        />
                    ),
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('px_wartungsueberpruefung').canRead(),
                },
                {
                    path: 'quote',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.Quote'),
                    icon: 'request_quote' as any,
                    main: () => (
                        <DataBrowser entityName="quote" defaultListLayoutName="Portal_Angebote" />
                    ),
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('quote').canRead(),
                },
                {
                    path: 'px_restarbeiten',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.RemainingWork'),
                    icon: 'work',
                    main: () => <DataBrowser entityName="px_restarbeiten" />,
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('px_restarbeiten').canRead(),
                },
            ],
        },
        {
            id: 'product',
            translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.Product'),
            items: [
                {
                    path: 'performx_wikipedia',
                    translationKey: tKey('literal.CustomLiterals.PNet.MainMenu.PerformXWikipedia'),
                    icon: 'library_books',
                    main: () => (
                        <ExternalLink
                            key={'performx_wikipedia'}
                            url={'https://confluence.i-ag.ch/display/PD/PerformX+Dokumentation'}
                            target={'PerformX Wikipedia'}
                        />
                    ),
                },
            ],
        },
    ],
});
