import { initialListStates, listStatesReducer } from './listStates/store';
import { combineReducers, createStore } from 'redux';
import { initialSessionState, sessionStateReducer } from '../session/sessionStore';
import { apiReducer, initialApiState } from './api/apiStore';

const initialState = {
    api: initialApiState,
    listStates: initialListStates,
    session: initialSessionState,
};

export type TRootReducerState = typeof initialState;

const reducer = combineReducers({
    api: apiReducer,
    listStates: listStatesReducer,
    session: sessionStateReducer,
});

const tryGetDevTools = () => {
    if (typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        return (window as any).__REDUX_DEVTOOLS_EXTENSION__();
    }

    return undefined;
};

export const performaStore = createStore(reducer, initialState, tryGetDevTools());

export type TPerformaStore = typeof performaStore;
