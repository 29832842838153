import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../session/sessionStore';
import { useI18n } from '../../lang/useI18n';

export const useTranslationLoader = (onSuccess: () => void) => {
    const i18n = useI18n();

    const currentLanguage = useSelector(getCurrentLocale);

    useEffect(
        () => {
            currentLanguage &&
                (async () => {
                    await i18n.loadTranslationsAsync();
                    onSuccess();
                })();
        },
        [currentLanguage]
    );
};
