import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getAspectOrDefault, getMetaProperty, II18n, QuinoCoreServiceSymbols } from '@quino/core';
import {
    IQuinoComponentProps,
    ObjectBookmark,
    QuinoLabeled,
    QuinoLabeledLabelPosition,
    useMetadata,
    useOnBookmarkResetEvent,
    useOnBookmarkSavedEvent,
    useRerender,
    useValidation,
} from '@quino/ui';
import { useService } from '../../../ioc/hook/useService';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { IPerformaStorage } from '../../../storage/IPerformaStorage';
import { hashCode } from '../../../util/HashCode';
import { IPerformaFileHandler } from '../../../storage/IPerformaFileHandler';
import {
    IUploadSettingsAspect,
    UploadSettingsAspectIdentifier,
} from '../../../meta/aspects/IUploadSettingsAspect';
import { PerformaFileManager } from './PerformaFileManager';
import { PerformaFileUploader } from './PerformaFileUploader';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { IFileMetadata } from '../../../api/DTOs/IFileMetadata';
import { GlobalErrorContext } from '../../Error/GlobalErrorContext';
import { IUploadErrorRegistration } from './IUploadErrorRegistration';

type TStyles = {
    upload: string;
};

const styles: TStyles = require('./Upload.less');

export interface IFilesSaveValue {
    numberOfFiles: number;
    hash: number;
    uploadInProgress?: boolean;
}

export const Upload: React.FC<IQuinoComponentProps<ObjectBookmark>> = (props) => {
    const { element, bookmark, actions } = props;
    const metaProperty = getMetaProperty(element);
    const { description, caption, name } = metaProperty;

    const performaStorage = useService<IPerformaStorage>(
        SHARED_SERVICE_IDENTIFIER.IPERFORMASTORAGE
    );
    const performaFileHandler = useService<IPerformaFileHandler>(
        SHARED_SERVICE_IDENTIFIER.IPERFORMAFILEHANDLER
    );
    const uploadErrorRegistration = useService<IUploadErrorRegistration>(
        SHARED_SERVICE_IDENTIFIER.IUPLOADERRORREGISTRATION
    );
    const translationService = useService<II18n>(QuinoCoreServiceSymbols.II18N);

    const context = bookmark.genericObject;
    const { readOnly, required } = useMetadata(metaProperty, bookmark.genericObject);

    const uploadSettingsAspect = useMemo(
        () => {
            return getAspectOrDefault<IUploadSettingsAspect>(
                element,
                UploadSettingsAspectIdentifier
            );
        },
        [metaProperty]
    );

    const [, setGlobalError] = useContext(GlobalErrorContext);
    const [files, setFiles] = useState<IFileMetadata[] | undefined>(undefined);
    const [isValid, errorMessages] = useValidation(bookmark, name);
    const [maxFileSizeInBytes, setMaxFileSizeInBytes] = useState<number | undefined>();
    const [acceptedMimeTypes, setAcceptedMimeTypes] = useState<string>();
    const [uploadsInProgress, setUploadsInProgress] = useState<number>(0);
    const [initialFilesSaveValue, setInitialFilesSaveValue] = useState<string | null | undefined>();

    const rerender = useRerender();

    const fileUploadContext = (uploadSettingsAspect && uploadSettingsAspect.context) || undefined;

    useEffect(() => {
        (async () => {
            // since firefox can not handle multiple mime types we have to supply extensions
            const mimeTypesCSV = await performaStorage.getAcceptedMimeTypesAsync();
            let mimeTypesArray = mimeTypesCSV.split(';');
            mimeTypesArray = mimeTypesArray.map((mimeType) => mimeType.replace('/', '.'));
            mimeTypesArray = mimeTypesArray.map((mimeType) =>
                mimeType.substring(mimeType.indexOf('.'))
            );
            const extensionCSV = mimeTypesArray.join(', ');

            setAcceptedMimeTypes(extensionCSV);

            const maxFileSizeInBytes = await performaStorage.getMaxFileSizeInBytesAsync();
            setMaxFileSizeInBytes(maxFileSizeInBytes);
        })();
    }, []);

    useOnBookmarkSavedEvent(bookmark, () => {
        if (!bookmark.genericObject.primaryKey) {
            return;
        }

        const promise = performaFileHandler.commitAllFiles(
            bookmark.genericObject.metaClass,
            bookmark.genericObject.primaryKey,
            fileUploadContext
        );

        uploadErrorRegistration.addCommitPromise(promise);

        promise.then((result) => {
            setInitialFilesSaveValue(undefined);
            setFiles(result.fileMetaData);
            if (result.errors.length > 0) {
                setGlobalError({
                    title: translationService.t('literal.CustomLiterals.Upload.UploadError'),
                    error: result.errors[0],
                    logout: false,
                });
            }
        });
    });

    useOnBookmarkResetEvent(bookmark, () => {
        setFiles(performaFileHandler.reset());
        setInitialFilesSaveValue(undefined);
    });

    const getFilesSaveValue = (fileMetadata: IFileMetadata[]): IFilesSaveValue | null => {
        const fileIds = fileMetadata.map((metadata) => metadata.id);
        fileIds.sort();

        const numberOfFiles = fileIds.length;
        if (numberOfFiles < 1) {
            return null;
        }

        const hash = hashCode(fileIds);

        if (uploadsInProgress > 0) {
            return { numberOfFiles, hash, uploadInProgress: uploadsInProgress > 0 };
        }
        return { numberOfFiles, hash };
    };

    useEffect(
        () => {
            if (files != null && initialFilesSaveValue === undefined) {
                //PERFWP-916: set own initial value and do not depend on bookmark's one if files are changed on CRM
                const fileSaveValue = getFilesSaveValue(files || []);
                const value = fileSaveValue && JSON.stringify(fileSaveValue);
                setInitialFilesSaveValue(value);
            }
        },
        [files, initialFilesSaveValue]
    );

    const reValidate = () => {
        if (bookmark.fieldErrors.get(name) != null && uploadsInProgress === 0) {
            actions.validate(bookmark).then(rerender);
        }
    };

    useEffect(
        () => {
            if (files != null) {
                const fileSaveValue = getFilesSaveValue(files || []);
                const value = fileSaveValue && JSON.stringify(fileSaveValue);
                const initialValue =
                    initialFilesSaveValue === undefined ? value : initialFilesSaveValue;
                const originalValue = bookmark.originalObject[name];

                const hasInitialValueChanges = initialValue !== value;
                const hasCRMValueChange = originalValue !== initialFilesSaveValue;

                if (!hasInitialValueChanges && hasCRMValueChange) {
                    // PERFWP-978 Files were changed in the CRM, we should update the bookmark's value but not show the changed state
                    // TODO: ignore property in bookmark's hasChangeCheck
                }

                bookmark.updateFieldValue(name, value);
                reValidate();
            }
        },
        [files, uploadsInProgress]
    );

    useEffect(
        () => {
            (async () => {
                if (fileUploadContext) {
                    const fileHandlerState =
                        context.primaryKey &&
                        (await performaFileHandler.init(
                            bookmark.genericObject.metaClass,
                            context.primaryKey,
                            fileUploadContext
                        ));

                    fileHandlerState && setFiles(fileHandlerState);
                }
            })();
        },
        [fileUploadContext]
    );

    return (
        <div className={styles.upload}>
            <QuinoLabeled
                label={caption}
                required={required}
                hint={description}
                labelPosition={QuinoLabeledLabelPosition.Top}
                showBorder={true}
                valueTextAlign={files ? 'left' : 'center'}
                errorMessages={errorMessages}
            >
                {(!files && <LoadIndicator height={50} width={50} />) || (
                    <>
                        <PerformaFileManager
                            readonly={readOnly}
                            files={files}
                            isValid={isValid}
                            onDeleteFile={(id) => setFiles(performaFileHandler.deleteFile(id))}
                        />
                        <PerformaFileUploader
                            readonly={readOnly}
                            fileContext={fileUploadContext}
                            acceptedMimeTypes={acceptedMimeTypes}
                            maxFileSizeInBytes={maxFileSizeInBytes}
                            onUploadedFile={async (id) =>
                                setFiles(await performaFileHandler.addFile(id))
                            }
                            onUploadStateChanged={(uploadsInProgress) => {
                                setUploadsInProgress(() => uploadsInProgress);
                            }}
                        />
                    </>
                )}
            </QuinoLabeled>
        </div>
    );
};
