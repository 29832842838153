import React from 'react';
import { ISiteMapItem } from '../../../../navigation/ISiteMapItem';
import { ISessionService } from '../../../../session/ISessionService';
import { SHARED_SERVICE_IDENTIFIER } from '../../../../ioc/sharedIdentifiers';
import { Icon } from '../../../Util/Icon';
import { MenuItem } from './MenuItem';
import { useI18n } from '../../../../lang/useI18n';
import { useService } from '../../../../ioc/hook/useService';

interface IProps {
    siteMapItem: ISiteMapItem;
    isWideMode: boolean;
    onClick: (item: ISiteMapItem) => void;
}

export const SitemapMenuItem: React.FC<IProps> = (props) => {
    const sessionService = useService<ISessionService>(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE);
    const i18n = useI18n();
    const { siteMapItem, isWideMode } = props;

    const renderIcon = siteMapItem.icon && (() => <Icon name={siteMapItem.icon!} />);

    const activeCrmContext = sessionService.getSessionState().activeCrmContext;
    const translationKey =
        !siteMapItem.translationKey || typeof siteMapItem.translationKey === 'string'
            ? siteMapItem.translationKey
            : siteMapItem.translationKey(activeCrmContext);

    return (
        <MenuItem
            isWideMode={isWideMode}
            icon={renderIcon}
            caption={i18n.t(translationKey as any)}
            onClick={() => props.onClick(siteMapItem)}
        />
    );
};
