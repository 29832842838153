import React from 'react';

type TStyles = {
    root: string;
};

const styles: TStyles = require('./MenuSubheader.less');

interface IProps {
    caption: string;
    isWideMode: boolean;
}

export class MenuSubheader extends React.Component<IProps> {
    render() {
        const { caption, isWideMode } = this.props;

        return isWideMode ? <div className={styles.root}>{caption}</div> : <></>;
    }
}
