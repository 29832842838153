import React, { useContext } from 'react';
import { ICrmContext } from '../..';
import { CrmContext } from '../../contexts/crmContext/CrmContext';
import { List } from 'devextreme-react/list';
import { CrmContextListItem } from './CrmContextListItem';

interface IProps {
    crmContexts?: ICrmContext[];
}

export const CrmContextList: React.FunctionComponent<IProps> = (props: IProps) => {
    const crmContext = useContext(CrmContext);

    const { crmContexts } = props;

    if (!crmContexts || crmContexts.length === 0) {
        return <></>;
    }

    return (
        <List
            dataSource={crmContexts}
            itemRender={(context: ICrmContext) => (
                <CrmContextListItem
                    crmContext={context}
                    onClick={crmContext.trySetActiveCrmContextAsync}
                />
            )}
        />
    );
};
