import React from 'react';
import { ProfileForm } from '../../Profile/ProfileForm';
import { Link, Redirect } from 'react-router-dom';
import { lazyInject, QuinoCoreServiceSymbols } from '@quino/core';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { IRouteHelper } from '../../../navigation/IRouteHelper';
import { Translation } from '@quino/ui';
import { tKey } from '../../../lang/TranslationKeys';
import { IProfileFormState } from '../../Profile/IProfileFormState';
import { IProfileService } from '../../../api/IProfileService';
import { Button } from 'devextreme-react/button';
import { withConfigurationContext } from '../../../contexts/configurationContext/withConfigurationContext';
import { IWithConfigurationContext } from '../../../contexts/configurationContext/IWithConfigurationContext';
import { IPerformaI18n } from '../../../lang/IPerformaI18n';
import { ScrollablePopup } from '../../Util/Devextreme/ScrollablePopup';
import { PopupButtonBar } from '../../Util/Devextreme/PopupButtonBar';
import { ISessionService } from '../../../session/ISessionService';

type TStyles = {
    authFormRoot: string;
    authFormForm: string;
};

const styles: TStyles = require('../AuthForm.less');

interface IProps extends IWithConfigurationContext {}

interface IState {
    processCompleted: boolean;
    languageCode?: string;
}

class UserRegistrationComponent extends React.Component<IProps, IState> {
    @lazyInject(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER)
    private routeHelper: IRouteHelper;
    @lazyInject(SHARED_SERVICE_IDENTIFIER.IPROFILESERVICE)
    private profileService: IProfileService;
    @lazyInject(QuinoCoreServiceSymbols.II18N)
    private i18n: IPerformaI18n;
    @lazyInject(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE)
    private sessionService: ISessionService;

    private reload = () => {
        this.setState({ ...this.state });
    };

    constructor(props: IProps) {
        super(props);

        this.i18n.addUpdateListener(this.reload);
        this.state = {
            processCompleted: false,
        };
    }

    componentWillUnmount(): void {
        this.i18n.removeUpdateListener(this.reload);
    }

    private submitAsync = (state: IProfileFormState): Promise<void> => {
        return this.profileService.registerUserAsync({
            firstName: state.firstName,
            lastName: state.lastName,
            mailAddress: state.emailAddress,
            password: state.password,
            showFieldDescriptions: state.showFieldDescriptions,
            languageCode: state.languageCode,
        });
    };

    private onLanguageChanged = async (language: string) => {
        this.sessionService.updateSessionState({
            ...this.sessionService.getSessionState(),
            locale: language,
        });

        await this.i18n.loadTranslationsAsync();

        this.setState({ ...this.state, languageCode: language });
    };

    private onSuccess = () => {
        this.setState({ processCompleted: true });
    };

    private isRegistrationAllowed(): boolean {
        return this.props.configuration.getBooleanValueOrDefault(
            'client.Registration.AllowSelfRegistration',
            false
        );
    }

    render() {
        if (!this.isRegistrationAllowed()) {
            return <Redirect to={this.routeHelper.getLoginUrl()} />;
        }

        return (
            <>
                {this.renderForm()}
                {this.renderSuccess()}
            </>
        );
    }

    private renderSuccess() {
        return (
            <ScrollablePopup
                visible={this.state.processCompleted}
                title={this.i18n.t('literal.CustomLiterals.UserRegistration.Dialog.Title')}
                width={'auto'}
                height={'auto'}
                showCloseButton={false}
                dragEnabled={false}
                closeOnOutsideClick={false}
            >
                <Translation
                    i18nKey={tKey('literal.CustomLiterals.UserRegistration.Dialog.Text')}
                />
                <PopupButtonBar>
                    <Link to={this.routeHelper.getLoginUrl()}>
                        <Button
                            type={'default'}
                            text={this.i18n.t(
                                'literal.CustomLiterals.UserRegistration.Dialog.Confirm'
                            )}
                        />
                    </Link>
                </PopupButtonBar>
            </ScrollablePopup>
        );
    }

    private renderForm() {
        return (
            <div className={styles.authFormRoot}>
                <div>
                    <ProfileForm
                        title={this.i18n.t(tKey('literal.CustomLiterals.UserRegistration.Title'))}
                        saveButtonText={this.i18n.t(
                            tKey('literal.CustomLiterals.UserRegistration.SendButton.Text')
                        )}
                        showSaveButtonIcon={false}
                        showLeadInText={true}
                        showLeadOutText={true}
                        allowSetPassword={true}
                        showDiscardButton={false}
                        initialState={{ languageCode: this.state.languageCode }}
                        submitAsync={this.submitAsync}
                        onSuccess={this.onSuccess}
                        allowChangeEmail={true}
                        allowChangeFieldDescription={false}
                        onLanguageChanged={this.onLanguageChanged}
                    />
                    <p>
                        <Link to={this.routeHelper.getLoginUrl()}>
                            <Translation
                                i18nKey={tKey('literal.CustomLiterals.PasswordReset.BackButton')}
                            />
                        </Link>
                    </p>
                </div>
            </div>
        );
    }
}

export const UserRegistration = withConfigurationContext(UserRegistrationComponent);
