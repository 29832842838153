import React, { useContext } from 'react';
import { Button } from 'devextreme-react/button';
import { CrmContext } from '../../contexts/crmContext/CrmContext';
import { LoginContext } from '../../contexts/loginContext';
import { useI18n } from '../../lang/useI18n';

interface IStyles {
    button: string;
}

const styles: IStyles = require('./CrmContextSelectorCancelButton.less');

interface IProps {
    handleCancel: () => void;
}

export const CrmContextSelectorCancelButton: React.FunctionComponent<IProps> = (props: IProps) => {
    const { handleCancel } = props;

    const i18n = useI18n();

    const crmContext = useContext(CrmContext);
    const loginContext = useContext(LoginContext);

    const hasActiveContext = crmContext.activeCrmContext !== undefined;

    const handleSignOut = () => {
        loginContext.onTryLogoutAsync();
    };

    return (
        <>
            {(hasActiveContext && (
                <Button
                    stylingMode={'contained'}
                    className={styles.button}
                    onClick={handleCancel}
                    text={i18n.t('literal.CustomLiterals.MainLayout.CrmContextCancelButton')}
                />
            )) || (
                <Button
                    stylingMode={'contained'}
                    className={styles.button}
                    onClick={handleSignOut}
                    text={i18n.t('literal.CustomLiterals.UserMenu.SignOut')}
                />
            )}
        </>
    );
};
