import React from 'react';
import { HOC } from '../../util/types';
import { LoginContext } from './LoginContext';
import { ILoginContextState } from './ILoginContextState';

/**
 * @deprecated please use useContext()-Hooks instead
 */
export const withLoginContext: HOC<ILoginContextState> = (InnerComponent: any) => (props) => (
    <LoginContext.Consumer>
        {(login) => <InnerComponent {...login} {...props} />}
    </LoginContext.Consumer>
);
