import {
    ILanguageMetadata,
    ILanguageService,
    IRequestBuilder,
    IUrlManager,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { inject, injectable } from 'inversify';
import { ISessionService } from '../session/ISessionService';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';

@injectable()
export class PerformaLanguageService implements ILanguageService {
    private languageList: Promise<ILanguageMetadata[]>;

    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE) private sessionService: ISessionService,
        @inject(QuinoCoreServiceSymbols.IUrlManager) private urlManager: IUrlManager,
        @inject(QuinoCoreServiceSymbols.IRequestBuilder) private requestBuilder: IRequestBuilder,
        @inject(SHARED_SERVICE_IDENTIFIER.WINDOW) private windowObject: Window
    ) {}

    async getLanguageListAsync(): Promise<ILanguageMetadata[]> {
        if (this.languageList === undefined) {
            const url = this.urlManager.getLanguageListUrl();
            const result = this.requestBuilder.create(url, 'get').fetchJson<ILanguageMetadata[]>();
            this.languageList = result;
        }
        return this.languageList;
    }

    async getCurrentLanguageAsync(): Promise<string> {
        return (
            this.getSessionLanguage() ||
            (await this.getAvailableNavigatorLanguageAsync()) ||
            (await this.getFirstAvailableLanguage()) ||
            'de'
        );
    }

    async getCurrentLocaleAsync(): Promise<string> {
        const defaultInternationalisation = 'CH';
        const currentLanguage = await this.getCurrentLanguageAsync();
        return `${currentLanguage}-${defaultInternationalisation}`;
    }

    private getSessionLanguage() {
        return this.sessionService.getSessionState().locale;
    }

    private async getAvailableNavigatorLanguageAsync() {
        const availableLanguages = (await this.getLanguageListAsync()).map(
            (language) => language.name
        );

        return this.getNavigatorLanguages().find((language) =>
            availableLanguages.includes(language)
        );
    }

    private async getFirstAvailableLanguage() {
        const firstAvailableLanguage = (await this.getLanguageListAsync())[0];

        return firstAvailableLanguage && firstAvailableLanguage.name;
    }

    private getNavigatorLanguages(): string[] {
        const { languages: navigatorLanguages, language: uiLanguage } = this.windowObject.navigator;

        const stripLocale = (languageCodeWithLocale: string) =>
            languageCodeWithLocale.replace(/-.*/, '');

        const strippedNavigatorLanguages = (navigatorLanguages || []).map((languageCode) =>
            stripLocale(languageCode)
        );
        const strippedUiLanguage = uiLanguage && stripLocale(uiLanguage);

        return [...strippedNavigatorLanguages, strippedUiLanguage];
    }
}
