import React, { useState } from 'react';
import { HOC } from '../../util/types';
import { ErrorPopup, IErrorPopup } from './ErrorPopup';
import { GlobalErrorContext } from './GlobalErrorContext';

const GlobalErrorProviderComponent: React.FunctionComponent = (props: any) => {
    const [error, setError] = useState<IErrorPopup>();

    return (
        <GlobalErrorContext.Provider value={[error, setError]}>
            <>
                {props.children}
                {error && <ErrorPopup error={error} />}
            </>
        </GlobalErrorContext.Provider>
    );
};

export const withGlobalErrorContextProvider: HOC = (InnerComponent) => (props) => (
    <GlobalErrorProviderComponent>
        <InnerComponent {...props} />
    </GlobalErrorProviderComponent>
);
