import * as React from 'react';
import classNames from 'classnames';
import { VersionInfo } from '../VersionInfo';

type TStyles = {
    floatingVersionInfoRoot: string;
    floatingVersionInfoSpacer: string;
    floatingVersionInfoContent: string;
    floatingVersionInfoContentLight: string;
    floatingVersionInfoContentDark: string;
};

const styles: TStyles = require('./FloatingVersionInfo.less');

export enum FloatingVersionInfoMode {
    Light,
    Dark,
}

interface IProps {
    mode?: FloatingVersionInfoMode;
}

export const FloatingVersionInfo = ({ mode }: IProps) => {
    const modeCssClassName =
        mode === FloatingVersionInfoMode.Dark
            ? styles.floatingVersionInfoContentDark
            : styles.floatingVersionInfoContentLight;

    return (
        <div className={styles.floatingVersionInfoRoot}>
            <div className={classNames(styles.floatingVersionInfoContent, modeCssClassName)}>
                <VersionInfo />
            </div>
        </div>
    );
};
