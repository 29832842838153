// noinspection SpellCheckingInspection
export const PERFORMA_CONTROL_IDENTIFIER = {
    GRID: 'Grid',
    GRIDEDITOR: 'GridEditor',
    LOCALIZEDLABEL: 'LocalizedLabel',
    MARKDOWN: 'Markdown',
    TABCONTAINER: 'TabContainer',
    UPLOAD: 'Upload',
    PLACEHOLDER: 'Placeholder',
    NOCAPTONGROUPBOX: 'NoCaptionGroupBox',
    ZIPPLACELOOKUP: 'ZipPlaceLookup',
    FILTEREDDROPDOWN: 'FilteredDropDown',
    CUSTOMQUERYDROPDOWN: 'CustomqueryDropDown',
};
