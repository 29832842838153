import { inject, injectable } from 'inversify';
import { IPerformaI18n } from '../../lang/IPerformaI18n';
import flow from 'lodash/flow';
import { QuinoCoreServiceSymbols } from '@quino/core';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { IPasswordValidator } from '../../authentication/IPasswordValidator';
import { IProfileFormState } from './IProfileFormState';
import { IProfileFormStateHelper } from './IProfileFormStateHelper';
import { ICompanyBranchFormState } from './ICompanyBranchFormState';

@injectable()
export class ProfileFormStateHelper implements IProfileFormStateHelper {
    constructor(
        @inject(QuinoCoreServiceSymbols.II18N) private i18n: IPerformaI18n,
        @inject(SHARED_SERVICE_IDENTIFIER.IPASSWORDVALIDATOR)
        private passwordValidator: IPasswordValidator
    ) {}

    validateProfileForm = async (state: IProfileFormState): Promise<IProfileFormState> =>
        await flow([
            this.resetValidationState,
            this.validateFirstNameExists,
            this.validateLastNameExists,
            this.validateEmailAddressExists,
            this.validateEmailAddressIsValidFormat,
            this.validateLanguageCodePresent,
            this.validatePasswordPresent,
            this.validatePasswordMatch,
            this.validatePasswordComplexityAsync,
        ])(state);

    validateCompanyBranchFormAsync = async (
        state: ICompanyBranchFormState
    ): Promise<ICompanyBranchFormState> =>
        await flow([
            this.resetValidationState,
            this.validateFirstNameExists,
            this.validateLastNameExists,
            this.validateEmailAddressExists,
            this.validateEmailAddressIsValidFormat,
            this.validateCompanyExists,
            this.validateAddressExists,
            this.validateBranchExists,
            this.validateLanguageCodePresent,
        ])(state);

    private resetValidationState = (state: IProfileFormState): IProfileFormState => ({
        ...state,
        isValid: true,
        firstNameError: undefined,
        lastNameError: undefined,
        emailAddressError: undefined,
        languageCodeError: undefined,
        passwordError: undefined,
        passwordConfirmationError: undefined,
        errorMessage: undefined,
    });

    private validateFirstNameExists = (state: IProfileFormState): IProfileFormState => {
        if (state.firstName && state.firstName.length > 0) {
            return { ...state };
        }

        return {
            ...state,
            firstNameError: this.i18n.t(
                'literal.CustomLiterals.ProfileForm.Validation.MissingFirstName'
            ),
            isValid: false,
        };
    };

    private validateLastNameExists = (state: IProfileFormState): IProfileFormState => {
        if (state.lastName && state.lastName.length > 0) {
            return { ...state };
        }

        return {
            ...state,
            lastNameError: this.i18n.t(
                'literal.CustomLiterals.ProfileForm.Validation.MissingLastName'
            ),
            isValid: false,
        };
    };

    private validateEmailAddressExists = (state: IProfileFormState): IProfileFormState => {
        if (state.emailAddress && state.emailAddress.length > 0) {
            return { ...state };
        }

        return {
            ...state,
            emailAddressError: this.i18n.t(
                'literal.CustomLiterals.ProfileForm.Validation.MissingEmailAddress'
            ),

            isValid: false,
        };
    };

    private validateEmailAddressIsValidFormat = (state: IProfileFormState): IProfileFormState => {
        if (!state.emailAddress || state.emailAddressError) {
            return { ...state };
        }

        // NOTE: Taken from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript#46181
        const regexEmailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regexEmailFormat.test(state.emailAddress)) {
            return { ...state };
        }

        return {
            ...state,
            emailAddressError: this.i18n.t(
                'literal.CustomLiterals.ProfileForm.Validation.InvalidEmailAddressFormat'
            ),

            isValid: false,
        };
    };

    private validateLanguageCodePresent = (state: IProfileFormState): IProfileFormState => {
        if (state.languageCode && state.languageCode.length > 0) {
            return { ...state };
        }

        return {
            ...state,
            languageCodeError: this.i18n.t(
                'literal.CustomLiterals.ProfileForm.Validation.MissingLanguage'
            ),
            isValid: false,
        };
    };

    private validatePasswordMatch = (state: IProfileFormState): IProfileFormState => {
        if (!state.allowSetPassword || state.passwordError) {
            return { ...state };
        }

        if (state.password === state.passwordConfirmation) {
            return { ...state };
        }

        return {
            ...state,
            passwordConfirmationError: this.i18n.t(
                'literal.CustomLiterals.ChangePassword.Validation.Mismatch'
            ),

            isValid: false,
        };
    };

    private validatePasswordPresent = (state: IProfileFormState): IProfileFormState => {
        if (!state.allowSetPassword) {
            return { ...state };
        }

        if (state.password.length > 0) {
            return { ...state };
        }

        return {
            ...state,
            passwordError: this.i18n.t(
                'literal.CustomLiterals.ChangePassword.Validation.MissingRequiredFields'
            ),

            isValid: false,
        };
    };

    private validatePasswordComplexityAsync = async (
        state: IProfileFormState
    ): Promise<IProfileFormState> => {
        if (!state.allowSetPassword || state.passwordError) {
            return { ...state };
        }

        const errors = await this.passwordValidator.validateAsync(state.password);

        if (errors.length === 0) {
            return { ...state };
        }

        return {
            ...state,
            passwordError: errors.join(' '),
            isValid: false,
        };
    };

    private validateBranchExists = (state: ICompanyBranchFormState): ICompanyBranchFormState => {
        if (state.branch && state.branch.length > 0) {
            return { ...state };
        }

        return {
            ...state,
            branchError: this.i18n.t('literal.CustomLiterals.ProfileForm.Validation.MissingBranch'),
            isValid: false,
        };
    };

    private validateCompanyExists = (state: ICompanyBranchFormState): ICompanyBranchFormState => {
        if (state.company && state.company.length > 0) {
            return { ...state };
        }

        return {
            ...state,
            companyError: this.i18n.t(
                'literal.CustomLiterals.ProfileForm.Validation.MissingCompany'
            ),
            isValid: false,
        };
    };

    private validateAddressExists = (state: ICompanyBranchFormState): ICompanyBranchFormState => {
        if (state.address && state.address.length > 0) {
            return { ...state };
        }

        return {
            ...state,
            addressError: this.i18n.t(
                'literal.CustomLiterals.ProfileForm.Validation.MissingAddress'
            ),
            isValid: false,
        };
    };
}
