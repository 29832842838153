import { IPasswordValidatorConfig } from './IPasswordValidatorConfig';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IConfigurationService } from '../configuration/IConfigurationService';

@injectable()
export class PasswordValidatorConfig implements IPasswordValidatorConfig {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.ICONFIGURATIONSERVICE)
        private configurationService: IConfigurationService
    ) {}

    async getMinimumLengthAsync(): Promise<number> {
        return this.configurationService.getNumberValueOrDefaultAsync(
            'client.PasswordPolicy.MinimumLength',
            8
        );
    }

    async getMaximumLengthAsync(): Promise<number> {
        return this.configurationService.getNumberValueOrDefaultAsync(
            'client.PasswordPolicy.MaximumLength',
            100
        );
    }

    async getAllowedSpecialCharactersAsync(): Promise<string[]> {
        const valueString = await this.configurationService.getStringValueOrDefaultAsync(
            'client.PasswordPolicy.AllowedSpecialCharacters',
            ',;.:-_+*!?(){}[]&%"@$£§/<>'
        );
        return valueString.split('');
    }

    async needsUppercaseCharacterAsync(): Promise<boolean> {
        return this.configurationService.getBooleanValueOrDefaultAsync(
            'client.PasswordPolicy.NeedsUpperCaseCharacter',
            true
        );
    }

    async needsLowercaseCharacterAsync(): Promise<boolean> {
        return this.configurationService.getBooleanValueOrDefaultAsync(
            'client.PasswordPolicy.NeedsLowerCaseCharacter',
            true
        );
    }

    async needsNumberCharacterAsync(): Promise<boolean> {
        return this.configurationService.getBooleanValueOrDefaultAsync(
            'client.PasswordPolicy.NeedsNumberCharacters',
            true
        );
    }

    async needsSpecialCharacterAsync(): Promise<boolean> {
        return this.configurationService.getBooleanValueOrDefaultAsync(
            'client.PasswordPolicy.NeedsSpecialCharacters',
            false
        );
    }
}
