import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiActions } from '../../redux/api/apiStore';
import { GlobalErrorContext } from '../Error/GlobalErrorContext';

export const serverNotReachableTimeoutMS = 40000;

/**
 * If this component is not unmounted again after a short period of time,
 * it marks the server as not reachable causing the app to show the server not reachable page.
 */
export const ServerNotReachableTimeout: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const [globalError] = useContext(GlobalErrorContext);
    const [serverNotReachable, setServerNotReachable] = useState<boolean>(false);

    useEffect(
        () => {
            if (serverNotReachable && globalError == undefined) {
                dispatch(apiActions.setServerReachable(false));
            }
        },
        [serverNotReachable]
    );

    useEffect(() => {
        const timeout = window.setTimeout(
            () => setServerNotReachable(true),
            serverNotReachableTimeoutMS
        );
        return () => {
            window.clearTimeout(timeout);
        };
    }, []);

    return <React.Fragment />;
};
