import { IUploadErrorRegistration } from './IUploadErrorRegistration';
import { injectable } from 'inversify';
import { IFileMetadata } from '../../../api/DTOs/IFileMetadata';
import { PerformaServerError } from '../../../api/PerformaServerError';

@injectable()
export class UploadErrorRegistration implements IUploadErrorRegistration {
    hasUploadErrors = async () => {
        let hasError = false;
        for (const promise of this.promises) {
            const result = await promise;
            if (result.errors.length > 0) {
                hasError = true;
            }
        }
        this.promises = [];
        return hasError;
    };

    addCommitPromise = (
        promise: Promise<{ fileMetaData: IFileMetadata[]; errors: PerformaServerError[] }>
    ) => {
        this.promises.push(promise);
    };

    private promises: Promise<{
        fileMetaData: IFileMetadata[];
        errors: PerformaServerError[];
    }>[] = [];
}
