import React from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { IWithCrmContext, withCrmContext, Typography } from 'performa-shared';
import { IDocumentsState } from './IDocumentsState';
import { IDocumentsStateHelper } from './IDocumentsStateHelper';
import { lazyInject } from '@quino/core';
import { PNET_SERVICE_IDENTIFIER } from '../../ioc/pnetIdentifiers';
import { Translation } from '@quino/ui';
import { tKey } from '../../lang/TranslationKeys';

interface IProps extends IWithCrmContext {}

class DocumentsComponent extends React.Component<IProps, IDocumentsState> {
    private readonly documentsTargetName = 'PnetDocuments';

    @lazyInject(PNET_SERVICE_IDENTIFIER.IDOCUMENTSSTATEHELPER)
    private stateHelper: IDocumentsStateHelper;

    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    async componentDidMount(): Promise<any> {
        const { activeCrmContext } = this.props.crmContext;
        if (
            activeCrmContext &&
            activeCrmContext.context &&
            this.state.loadedCrmContext !== activeCrmContext
        ) {
            await this.loadAsync();
        }
    }

    static getDerivedStateFromProps(props: IProps, state: IDocumentsState): IDocumentsState | null {
        const { activeCrmContext } = props.crmContext;
        if (
            activeCrmContext &&
            activeCrmContext.context &&
            state.loadedCrmContext !== activeCrmContext
        ) {
            return {
                ...state,
                loadedCrmContext: undefined,
                sharePointUrl: undefined,
            };
        }

        return null;
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IDocumentsState>) {
        if (!this.state.loadedCrmContext && prevState.loadedCrmContext) {
            await this.loadAsync();
        }

        return null;
    }

    private async loadAsync() {
        const { activeCrmContext } = this.props.crmContext;
        if (activeCrmContext === this.state.loadedCrmContext) {
            return;
        }

        try {
            this.setState(this.stateHelper.startLoading(this.state));
            if (activeCrmContext) {
                const newState = await this.stateHelper.loadSharePointUrlAsync(
                    activeCrmContext,
                    this.state
                );
                this.setState(newState);

                if (newState.sharePointUrl) {
                    window.open(newState.sharePointUrl, this.documentsTargetName);
                }
            } else {
                this.setState({ sharePointUrl: null });
            }
        } finally {
            this.setState(this.stateHelper.stopLoading(this.state));
        }
    }

    render() {
        const { isLoading, sharePointUrl } = this.state;

        return (
            <>
                <LoadPanel visible={isLoading} />
                {!isLoading &&
                    sharePointUrl && (
                        <div>
                            <Typography>
                                <h5>
                                    <Translation
                                        i18nKey={tKey(
                                            'literal.CustomLiterals.PNet.Documents.Heading'
                                        )}
                                    />
                                </h5>
                            </Typography>
                            <Typography>
                                <Translation
                                    i18nKey={tKey('literal.CustomLiterals.PNet.Documents.Text')}
                                />
                                <a href={sharePointUrl} target={this.documentsTargetName}>
                                    <Translation
                                        i18nKey={tKey(
                                            'literal.CustomLiterals.PNet.Documents.LinkText'
                                        )}
                                    />
                                </a>
                                .
                            </Typography>
                        </div>
                    )}
                {!isLoading &&
                    sharePointUrl === null && (
                        <div>
                            <Typography>
                                <h6>
                                    <Translation
                                        i18nKey={tKey(
                                            'literal.CustomLiterals.PNet.Documents.NoDocumentStorage'
                                        )}
                                    />
                                </h6>
                            </Typography>
                        </div>
                    )}
            </>
        );
    }
}

export const Documents = withCrmContext(DocumentsComponent);
