import React from 'react';
import { TextBox } from 'devextreme-react/text-box';
import { useTextBoxFocusRef } from '../Util/Devextreme/DevextremeHelpers';
import { useI18n } from '../../lang/useI18n';

interface IStyles {
    searchIcon: string;
}

const styles: IStyles = require('./CrmContextSearch.less');

interface IProps {
    handleInputChange: (value: string) => void;
}

export const CrmContextSearch: React.FunctionComponent<IProps> = (props: IProps) => {
    const i18n = useI18n();

    const { handleInputChange } = props;

    const initialFocusRef = useTextBoxFocusRef();

    return (
        <TextBox
            ref={initialFocusRef}
            buttons={[
                {
                    name: 'search-icon',
                    location: 'before',
                    options: {
                        icon: 'search',
                        type: 'normal',
                        height: 32,
                        activeStateEnabled: false,
                        focusStateEnabled: false,
                        hoverStateEnabled: false,
                        elementAttr: { class: styles.searchIcon },
                    },
                },
            ]}
            placeholder={i18n.t('literal.CustomLiterals.MainLayout.CrmContextSearch')}
            onInput={({ event }: any) => handleInputChange(event.target.value)}
            width={'100%'}
        />
    );
};
