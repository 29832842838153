import { IPerformaSessionState } from './IPerformaSessionState';
import { TActionType } from '../redux/types';
import { TRootReducerState } from '../redux/store';
import { SessionPersister } from './SessionPersister';

// State

export const initialSessionState: IPerformaSessionState = new SessionPersister(window).getSession();

// Actions

export const sessionActions = {
    updateSessionState: (state: Partial<IPerformaSessionState>) =>
        ({
            type: 'session/UPDATE_SESSION_STATE',
            state,
        } as const),
};

type TAction = TActionType<typeof sessionActions>;

// Reducer

export const sessionStateReducer = (
    state: IPerformaSessionState,
    action: TAction
): IPerformaSessionState => {
    switch (action.type) {
        case 'session/UPDATE_SESSION_STATE':
            return { ...state, ...action.state };
        default:
            return { ...state };
    }
};

// Selectors

export const getSessionState = (state: TRootReducerState) => state.session;

export const getCurrentLocale = (state: TRootReducerState) => getSessionState(state).locale;
