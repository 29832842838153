import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getSiteMapItem, ISiteMap, ISiteMapItem } from '../../navigation';
import { SiteMapWrapper } from '../SiteMapContext';
import { IWithCrmContext, withCrmContext } from '../../contexts/crmContext';
import { DataBrowser } from '../DataBrowser/DataBrowser';
import { withDataBrowserContextProvider } from '../DataBrowser/DataBrowserContext';

interface IRouterProps {
    siteMapPath?: string;
}

interface IProps extends RouteComponentProps<IRouterProps>, ISiteMapItem, IWithCrmContext {
    siteMap: ISiteMap;
    detailLayout?: string;
}

interface IState {
    currentSiteMapItem?: ISiteMapItem;
    locationPath?: string;
}

class MainContentComponent extends React.Component<IProps, IState> {
    state: IState = {};

    static getDerivedStateFromProps(props: IProps, state: IState): IState | null {
        if (!state.currentSiteMapItem || props.location.pathname !== state.locationPath) {
            return {
                ...state,
                currentSiteMapItem: getSiteMapItem(props.siteMap, props.match.params.siteMapPath),
                locationPath: props.location.pathname,
            };
        }

        return null;
    }

    render() {
        const { currentSiteMapItem } = this.state;

        return (
            <SiteMapWrapper
                currentSiteMapItem={currentSiteMapItem}
                siteMap={this.props.siteMap}
                key={
                    (currentSiteMapItem && currentSiteMapItem.path) ||
                    this.props.match.params.siteMapPath
                }
            >
                {/*
                  When the user navigates between two very similar pages, React might be tempted to reuse the existing
                  DOM tree for the new content. This leads to parts of the page not being updated. We set a key here to
                  tell React if the content has changed entirely and therefore needs to be re-rendered from scratch.
                  Technical background: https://overreacted.io/react-as-a-ui-runtime/#reconciliation
                */}
                <React.Fragment
                    key={
                        (currentSiteMapItem && currentSiteMapItem.path) ||
                        this.props.match.params.siteMapPath
                    }
                >
                    {(currentSiteMapItem &&
                        currentSiteMapItem.main &&
                        currentSiteMapItem.main()) || (
                        <DataBrowser entityName={this.props.match.params.siteMapPath || ''} />
                    )}
                </React.Fragment>
            </SiteMapWrapper>
        );
    }
}

export const MainContent = withCrmContext(
    withDataBrowserContextProvider(withRouter(MainContentComponent))
);
