import { IChangePasswordState } from './IChangePasswordState';
import { IPerformaI18n } from '../../../../lang/IPerformaI18n';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '@quino/core';
import { IChangePasswordStateHelper } from './IChangePasswordStateHelper';
import { IProfileService } from '../../../../api/IProfileService';
import { SHARED_SERVICE_IDENTIFIER } from '../../../../ioc/sharedIdentifiers';
import { IPasswordValidator } from '../../../../authentication/IPasswordValidator';
import flow from 'lodash/flow';

@injectable()
export class ChangePasswordStateHelper implements IChangePasswordStateHelper {
    constructor(
        @inject(QuinoCoreServiceSymbols.II18N) private i18n: IPerformaI18n,
        @inject(SHARED_SERVICE_IDENTIFIER.IPROFILESERVICE) private profileService: IProfileService,
        @inject(SHARED_SERVICE_IDENTIFIER.IPASSWORDVALIDATOR)
        private passwordValidator: IPasswordValidator
    ) {}

    validateAsync = (state: IChangePasswordState): Promise<IChangePasswordState> =>
        flow([
            this.resetValidationState,
            this.validatePasswordMatch,
            this.validateOldPasswordPresent,
            this.validateNewPasswordComplexityAsync,
        ])(state);

    async submitAsync(state: IChangePasswordState): Promise<IChangePasswordState> {
        if (!state.isValid) {
            return { ...state, success: false };
        }

        try {
            await this.profileService.changePasswordAsync({
                oldPassword: state.oldPassword,
                newPassword: state.newPassword,
            });
        } catch (e) {
            return {
                ...state,
                success: false,
                generalError: this.i18n.t(
                    'literal.CustomLiterals.ChangePassword.Validation.ServerError'
                ),
            };
        }

        return { ...state, success: true };
    }

    private resetValidationState = (state: IChangePasswordState): IChangePasswordState => ({
        ...state,
        isValid: true,
        oldPasswordError: undefined,
        newPasswordError: undefined,
        confirmNewPasswordError: undefined,
        generalError: undefined,
    });

    private validatePasswordMatch = (state: IChangePasswordState): IChangePasswordState => {
        if (state.newPassword === state.confirmNewPassword) {
            return { ...state };
        }

        return {
            ...state,
            confirmNewPasswordError: this.i18n.t(
                'literal.CustomLiterals.ChangePassword.Validation.Mismatch'
            ),
            isValid: false,
        };
    };

    private validateOldPasswordPresent = (state: IChangePasswordState): IChangePasswordState => {
        if (state.oldPassword.length > 0) {
            return { ...state };
        }

        return {
            ...state,
            oldPasswordError: this.i18n.t(
                'literal.CustomLiterals.ChangePassword.Validation.MissingRequiredFields'
            ),
            isValid: false,
        };
    };

    private validateNewPasswordComplexityAsync = async (
        state: IChangePasswordState
    ): Promise<IChangePasswordState> => {
        const errors = await this.passwordValidator.validateAsync(state.newPassword);

        if (errors.length === 0) {
            return { ...state };
        }

        return {
            ...state,
            newPasswordError: errors.join(' '),
            isValid: false,
        };
    };
}
