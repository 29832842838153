import React from 'react';
import { ISiteMapState, SiteMapContext } from './SiteMapContext';
import { ISiteMap, ISiteMapItem } from '../../navigation';

interface IProps {
    currentSiteMapItem?: ISiteMapItem;
    siteMap: ISiteMap;
}

interface IState extends ISiteMapState {}

export class SiteMapWrapper extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            currentSiteMapItem: props.currentSiteMapItem,
            siteMap: props.siteMap,
            onSetCurrentSiteMapItem: this.handleSetCurrentSetMapItem,
            siteMapContainsCurrentEntity: (entityName): boolean => {
                return (
                    props.siteMap.nodes.filter(
                        (node) =>
                            node.items.filter((siteMapItem) => siteMapItem.path === entityName)
                                .length > 0
                    ).length > 0
                );
            },
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        this.handleSetCurrentSetMapItem(nextProps.currentSiteMapItem);
    }

    private handleSetCurrentSetMapItem(siteMapItem?: ISiteMapItem) {
        this.setState({ currentSiteMapItem: siteMapItem });
    }

    render() {
        return (
            <SiteMapContext.Provider value={this.state}>
                {this.props.children}
            </SiteMapContext.Provider>
        );
    }
}
