import React from 'react';
import { IQuinoComponentProps, ObjectBookmark, QuinoUIServiceSymbols } from '@quino/ui';
import { useService } from '../../ioc/hook/useService';
import { IComponentFactory } from '@quino/ui/dist/components/ComponentFactory/IComponentFactory';
import { IMetaElement } from '@quino/core';

export const Placeholder: React.FunctionComponent<IQuinoComponentProps<ObjectBookmark>> = (
    props
) => {
    const factory = useService<IComponentFactory>(QuinoUIServiceSymbols.IComponentFactory);

    return (
        <>
            {(props.element as any).elements.map((child: IMetaElement) =>
                factory.create(child, props.bookmark, props.actions)
            )}
        </>
    );
};
