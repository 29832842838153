import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { IForgotPasswordState as IState } from './IForgotPasswordState';
import { IForgotPasswordStateHelper } from './IForgotPasswordStateHelper';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { IRouteHelper } from '../../../navigation';
import { tKey } from '../../../lang/TranslationKeys';
import { Translation } from '@quino/ui';
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react/text-box';
import { ErrorMessage } from '../../Util/ErrorMessage';
import { useService } from '../../../ioc/hook/useService';
import { Typography } from '../../Util/Devextreme/Typography';
import { useTextBoxFocusRef } from '../../Util/Devextreme/DevextremeHelpers';
import { useI18n } from '../../../lang/useI18n';
import { PopupButtonBar } from '../../Util/Devextreme/PopupButtonBar';

type TStyles = {
    authFormRoot: string;
    authFormForm: string;
    authFormButtonBar: string;
    authFormTextFields: string;
};

const styles: TStyles = require('../AuthForm.less');

export const ForgotPassword: React.FC = () => {
    const stateHelper = useService<IForgotPasswordStateHelper>(
        SHARED_SERVICE_IDENTIFIER.IFORGOTPASSWORDSTATEHELPER
    );
    const routeHelper = useService<IRouteHelper>(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER);
    const i18n = useI18n();

    const match = useRouteMatch();
    const history = useHistory();
    const initialFocusRef = useTextBoxFocusRef();

    const [state, setState] = useState<IState>({
        username: match.params['username'] || '',
        processCompleted: false,
    });

    const [inProgress, setInProgress] = useState(false);

    const updateUsername = (username: string) => {
        setState((oldState: IState) => stateHelper.updateUsername(oldState, username));
    };

    const submit = async (event: any) => {
        event.preventDefault();
        try {
            setInProgress(true);
            setState(await stateHelper.submitAsync(state));
        } finally {
            setInProgress(false);
        }
    };

    const goToLogin = () => {
        history.push(routeHelper.getLoginUrl());
    };

    const { username, processCompleted, errorMessage } = state;

    return (
        <div className={styles.authFormRoot}>
            <LoadPanel visible={inProgress} />
            <form autoComplete="off" className={styles.authFormForm} onSubmit={submit}>
                <Typography>
                    <h5>
                        <Translation
                            i18nKey={tKey('literal.CustomLiterals.ForgotPassword.Title')}
                        />
                    </h5>
                </Typography>

                <TextBox
                    ref={initialFocusRef}
                    id="userId"
                    className={styles.authFormTextFields}
                    value={username}
                    width={'100%'}
                    stylingMode={'underlined'}
                    placeholder={i18n.t(
                        'literal.CustomLiterals.ForgotPassword.UserNamePlaceholderText',
                        {}
                    )}
                    onInput={(data) => updateUsername((data.event as any).target.value || '')}
                />

                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

                <div className={styles.authFormButtonBar}>
                    <Button
                        useSubmitBehavior={true}
                        stylingMode="contained"
                        type={'default'}
                        disabled={state.username === '' || inProgress}
                        text={i18n.t(
                            tKey('literal.CustomLiterals.ForgotPassword.ResetPasswordButton')
                        )}
                        icon={'message'}
                    />
                </div>
                <p>
                    <a href={'#'} onClick={goToLogin}>
                        <Translation
                            i18nKey={tKey('literal.CustomLiterals.ForgotPassword.BackButton')}
                        />
                    </a>
                </p>
            </form>
            <Popup
                visible={processCompleted}
                showCloseButton={false}
                aria-labelledby="context-dialog-title"
                title={i18n.t('literal.CustomLiterals.ForgotPassword.EmailSentDialog.Title')}
                width={'auto'}
                height={'auto'}
                dragEnabled={false}
            >
                <div id={'context-dialog-title'}>
                    <Translation
                        i18nKey={tKey('literal.CustomLiterals.ForgotPassword.EmailSentDialog.Text')}
                    />
                </div>
                <PopupButtonBar>
                    <Button type={'default'} onClick={goToLogin}>
                        <Translation
                            i18nKey={tKey(
                                'literal.CustomLiterals.ForgotPassword.EmailSentDialog.BackButton'
                            )}
                        >
                            Zum Login
                        </Translation>
                    </Button>
                </PopupButtonBar>
            </Popup>
        </div>
    );
};
