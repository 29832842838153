import { Container } from 'inversify';
import { sharedModule } from './sharedModule';
import { ISettings } from '../ISettings';
import {
    ContainerContext,
    DevExpressModule,
    IHelpTextVisibilityStrategy,
    IQuinoDataGridConfigurationService,
    IQuinoDataGridFilterService,
    IQuinoLabelConfigurationService,
    IQuinoMetaPanelActions,
    QuinoUIServiceSymbols,
    UIModule,
} from '@quino/ui';
import { ProfileHelpTextVisibilityStrategy } from '../configuration/ProfileHelpTextVisibilityStrategy';
import {
    ApiModule,
    AuthenticationModule,
    AuthenticationStartupActionIdentifier,
    AuthorizationModule,
    ExpressionModule,
    FrameworkStartupGroup,
    IApplication,
    IAuthenticationFeedback,
    IExpressionEvaluator,
    IFieldValidatorProvider,
    II18n,
    ILanguageService,
    IQuinoServerServiceConfiguration,
    IReadOnlyCalculator,
    IRequestDecoratorProvider,
    IValidationMessageProvider,
    MetadataModule,
    MetadataStartupActionIdentifier,
    QuinoApplication,
    QuinoCoreServiceSymbols,
    RequestModule,
    ValidationModule,
} from '@quino/core';
import React from 'react';
import { IbanValidator } from '../data/validation/IbanValidator';
import { SocialSecurityNumberValidator } from '../data/validation/SocialSecurityNumberValidator';
import { DateInFutureValidator } from '../data/validation/DateInFutureValidator';
import { FileUploadValidator } from '../data/validation/FileUploadValidator';
import { PerformaControlModule } from './PerformaControlModule';
import { PerformaContextHeaderDecorator } from '../request/PerformaContextHeaderDecorator';
import { SHARED_SERVICE_IDENTIFIER } from './sharedIdentifiers';
import { ISessionService } from '../session/ISessionService';
import { PerformaMetaPanelActions } from '../Components/DataBrowser';
import { PerformaAuthorizationService } from '../authorization/PerformaAuthorizationService';
import { IPerformaAuthorizationService } from '../authorization/IPerformaAuthorisationService';
import { LanguageRequestDecorator } from '../lang/LanguageRequestDecorator';
import { tryRegisterAppInsights } from '../ApplicationInsights/ApplicationInsights';
import { IResponseHandler } from '@quino/core/dist/request/IResponseHandler';
import { FailedRequestHandler } from '../Components/ServerNotReachable/FailedRequestHandler';
import { PerformaLanguageService } from '../lang/PerformaLanguageService';
import { PerformaAuthenticationFeedback } from '../Components/Auth/PerformaAuthenticationFeedback/PerformaAuthenticationFeedback';
import { PerformaDataGridConfigurationService } from '../Components/Grid/PerformaDataGridConfigurationService';
import { PerformaLabelConfigurationService } from '../settings/PerformaLabelConfigurationService';
import { PerformaRequiredRuleValidator } from '../data/validation/PerformaRequiredRuleValidator';

import { loadMessages, locale } from 'devextreme/localization';
import { BooleanIsTrueValidator } from '../data/validation/BooleanIsTrueValidator';
import { BirthdateValidator } from '../data/validation/BirthdateValidator';
import { PerformaReadOnlyCalculator } from '../authorization/PerformaReadonlyCalculator';
import { IUploadErrorRegistration } from '../Components/Form/Upload/IUploadErrorRegistration';
import { UploadErrorRegistration } from '../Components/Form/Upload/UploadErrorRegistration';
import { PerformaDataGridFilterService } from '../DataGrid/PerformaDataGridFilterService';

const deMessages = require('devextreme/localization/messages/de.json');
const frMessages = require('devextreme/localization/messages/fr.json');
const itMessages = require('devextreme/localization/messages/it.json');

let application: IApplication;

export const setupSharedContainer = async (
    settings: ISettings,
    frontendVersion: string,
    setupTenantContainer?: (application: IApplication) => void
): Promise<IApplication> => {
    if (application) {
        // Container is already configured
        return application;
    }

    application = new QuinoApplication();
    ApiModule.use(application);
    MetadataModule.use(application);
    ValidationModule.use(application);
    RequestModule.use(application);
    AuthenticationModule.use(application);
    AuthorizationModule.use(application);
    UIModule.use(application);
    DevExpressModule.use(application);
    PerformaControlModule.use(application);
    ExpressionModule.use(application);

    application.unregisterStartupAction(AuthenticationStartupActionIdentifier);
    application.unregisterStartupAction(MetadataStartupActionIdentifier);

    application.registerStartupAction(Symbol.for('performa_validation_startup_action'), (app) => {
        const validatorProvider = app.get<IFieldValidatorProvider>(
            QuinoCoreServiceSymbols.IFieldValidatorProvider
        );
        const validationMessageProvider = app.get<IValidationMessageProvider>(
            QuinoCoreServiceSymbols.IValidationMessageProvider
        );
        const i18n = app.get<II18n>(QuinoCoreServiceSymbols.II18N);
        const expressionEvaluator = app.get<IExpressionEvaluator>(
            QuinoCoreServiceSymbols.IExpressionEvaluator
        );

        validatorProvider.register(
            new SocialSecurityNumberValidator(i18n),
            Symbol.for('SocialSecurityNumberValidator')
        );
        validatorProvider.register(new IbanValidator(i18n), Symbol.for('IbanValidator'));
        validatorProvider.register(
            new DateInFutureValidator(i18n),
            Symbol.for('DateInFutureValidator')
        );
        validatorProvider.register(
            new FileUploadValidator(i18n, expressionEvaluator),
            Symbol.for('FileUploadValidator')
        );
        validatorProvider.register(
            new BooleanIsTrueValidator(),
            Symbol.for('BooleanIsTrueValidator')
        );
        validatorProvider.register(new BirthdateValidator(i18n), Symbol.for('BirthdateValidator'));

        // Overwrite Quino RequiredRuleValidator
        validatorProvider.register(
            new PerformaRequiredRuleValidator(validationMessageProvider, expressionEvaluator),
            Symbol.for('RequiredRuleValidator')
        );
    });

    application.registerStartupAction(Symbol.for('performa_decorator_startup_action'), (app) => {
        const sessionService = app.get<ISessionService>(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE);
        const decorator = app.get<IRequestDecoratorProvider>(
            QuinoCoreServiceSymbols.IRequestDecoratorProvider
        );
        decorator.register(
            new PerformaContextHeaderDecorator(sessionService),
            Symbol.for('PerformaContextHeaderDecorator')
        );
        decorator.register(
            new LanguageRequestDecorator(sessionService),
            Symbol.for('LanguageRequestDecorator')
        );
    });

    application
        .registerStartupAction(
            Symbol.for('performa_devexpress_localization_startup_action'),
            async (app) => {
                const languageService = app.get<ILanguageService>(
                    QuinoCoreServiceSymbols.ILanguageService
                );

                loadMessages(deMessages);
                loadMessages(frMessages);
                loadMessages(itMessages);

                // Overwrite missing translations
                loadMessages({
                    fr: {
                        'dxFileManager-dialogDeleteItemTitle': 'Supprimer',
                        'dxFileManager-dialogDeleteItemButtonText': 'Supprimer',
                        'dxFileManager-dialogDeleteItemSingleItemConfirmation':
                            'Êtes-vous sûr de vouloir supprimer {0}?',
                        'dxFileManager-dialogButtonCancel': 'Annuler',
                    },
                    it: {
                        'dxFileManager-commandDelete': 'Cancellare',
                        'dxFileManager-commandDownload': 'Scaricare',
                        'dxFileManager-dialogDeleteItemTitle': 'Cancellare',
                        'dxFileManager-dialogDeleteItemButtonText': 'Cancellare',
                        'dxFileManager-dialogDeleteItemSingleItemConfirmation':
                            'È sicuro di voler cancellare {0}?',
                        'dxFileManager-dialogButtonCancel': 'Annulla',
                    },
                });

                const currentLocale = await languageService.getCurrentLocaleAsync();
                locale(currentLocale);
            }
        )
        .moveTo(FrameworkStartupGroup);

    application.registerStartupAction(Symbol.for('app_insight_performa_startup'), () =>
        tryRegisterAppInsights()
    );

    application.registerSingle<IQuinoMetaPanelActions>(
        QuinoUIServiceSymbols.IQuinoMetaPanelActions,
        PerformaMetaPanelActions
    );

    application.registerSingle<IPerformaAuthorizationService>(
        QuinoCoreServiceSymbols.IAuthorizationService,
        PerformaAuthorizationService
    );

    application.registerSingle<IQuinoDataGridConfigurationService>(
        QuinoUIServiceSymbols.IQuinoDataGridConfigurationService,
        PerformaDataGridConfigurationService
    );

    application.registerConstant<IQuinoServerServiceConfiguration>(
        QuinoCoreServiceSymbols.IQuinoServerServiceConfiguration,
        {
            baseUrl: settings.backendServerUrl,
        }
    );

    application.register<IResponseHandler>(
        QuinoCoreServiceSymbols.IResponseHandler,
        FailedRequestHandler
    );

    application.registerSingle<ILanguageService>(
        QuinoCoreServiceSymbols.ILanguageService,
        PerformaLanguageService
    );

    application.registerSingle<IAuthenticationFeedback>(
        QuinoCoreServiceSymbols.IAuthenticationFeedback,
        PerformaAuthenticationFeedback
    );

    application.registerSingle<IQuinoLabelConfigurationService>(
        QuinoUIServiceSymbols.IQuinoLabelConfigurationService,
        PerformaLabelConfigurationService
    );

    application.registerSingle<IReadOnlyCalculator>(
        QuinoCoreServiceSymbols.IReadOnlyCalculator,
        PerformaReadOnlyCalculator
    );

    application.registerSingle<IUploadErrorRegistration>(
        SHARED_SERVICE_IDENTIFIER.IUPLOADERRORREGISTRATION,
        UploadErrorRegistration
    );

    application.registerSingle<IQuinoDataGridFilterService>(
        QuinoUIServiceSymbols.IQuinoDataGridFilterService,
        PerformaDataGridFilterService
    );

    application.load(sharedModule(settings, frontendVersion));

    setupTenantContainer && setupTenantContainer(application);

    await application.build();

    return application;
};

/**
 * Retrieve a container with all shared services registered
 * This function is intended to be used in integration tests only.
 */
export function setupSharedMockContainer(container: Container) {
    // NOTE: The following binding is so in testing the "rebind" later on works because unit-tests
    //       don't get the quino registrations before and therefore the "rebind" will fail.
    container
        .bind<IHelpTextVisibilityStrategy>(QuinoUIServiceSymbols.IHelpTextVisibilityStrategy)
        .to(ProfileHelpTextVisibilityStrategy)
        .inRequestScope();

    container.load(sharedModule({} as ISettings, ''));
}

export class ContainerContextProvider extends React.Component {
    render() {
        if (!application) {
            throw new Error('IOC container is not set up. Call setupSharedContainer first.');
        }

        return (
            <ContainerContext.Provider value={{ container: application }}>
                {this.props.children}
            </ContainerContext.Provider>
        );
    }
}
