import { TTranslationKey } from './TranslationKeys';
import { useI18n } from './useI18n';

export const useTranslation = (
    key: TTranslationKey,
    params?: { [key: string]: any },
    fallback?: string
): string => {
    const i18n = useI18n();
    return i18n.t(key, params, fallback);
};
