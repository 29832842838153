import { IPerformaSessionState } from './IPerformaSessionState';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { ISessionPersister } from './ISessionPersister';

@injectable()
export class SessionPersister implements ISessionPersister {
    private static readonly STORAGE_ENTRY_NAME = 'performa_shared_session';
    private static readonly SESSION_ENTRY_ID = 'performa_local_id';

    constructor(@inject(SHARED_SERVICE_IDENTIFIER.WINDOW) private window: Window) {}

    getSession() {
        const localStorageSerialized = this.window.localStorage.getItem(
            SessionPersister.STORAGE_ENTRY_NAME
        );
        const sessionStorageSerialized = this.window.sessionStorage.getItem(this.getUniqueId());

        const localStorage = SessionPersister.unmarshal(localStorageSerialized);
        const sessionStorage = SessionPersister.unmarshal(sessionStorageSerialized);

        const mergedSession = { ...sessionStorage, ...localStorage, locale: sessionStorage.locale };
        return mergedSession;
    }

    setSession(newState: IPerformaSessionState) {
        this.window.sessionStorage.setItem(this.getUniqueId(), SessionPersister.marshal(newState));
        this.window.localStorage.setItem(
            SessionPersister.STORAGE_ENTRY_NAME,
            SessionPersister.marshal(newState)
        );
    }

    private static marshal(state: IPerformaSessionState) {
        return JSON.stringify(state);
    }

    private static unmarshal(value: string | null): IPerformaSessionState {
        return value ? JSON.parse(value) : {};
    }

    private getUniqueId() {
        let value = window.sessionStorage.getItem(SessionPersister.SESSION_ENTRY_ID);

        if (!value || !window.name) {
            value = SessionPersister.generateUuid();
            window.sessionStorage.setItem(SessionPersister.SESSION_ENTRY_ID, value);
        }

        window.name = value;
        return value;
    }

    // https://www.ietf.org/rfc/rfc4122.txt
    // http://stackoverflow.com/a/2117523
    private static generateUuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}
