import React from 'react';
import { HOC } from '../../util/types';
import { CrmContext } from './CrmContext';
import { ICrmContextState } from './ICrmContextState';

export interface IWithCrmContext {
    crmContext: ICrmContextState;
}

export const withCrmContext: HOC<IWithCrmContext> = (InnerComponent: any) => (props) => (
    <CrmContext.Consumer>
        {(crmContext) => <InnerComponent crmContext={crmContext} {...props} />}
    </CrmContext.Consumer>
);
