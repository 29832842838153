import { inject, injectable } from 'inversify';
import { ICustomAction } from './ICustomAction';
import { ICustomActionParser } from './ICustomActionParser';
import { II18n, QuinoCoreServiceSymbols } from '@quino/core';
import { EntityActionStyleHelper } from '../meta/EntityAction/EntityActionStyleHelper';

/**
 * Parse custom actions form a string. The custom action names are
 * expected to by semi-colon separated.
 */
@injectable()
export class CustomActionParser implements ICustomActionParser {
    constructor(@inject(QuinoCoreServiceSymbols.II18N) private i18n: II18n) {}

    /**
     * Parse custom actions from @param input. The custom action names are
     * expected to be a semi-colon separated list.
     * @param input
     */
    parse = (input: string): ICustomAction[] =>
        input
            .split(';')
            .map(this.trimActionName)
            .filter((customActionName) => customActionName !== '')
            .map(this.createCustomAction);

    private trimActionName = (customActionName: string): string => customActionName.trim();

    private createCustomAction = (customActionNameWithStyle: string): ICustomAction => {
        const name = EntityActionStyleHelper.removeStyle(customActionNameWithStyle);
        const caption = this.translateCaption(name);
        const style = EntityActionStyleHelper.extractStyle(customActionNameWithStyle);
        const disabled = false;

        return { name, caption, disabled, style };
    };

    private translateCaption = (customActionName: string) => {
        return this.i18n.t(`literal.CustomLiterals.CustomAction.${customActionName}`);
    };
}
