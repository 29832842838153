import { inject, injectable } from 'inversify';
import { IPerformaUrlManager } from './IPerformaUrlManager';
import { ICrmContextIdentifier } from '..';
import { IUrlHelper, QuinoCoreServiceSymbols } from '@quino/core';

@injectable()
export class PerformaUrlManager implements IPerformaUrlManager {
    private readonly appControllerName = 'app';
    private readonly authControllerName = 'auth';
    private readonly authorizationControllerName = 'authorization';
    private readonly configurationControllerName = 'configuration';
    private readonly contextControllerName = 'context';
    private readonly profileControllerName = 'profile';
    private readonly customActionControllerName = 'customaction';
    private readonly storageControllerName = 'storage';
    private readonly genericControllerName = 'generic';
    private readonly recruiterControllerName = 'recruiter';
    private readonly customQueryControllerName = 'queries';

    constructor(@inject(QuinoCoreServiceSymbols.IUrlHelper) private urlHelper: IUrlHelper) {}

    getConfigurationUrl(): string {
        return this.urlHelper.combineUrlParts(this.configurationControllerName, 'list');
    }

    getContextListUrl(forceReload?: boolean): string {
        const params = forceReload ? this.urlHelper.combineQueryParams({ forceReload }) : '';
        return this.urlHelper.combineUrlParts(this.contextControllerName, 'list') + params;
    }

    getSetContextUrl(): string {
        return this.urlHelper.combineUrlParts(this.contextControllerName);
    }

    getContextImageUrl(crmContextIdentifier: ICrmContextIdentifier): string {
        return (
            this.urlHelper.combineUrlParts(this.contextControllerName, 'image') +
            this.urlHelper.combineQueryParams(crmContextIdentifier)
        );
    }

    getProfileUrl(): string {
        return this.urlHelper.combineUrlParts(this.profileControllerName);
    }

    getConfirmEmailUrl(): string {
        return this.urlHelper.combineUrlParts(this.profileControllerName, 'confirmmailaddress');
    }

    getSetPasswordUrl(): string {
        return this.urlHelper.combineUrlParts(this.profileControllerName, 'setpassword');
    }

    getResetPasswordUrl(): string {
        return this.urlHelper.combineUrlParts(this.profileControllerName, 'resetpassword');
    }

    getChangePasswordUrl(): string {
        return this.urlHelper.combineUrlParts(this.profileControllerName, 'changepassword');
    }

    getCheckTokenUrl(): string {
        return this.urlHelper.combineUrlParts(this.authControllerName, 'check');
    }

    getAuthorizationUrl(nameFilter?: string): string {
        const params = nameFilter ? this.urlHelper.combineQueryParams({ nameFilter }) : '';
        return this.urlHelper.combineUrlParts(this.authorizationControllerName, 'list') + params;
    }

    getVersionInfoUrl(): string {
        return this.urlHelper.combineUrlParts(this.appControllerName, 'versioninfo');
    }

    getRestartServerUrl(): string {
        return this.urlHelper.combineUrlParts(this.appControllerName, 'recycle');
    }

    getExecuteCustomActionUrl(customActionName: string): string {
        return this.urlHelper.combineUrlParts(this.customActionControllerName, customActionName);
    }

    getLogoutUrl(): string {
        return this.urlHelper.combineUrlParts(this.profileControllerName, 'logout');
    }

    getDataEntryStatusUrl(): string {
        return this.urlHelper.combineUrlParts(this.profileControllerName, 'dataentrystatus');
    }

    getHealthCheck(): string {
        return this.urlHelper.combineUrlParts(this.appControllerName, 'healthcheck');
    }

    getPersistentStorageUploadUrl(): string {
        return this.urlHelper.combineUrlParts(this.storageControllerName, 'persistent');
    }

    getPersistentStorageDownloadUrl(id: string): string {
        return this.urlHelper.combineUrlParts(
            this.storageControllerName,
            'persistent',
            'download',
            id
        );
    }

    deletePersistentStorageUrl(id: string): string {
        return this.urlHelper.combineUrlParts(this.storageControllerName, 'persistent', id);
    }

    getStorageMetadataUrl(byId: string): string {
        return this.urlHelper.combineUrlParts(this.storageControllerName, 'metadata', 'byId', byId);
    }

    findStorageMetadataUrl(
        ownerKey: string,
        ownerValue: string,
        entityKey: string,
        entityValue: string
    ): string {
        const queryParams = {};
        queryParams[ownerKey] = ownerValue;
        queryParams[entityKey] = entityValue;

        return (
            this.urlHelper.combineUrlParts(this.storageControllerName, 'metadata', 'find') +
            this.urlHelper.combineQueryParams(queryParams)
        );
    }

    setCustomAttributesToStorageMetadataUrl(byId: string): string {
        return this.urlHelper.combineUrlParts(this.storageControllerName, 'metadata', byId);
    }

    insertObjectUrl(className: string, layout: string): string {
        return (
            this.urlHelper.combineUrlParts(this.genericControllerName, 'class', className) +
            this.urlHelper.combineQueryParams({ layout })
        );
    }

    updateObjectUrl(className: string, primaryKey: string, layout: string): string {
        return (
            this.urlHelper.combineUrlParts(
                this.genericControllerName,
                'class',
                className,
                'primaryKey',
                primaryKey
            ) + this.urlHelper.combineQueryParams({ layout })
        );
    }

    getRegisterRecruiterUrl(): string {
        return this.urlHelper.combineUrlParts(this.recruiterControllerName, 'register');
    }

    getCustomQueryListUrl(queryName: string, params: object): string {
        return (
            this.urlHelper.combineUrlParts(this.customQueryControllerName, 'list', queryName) +
            this.urlHelper.combineQueryParams(params)
        );
    }
}
