import { IBookmark, IQuinoComponentProps, QuinoUIServiceSymbols, useService } from '@quino/ui';
import * as React from 'react';
import { IMetaElement, IMetaProperty, isIMetaGroup } from '@quino/core';
import { IComponentFactory } from '@quino/ui/dist/components/ComponentFactory/IComponentFactory';

type TStyles = {
    verticalListBox: string;
};

const styles: TStyles = require('./VerticalList.less');

export const VerticalList: React.FunctionComponent<IQuinoComponentProps<IBookmark>> = (props) => {
    const { element, bookmark, actions, children } = props;
    const componentFactory = useService<IComponentFactory>(QuinoUIServiceSymbols.IComponentFactory);

    if (isIMetaGroup(element)) {
        return (
            <div>
                {element.elements &&
                    element.elements.map((element: IMetaProperty) => (
                        <div className={styles.verticalListBox} key={element.name}>
                            <h3>{element.caption || ''}</h3>
                            {(isIMetaGroup(element) &&
                                (element.elements != null &&
                                    element.elements.map((child: IMetaElement) =>
                                        componentFactory.create(child, bookmark, actions)
                                    ))) || { children }}
                            {props.children}
                        </div>
                    ))}
                {children}
            </div>
        );
    } else {
        throw new Error(
            `Element [${element.name}] is not a valid meta group. Full element: [${JSON.stringify(
                element
            )}]`
        );
    }
};
