import React from 'react';
import { FloatingVersionInfo } from 'performa-shared';
import { FloatingVersionInfoMode } from 'performa-shared';

const image = require('./pnet_background.jpg');

const styles: TStyles = require('./PnetLoggedOutDecorator.less');

type TStyles = {
    pnetDecorator: string;
    pnetDecoratorChildren: string;
    pnetDecoratorImageWrapper: string;
    pnetDecoratorImage: string;
};

export const PnetLoggedOutDecorator = ({ children }: any) => {
    return (
        <>
            <FloatingVersionInfo mode={FloatingVersionInfoMode.Dark} />
            <div className={styles.pnetDecorator}>
                <div className={styles.pnetDecoratorChildren}>{children}</div>
                <div className={styles.pnetDecoratorImageWrapper}>
                    <img src={image} alt="" className={styles.pnetDecoratorImage} />
                </div>
            </div>
        </>
    );
};
