import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { Typography } from '../../Util/Devextreme/Typography';
import { LoginContext } from '../../../contexts/loginContext/LoginContext';
import { IRouteHelper } from '../../../navigation/IRouteHelper';
import { useHistory } from 'react-router';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { Translation } from '@quino/ui';
import { tKey } from '../../../lang/TranslationKeys';
import { Link } from 'react-router-dom';
import { ConfigurationContext } from '../../../contexts/configurationContext/ConfigurationContext';
import { PasswordTextField } from '../PasswordTextField/PasswordTextField';
import { LoginFormCustomText } from './LoginFormCustomText';
import { useService } from '../../../ioc/hook/useService';
import { useDispatch } from 'react-redux';
import { apiActions } from '../../../redux/api/apiStore';
import { ErrorMessage } from '../../Util/ErrorMessage';
import { LoadPanel } from 'devextreme-react/load-panel';
import { useTextBoxFocusRef } from '../../Util/Devextreme/DevextremeHelpers';
import { useI18n } from '../../../lang/useI18n';
import { ILoginFormExtensions } from './ILoginFormExtensions';

type TStyles = {
    authFormRoot: string;
    authFormForm: string;
    authFormButtonBar: string;
    authFormTextFields: string;
    authFormFieldPassword: string;
    authFormRegisterParagraph: string;
};

const styles: TStyles = require('../AuthForm.less');

const healthCheckPollIntervalMS = 60000;

export const LoginForm: React.FC = () => {
    const routeHelper = useService<IRouteHelper>(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER);
    const loginFormExtension = useService<ILoginFormExtensions>(
        SHARED_SERVICE_IDENTIFIER.ILOGINFORMEXTENSION
    );
    const i18n = useI18n();

    const loginContext = useContext(LoginContext);
    const configuration = useContext(ConfigurationContext);
    const history = useHistory();

    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');

    const [isLoginInProgress, setIsLoginInProgress] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const dispatch = useDispatch();

    const initialFocusRef = useTextBoxFocusRef();

    const requestHealthCheck = () => {
        dispatch(apiActions.requestHealthCheck());
    };

    const setUpHealthCheckPolling = () => {
        requestHealthCheck();
        const interval = window.setInterval(requestHealthCheck, healthCheckPollIntervalMS);

        return () => {
            window.clearTimeout(interval);
        };
    };
    useEffect(setUpHealthCheckPolling, []);

    const loginAsync = async (): Promise<void> => {
        setIsLoginInProgress(true);

        try {
            const loginResult = await loginContext.onTryLoginAsync(emailAddress, password);

            if (!loginResult) {
                setErrorMessage(i18n.t('literal.CustomLiterals.Login.InvalidLogin'));
            }
        } catch (ex) {
            setErrorMessage(ex.message);
        }

        setIsLoginInProgress(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        loginAsync();
    };

    const goToForgotPassword = () => {
        const loginPath = routeHelper.getForgotPasswordUrl(emailAddress);
        history.push(loginPath);
    };

    const isRegistrationAllowed = configuration.getBooleanValueOrDefault(
        'client.Registration.AllowSelfRegistration',
        false
    );

    const sendDisabled = isLoginInProgress || !emailAddress || !password;

    return (
        <div className={styles.authFormRoot}>
            <form autoComplete="off" className={styles.authFormForm} onSubmit={handleSubmit}>
                <Typography>
                    <h5>
                        <Translation i18nKey={tKey('literal.CustomLiterals.Login.Title')} />
                    </h5>
                </Typography>

                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

                <TextBox
                    id="emailAddress"
                    className={styles.authFormTextFields}
                    value={emailAddress}
                    width={'100%'}
                    ref={initialFocusRef}
                    stylingMode={'filled'}
                    placeholder={i18n.t('literal.CustomLiterals.Login.EmailAddressPlaceholder')}
                    onInput={({ event }) => setEmailAddress((event as any).target.value || '')}
                />

                <PasswordTextField
                    id="password"
                    className={classNames(styles.authFormTextFields, styles.authFormFieldPassword)}
                    width={'100%'}
                    stylingMode={'filled'}
                    value={password}
                    placeholder={i18n.t('literal.CustomLiterals.Login.PasswordPlaceholder')}
                    onInput={({ event }) => setPassword((event as any).target.value || '')}
                />

                <Typography>
                    <p>
                        <a href={'#'} onClick={goToForgotPassword}>
                            <Translation
                                i18nKey={tKey('literal.CustomLiterals.Login.ForgotPasswordButton')}
                            />
                        </a>
                    </p>
                </Typography>

                <LoginFormCustomText />

                <div className={styles.authFormButtonBar}>
                    <Button
                        useSubmitBehavior={true}
                        stylingMode={'contained'}
                        type={'default'}
                        disabled={sendDisabled}
                        text={i18n.t(tKey('literal.CustomLiterals.Login.LoginButton'))}
                        icon={'key'}
                    />
                    <LoadPanel visible={isLoginInProgress} />
                </div>

                {isRegistrationAllowed && (
                    <Typography>
                        <p className={styles.authFormRegisterParagraph}>
                            <Link to={routeHelper.getRegisterUserUrl()}>
                                <Translation
                                    i18nKey={tKey('literal.CustomLiterals.Login.Register')}
                                />
                            </Link>
                        </p>
                        {loginFormExtension
                            .getExtensions()
                            .map((loginFormExtensionProps, index) => {
                                return (
                                    <p
                                        key={`LoginFormExtension_${index}`}
                                        className={styles.authFormRegisterParagraph}
                                    >
                                        <Link to={loginFormExtensionProps.link}>
                                            <Translation
                                                i18nKey={tKey(
                                                    loginFormExtensionProps.translationKey
                                                )}
                                            />
                                        </Link>
                                    </p>
                                );
                            })}
                    </Typography>
                )}
            </form>
        </div>
    );
};
