/**
 * The identifier for retrieving a grid drill down aspect.
 */
import { IMetaAspect } from '@quino/core';

export const EnableGridDrillDownAspectIdentifier = 'ControlParameters';

/**
 * Aspect containing a boolean if drill down should be enabled
 */
export interface IEnableGridDrillDownAspect extends IMetaAspect {
    enabledrilldown: boolean;
}
