import React from 'react';
import { ICrmContext } from '../..';
import { AvatarSize, CrmContextAvatar } from './CrmContextAvatar';

interface IProps {
    crmContext?: ICrmContext;
    onClick: (crmContext: ICrmContext) => void;
}

interface IStyles {
    root: string;
    avatar: string;
}

const styles: IStyles = require('./CrmContextListItem.less');

export class CrmContextListItem extends React.Component<IProps> {
    render() {
        const { crmContext, onClick } = this.props;

        if (!crmContext) {
            return null;
        }

        return (
            <div className={styles.root} onClick={() => onClick(crmContext)}>
                <div className={styles.avatar}>
                    <CrmContextAvatar crmContext={crmContext} avatarSize={AvatarSize.Normal} />
                </div>
                <div>{crmContext.caption}</div>
            </div>
        );
    }
}
