import { IForgotPasswordStateHelper } from './IForgotPasswordStateHelper';
import { IForgotPasswordState } from './IForgotPasswordState';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { IProfileService } from '../../../api/IProfileService';
import { QuinoCoreServiceSymbols } from '@quino/core';
import { IPerformaI18n } from '../../../lang/IPerformaI18n';

@injectable()
export class ForgotPasswordStateHelper implements IForgotPasswordStateHelper {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPROFILESERVICE) private profileService: IProfileService,
        @inject(QuinoCoreServiceSymbols.II18N) private i18n: IPerformaI18n
    ) {}

    updateUsername(oldState: IForgotPasswordState, username: string): IForgotPasswordState {
        return { ...oldState, username: username };
    }

    async submitAsync(oldState: IForgotPasswordState): Promise<IForgotPasswordState> {
        try {
            await this.profileService.resetPasswordAsync(oldState.username);
            return { ...oldState, processCompleted: true };
        } catch (e) {
            const errorMessage = this.i18n.t('literal.CustomLiterals.ForgotPassword.ErrorMessage', {
                errorMessage: e.message,
            });

            return {
                ...oldState,
                errorMessage,
                processCompleted: false,
            };
        }
    }
}
