import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IPerformaServer } from '../api';
import { IFrontendVersionInfo } from './IFrontendVersionInfo';
import { IVersionInfoService } from './IVersionInfoService';
import { IVersionInfo } from './IVersionInfo';

@injectable()
export class VersionInfoService implements IVersionInfoService {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMASERVER) private performaServer: IPerformaServer,
        @inject(SHARED_SERVICE_IDENTIFIER.IFRONTENDVERSIONINFO)
        private frontendVersionInfo: IFrontendVersionInfo
    ) {}

    async getVersionInfoAsync(): Promise<IVersionInfo> {
        const backendVersionInfo = await this.performaServer.getVersionInfoAsync();

        return {
            backend: backendVersionInfo,
            frontend: this.frontendVersionInfo,
        };
    }
}
