import React from 'react';
import { Button } from 'devextreme-react/button';
import { SideBarWidth } from '../../../Sidebar/Sidebar';

type TStyles = {
    general: string;
    open: string;
};

const styles: TStyles = require('./SidebarCloser.less');

interface IProps {
    isDrawerOpen: boolean;
    onClick: () => unknown;
}

export const SidebarCloser: React.FC<IProps> = (props) => {
    const width = props.isDrawerOpen ? SideBarWidth : 0;

    return (
        <div className={`${styles.general} ${props.isDrawerOpen && styles.open}`} style={{ width }}>
            <Button width={48} height={48} onClick={props.onClick} icon={'chevronleft'} />
        </div>
    );
};
