import { IRouteExtensions } from './IRouteExtensions';
import { injectable } from 'inversify';
import { RouteProps } from 'react-router';

@injectable()
export class NullRouteExtensions implements IRouteExtensions {
    getExtensions(): RouteProps[] {
        return [];
    }
}
