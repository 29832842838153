import { IDataEntryStatusDTO } from './DTOs/IDataEntryStatusDTO';
import { IDataEntryStatusService } from './IDataEntryStatusService';
import { inject, injectable } from 'inversify';
import { IPerformaUrlManager } from './IPerformaUrlManager';
import { IRequestBuilder, QuinoCoreServiceSymbols } from '@quino/core';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';

@injectable()
export class DataEntryStatusService implements IDataEntryStatusService {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMAURLMANAGER)
        private urlManager: IPerformaUrlManager,
        @inject(QuinoCoreServiceSymbols.IRequestBuilder) private requestBuilder: IRequestBuilder
    ) {}

    async getDataEntryStatusAsync(): Promise<IDataEntryStatusDTO> {
        const url = this.urlManager.getDataEntryStatusUrl();
        const response = this.requestBuilder
            .create(url, 'get')
            .requiresAuthentication()
            .fetchJson<IDataEntryStatusDTO>();

        return await response;
    }
}
