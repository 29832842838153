import { IConfigurationService } from '../configuration';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { inject, injectable } from 'inversify';
import { IPerformaStorage } from './IPerformaStorage';
import { IPerformaUrlManager, IStorageService } from '../api';
import { IFileMetadata } from '../api/DTOs/IFileMetadata';

const defaultMimeTypes = ['image/png', 'image/jpg', 'image/jpeg'];
const defaultMaxFileSizeInBytes = 1024 * 1024;

const ownerKey = 'owner';
const entityKey = 'entityname';

@injectable()
export class PerformaStorage implements IPerformaStorage {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.ICONFIGURATIONSERVICE)
        private configurationService: IConfigurationService,
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMAURLMANAGER)
        private urlManager: IPerformaUrlManager,
        @inject(SHARED_SERVICE_IDENTIFIER.ISTORAGESERVICE) private storageService: IStorageService
    ) {}

    private cachedStorageMetadata: { [key: string]: Promise<IFileMetadata[]> } = {};

    getUploadUrl(): string {
        return this.urlManager.getPersistentStorageUploadUrl();
    }

    async getMaxFileSizeInBytesAsync(): Promise<number> {
        const configuration = await this.configurationService.getConfigurationAsync();
        const maxFileSizeInBytesConfig = configuration.getNumberValue(
            'client.FileUpload.MaxFileSizeInBytes'
        );
        if (maxFileSizeInBytesConfig) {
            return maxFileSizeInBytesConfig;
        }
        return defaultMaxFileSizeInBytes;
    }

    async getAcceptedMimeTypesAsync(): Promise<string> {
        const configuration = await this.configurationService.getConfigurationAsync();
        const acceptedMimeTypesCsvConfig = configuration.getStringValue(
            'client.FileUpload.AcceptedMimeTypes'
        );
        if (acceptedMimeTypesCsvConfig) {
            return acceptedMimeTypesCsvConfig;
        }
        return defaultMimeTypes.join(';');
    }

    async setOwnerAndContextAsync(id: string, entityname: string, owner: string, context: string) {
        this.invalidateCache();
        await this.storageService.setCustomAttributesToStorageMetadata(id, {
            entityname,
            owner,
            context,
        });
    }

    async getMetaDataAsync(id: string): Promise<IFileMetadata> {
        return this.storageService.getStorageMetadataAsync(id);
    }

    async tryLoadStorageObjectUrlAsync(id: string): Promise<string | undefined> {
        return this.storageService.tryLoadPersistentStorageObjectUrlAsync(id);
    }

    getStorageMetadataForOwner(entity: string, owner: string): Promise<IFileMetadata[]> {
        if (owner != 'null') {
            if (this.cachedStorageMetadata[owner] == null) {
                this.cachedStorageMetadata[owner] = this.storageService.findStorageMetadataAsync(
                    ownerKey,
                    owner,
                    entityKey,
                    entity
                );
            }

            return this.cachedStorageMetadata[owner];
        }
        return new Promise<IFileMetadata[]>((resolve) => resolve([]));
    }

    async deleteStorage(id: string): Promise<any> {
        this.invalidateCache();
        return this.storageService.deletePersistentStorageAsync(id);
    }

    private invalidateCache(): void {
        this.cachedStorageMetadata = {};
    }
}
