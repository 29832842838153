import { useContext, useState } from 'react';
import { interfaces } from 'inversify';
import ServiceIdentifier = interfaces.ServiceIdentifier;
import { ContainerContext } from '@quino/ui';

export function useService<T>(serviceName: ServiceIdentifier<T>): T {
    const { container } = useContext(ContainerContext);
    const [result] = useState(container.get<T>(serviceName));
    return result;
}
