import * as React from 'react';
import { Icon } from '../Util/Icon';
import { IVersionInfoService } from '../../versionInfo/IVersionInfoService';
import { IVersionInfo } from '../../versionInfo/IVersionInfo';
import { lazyInject, QuinoCoreServiceSymbols } from '@quino/core';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { IPerformaI18n } from '../../lang/IPerformaI18n';

type TStyles = {
    versionInfoRoot: string;
    versionInfoVersionNumber: string;
};

const styles: TStyles = require('./VersionInfo.less');

interface IState {
    versionInfo?: IVersionInfo;
}

export class VersionInfo extends React.Component<{}, IState> {
    @lazyInject(SHARED_SERVICE_IDENTIFIER.IVERSIONINFOSERVICE)
    private versionInfoService: IVersionInfoService;
    @lazyInject(QuinoCoreServiceSymbols.II18N)
    private i18n: IPerformaI18n;

    constructor(props: any) {
        super(props);
        this.state = {
            versionInfo: undefined,
        };
    }

    async componentDidMount() {
        const versionInfo = await this.versionInfoService.getVersionInfoAsync();
        this.setState({ versionInfo });
    }

    render() {
        const { versionInfo } = this.state;

        return (
            <div className={styles.versionInfoRoot}>
                <span
                    title={this.i18n.t('literal.CustomLiterals.VersionInfo.FrontEndVersion', {
                        version: versionInfo ? versionInfo.frontend.frontendVersionInfo : '',
                    })}
                    className={styles.versionInfoVersionNumber}
                >
                    <Icon name={'laptop'} />
                    {versionInfo && versionInfo.frontend.frontendVersionInfo}
                </span>

                <span
                    title={this.i18n.t('literal.CustomLiterals.VersionInfo.BackEndVersion', {
                        version: versionInfo ? versionInfo.backend.backendVersion : '',
                    })}
                    className={styles.versionInfoVersionNumber}
                >
                    <Icon name={'dns'} />
                    {versionInfo && versionInfo.backend.backendVersion}
                </span>
            </div>
        );
    }
}
