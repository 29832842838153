import { ContainerModule, interfaces } from 'inversify';
import { ConfigurationService } from '../configuration/ConfigurationService';
import { IConfigurationService } from '../configuration/IConfigurationService';
import { ICustomActionService } from '../api/ICustomActionService';
import { ILocationService } from '../navigation/ILocationService';
import { IPerformaServer } from '../api/IPerformaServer';
import { IPerformaUrlManager } from '../api/IPerformaUrlManager';
import { ISettings } from '../ISettings';
import { IStorageService } from '../api/IStorageService';
import { LocationService } from '../navigation/LocationService';
import { PerformaServer } from '../api/PerformaServer';
import { PerformaUrlManager } from '../api/PerformaUrlManager';
import { SHARED_SERVICE_IDENTIFIER } from './sharedIdentifiers';
import { ISessionPersister } from '../session/ISessionPersister';
import { SessionPersister } from '../session/SessionPersister';
import { AuthService } from '../authentication/AuthService';
import { IPerformaAuthService } from '../authentication/IPerformaAuthService';
import { ISessionService } from '../session/ISessionService';
import { SessionService } from '../session/SessionService';
import { ICrmContextWrapperStateHelper } from '../Components/CrmContext/Wrapper/ICrmContextWrapperStateHelper';
import { CrmContextWrapperStateHelper } from '../Components/CrmContext/Wrapper/CrmContextWrapperStateHelper';
import { IImageFetcher } from '../api/IImageFetcher';
import { ImageFetcher } from '../api/ImageFetcher';
import { IPasswordResetStateHelper } from '../Components/Auth/PasswordReset/IPasswordResetStateHelper';
import { PasswordResetStateHelper } from '../Components/Auth/PasswordReset/PasswordResetStateHelper';
import { IForgotPasswordStateHelper } from '../Components/Auth/ForgotPassword/IForgotPasswordStateHelper';
import { ForgotPasswordStateHelper } from '../Components/Auth/ForgotPassword/ForgotPasswordStateHelper';
import { IProfileService } from '../api/IProfileService';
import { ProfileService } from '../api/ProfileService';
import { IRouteHelper } from '../navigation/IRouteHelper';
import { RouteHelper } from '../navigation/RouteHelper';
import { IAuthorizationFactory } from '../authorization/IAuthorizationFactory';
import { AuthorizationFactory } from '../authorization/AuthorizationFactory';
import { IFrontendVersionInfo, IVersionInfoService } from '../versionInfo';
import { VersionInfoService } from '../versionInfo/VersionInfoService';
import { IChangePasswordStateHelper } from '../Components/MainLayout/Components/ChangePasswordDialog/IChangePasswordStateHelper';
import { ChangePasswordStateHelper } from '../Components/MainLayout/Components/ChangePasswordDialog/ChangePasswordStateHelper';
import { IPasswordValidatorConfig } from '../authentication/IPasswordValidatorConfig';
import { PasswordValidatorConfig } from '../authentication/PasswordValidatorConfig';
import { IPasswordPolicy } from '../authentication/PasswordPolicy/IPasswordPolicy';
import { MinimumLengthPolicy } from '../authentication/PasswordPolicy/MinimumLengthPolicy';
import { MaximumLengthPolicy } from '../authentication/PasswordPolicy/MaximumLengthPolicy';
import { LowercaseCharacterPolicy } from '../authentication/PasswordPolicy/LowercaseCharacterPolicy';
import { UppercaseCharacterPolicy } from '../authentication/PasswordPolicy/UppercaseCharacterPolicy';
import { NumberPresencePolicy } from '../authentication/PasswordPolicy/NumberPresencePolicy';
import { SpecialCharacterPolicy } from '../authentication/PasswordPolicy/SpecialCharacterPolicy';
import { IPasswordValidator } from '../authentication/IPasswordValidator';
import { PasswordValidator } from '../authentication/PasswordValidator';
import { ProfileFormStateHelper } from '../Components/Profile/ProfileFormStateHelper';
import { IProfileFormStateHelper } from '../Components/Profile/IProfileFormStateHelper';
import { LayoutLoader } from '../data/LayoutLoader';
import { ILayoutLoader } from '../data/ILayoutLoader';
import { ILayoutCatalogManager } from '../Components/DataBrowser/ILayoutCatalogManager';
import { LayoutCatalogManager } from '../Components/DataBrowser/LayoutCatalogManager';
import { CurrentUserProfileCache } from '../api/CurrentUserProfileCache';
import { ICurrentUserProfileCache } from '../api/ICurrentUserProfileCache';
import {
    IHelpTextVisibilityStrategy,
    IQuinoDataGridConfigurationService,
    QuinoUIServiceSymbols,
} from '@quino/ui';
import { ProfileHelpTextVisibilityStrategy } from '../configuration/ProfileHelpTextVisibilityStrategy';
import {
    ICustomActionNamesReader,
    ICustomActionParser,
    IEntityActionsExtractor,
} from '../customActions';
import { CustomActionNamesReader } from '../customActions/CustomActionNamesReader';
import { CustomActionParser } from '../customActions/CustomActionParser';
import { EntityActionsExtractor } from '../customActions/EntityActionsExtractor';
import { CustomActionService } from '../api/CustomActionService';
import { IContextRouteChecker } from '../navigation/IContextRouteChecker';
import { ContextRouteChecker } from '../navigation/ContextRouteChecker';
import { IConfigurationLoader } from '../configuration/IConfigurationLoader';
import { ConfigurationLoader } from '../configuration/ConfigurationLoader';
import { ILayoutNameResolver } from '../data/ILayoutNameResolver';
import { LayoutNameResolver } from '../data/LayoutNameResolver';
import { IDataEntryStatusService } from '../api/IDataEntryStatusService';
import { DataEntryStatusService } from '../api/DataEntryStatusService';
import { StorageService } from '../api/StorageService';
import { IPerformaStorage } from '../storage/IPerformaStorage';
import { PerformaStorage } from '../storage/PerformaStorage';
import { PerformaFileHandler } from '../storage/PerformaFileHandler';
import { IPerformaFileHandler } from '../storage/IPerformaFileHandler';
import { performaStore, TPerformaStore } from '../redux/store';
import { PerformaDataGridConfigurationService } from '../Components/Grid/PerformaDataGridConfigurationService';
import { IRouteExtensions } from '../navigation/IRouteExtensions';
import { NullRouteExtensions } from '../navigation/NullRouteExtensions';
import { ILoginFormExtensions } from '../Components/Auth/LoginForm/ILoginFormExtensions';
import { NullLoginFormExtension } from '../Components/Auth/LoginForm/NullLoginFormExtension';

export const sharedModule = (settings: ISettings, frontendVersion: string) =>
    // prettier-ignore
    new ContainerModule((bind: interfaces.Bind, unbind: interfaces.Unbind, isBound: interfaces.IsBound, rebind: interfaces.Rebind) => {
        bind<ISettings>(SHARED_SERVICE_IDENTIFIER.ISETTINGS)
            .toConstantValue(settings);
        bind<IFrontendVersionInfo>(SHARED_SERVICE_IDENTIFIER.IFRONTENDVERSIONINFO)
            .toConstantValue({frontendVersionInfo: frontendVersion});
        bind<IPerformaUrlManager>(SHARED_SERVICE_IDENTIFIER.IPERFORMAURLMANAGER)
            .to(PerformaUrlManager)
            .inSingletonScope();
        bind<IPerformaServer>(SHARED_SERVICE_IDENTIFIER.IPERFORMASERVER)
            .to(PerformaServer)
            .inSingletonScope();
        bind<IConfigurationService>(SHARED_SERVICE_IDENTIFIER.ICONFIGURATIONSERVICE)
            .to(ConfigurationService)
            .inSingletonScope();
        bind<ISessionPersister>(SHARED_SERVICE_IDENTIFIER.ISESSIONPERSISTER)
            .to(SessionPersister)
            .inSingletonScope();
        bind<IPerformaAuthService>(SHARED_SERVICE_IDENTIFIER.IPERFORMAAUTHSERVICE)
            .to(AuthService)
            .inSingletonScope();
        bind<ISessionService>(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE)
            .to(SessionService)
            .inSingletonScope();
        bind<ICrmContextWrapperStateHelper>(SHARED_SERVICE_IDENTIFIER.ICRMCONTEXTWRAPPERSTATEHELPER)
            .to(CrmContextWrapperStateHelper)
            .inSingletonScope();
        bind<IImageFetcher>(SHARED_SERVICE_IDENTIFIER.IIMAGEFETCHER)
            .to(ImageFetcher)
            .inSingletonScope();
        bind<Window>(SHARED_SERVICE_IDENTIFIER.WINDOW)
            .toDynamicValue(() => window);
        bind<IPasswordResetStateHelper>(SHARED_SERVICE_IDENTIFIER.IPASSWORDRESETSTATEHELPER)
            .to(PasswordResetStateHelper)
            .inSingletonScope();
        bind<IForgotPasswordStateHelper>(SHARED_SERVICE_IDENTIFIER.IFORGOTPASSWORDSTATEHELPER)
            .to(ForgotPasswordStateHelper)
            .inSingletonScope();
        bind<IProfileService>(SHARED_SERVICE_IDENTIFIER.IPROFILESERVICE)
            .to(ProfileService)
            .inSingletonScope();
        bind<IRouteHelper>(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER)
            .to(RouteHelper)
            .inSingletonScope();
        bind<IRouteExtensions>(SHARED_SERVICE_IDENTIFIER.IROUTEEXTENSIONS)
                .to(NullRouteExtensions)
                .inSingletonScope();
        bind<IAuthorizationFactory>(SHARED_SERVICE_IDENTIFIER.IAUTHORIZATIONFACTORY)
            .to(AuthorizationFactory)
            .inSingletonScope();
        bind<IVersionInfoService>(SHARED_SERVICE_IDENTIFIER.IVERSIONINFOSERVICE)
            .to(VersionInfoService)
            .inSingletonScope();
        bind<IChangePasswordStateHelper>(SHARED_SERVICE_IDENTIFIER.ICHANGEPASSWORDSTATEHELPER)
            .to(ChangePasswordStateHelper)
            .inSingletonScope();
        bind<IPasswordValidatorConfig>(SHARED_SERVICE_IDENTIFIER.IPASSWORDVALIDATORCONFIG)
            .to(PasswordValidatorConfig)
            .inSingletonScope();
        bind<IPasswordPolicy>(SHARED_SERVICE_IDENTIFIER.IPASSWORDPOLICY)
            .to(MinimumLengthPolicy)
            .inSingletonScope();
        bind<IPasswordPolicy>(SHARED_SERVICE_IDENTIFIER.IPASSWORDPOLICY)
            .to(MaximumLengthPolicy)
            .inSingletonScope();
        bind<IPasswordPolicy>(SHARED_SERVICE_IDENTIFIER.IPASSWORDPOLICY)
            .to(NumberPresencePolicy)
            .inSingletonScope();
        bind<IPasswordPolicy>(SHARED_SERVICE_IDENTIFIER.IPASSWORDPOLICY)
            .to(LowercaseCharacterPolicy)
            .inSingletonScope();
        bind<IPasswordPolicy>(SHARED_SERVICE_IDENTIFIER.IPASSWORDPOLICY)
            .to(UppercaseCharacterPolicy)
            .inSingletonScope();
        bind<IPasswordPolicy>(SHARED_SERVICE_IDENTIFIER.IPASSWORDPOLICY)
            .to(SpecialCharacterPolicy)
            .inSingletonScope();
        bind<IPasswordValidator>(SHARED_SERVICE_IDENTIFIER.IPASSWORDVALIDATOR)
            .to(PasswordValidator)
            .inSingletonScope();
        bind<IProfileFormStateHelper>(SHARED_SERVICE_IDENTIFIER.IPROFILEFORMSTATEHELPER)
            .to(ProfileFormStateHelper)
            .inSingletonScope();
        bind<ILayoutLoader>(SHARED_SERVICE_IDENTIFIER.ILAYOUTLOADER)
            .to(LayoutLoader)
            .inSingletonScope();
        bind<ILayoutCatalogManager>(SHARED_SERVICE_IDENTIFIER.ILAYOUTCATALOGMANAGER)
            .to(LayoutCatalogManager)
            .inRequestScope();
        bind<ICurrentUserProfileCache>(SHARED_SERVICE_IDENTIFIER.ICURRENTUSERPROFILECACHE)
            .to(CurrentUserProfileCache)
            .inSingletonScope();
        rebind<IHelpTextVisibilityStrategy>(QuinoUIServiceSymbols.IHelpTextVisibilityStrategy)
            .to(ProfileHelpTextVisibilityStrategy)
            .inRequestScope();
        bind<ICustomActionNamesReader>(SHARED_SERVICE_IDENTIFIER.ICUSTOMACTIONNAMESREADER)
            .to(CustomActionNamesReader)
            .inRequestScope();
        bind<ICustomActionParser>(SHARED_SERVICE_IDENTIFIER.ICUSTOMACTIONPARSER)
            .to(CustomActionParser)
            .inRequestScope();
        bind<IEntityActionsExtractor>(SHARED_SERVICE_IDENTIFIER.IENTITYACTIONSEXTRACTOR)
            .to(EntityActionsExtractor)
            .inRequestScope();
        bind<ICustomActionService>(SHARED_SERVICE_IDENTIFIER.ICUSTOMACTIONSERVICE)
            .to(CustomActionService)
            .inRequestScope();
        bind<IContextRouteChecker>(SHARED_SERVICE_IDENTIFIER.ICONTEXTROUTECHECKER)
            .to(ContextRouteChecker)
            .inRequestScope();
        bind<IConfigurationLoader>(SHARED_SERVICE_IDENTIFIER.ICONFIGURATIONLOADER)
            .to(ConfigurationLoader)
            .inSingletonScope();
        bind<ILayoutNameResolver>(SHARED_SERVICE_IDENTIFIER.ILAYOUTNAMERESOLVER)
            .to(LayoutNameResolver)
            .inRequestScope();
        bind<IDataEntryStatusService>(SHARED_SERVICE_IDENTIFIER.IDATAENTRYSTATUSSERVICE)
            .to(DataEntryStatusService)
            .inRequestScope();
        bind<IStorageService>(SHARED_SERVICE_IDENTIFIER.ISTORAGESERVICE)
            .to(StorageService)
            .inSingletonScope();
        bind<IPerformaStorage>(SHARED_SERVICE_IDENTIFIER.IPERFORMASTORAGE)
            .to(PerformaStorage)
            .inSingletonScope();
        bind<IPerformaFileHandler>(SHARED_SERVICE_IDENTIFIER.IPERFORMAFILEHANDLER)
            .to(PerformaFileHandler)
            .inRequestScope();
        bind<TPerformaStore>(SHARED_SERVICE_IDENTIFIER.IPERFORMASTORE)
            .toConstantValue(performaStore);
        bind<IQuinoDataGridConfigurationService>(SHARED_SERVICE_IDENTIFIER.PERFORMADATAGRIDCONFIGURATIONSERVICE)
            .to(PerformaDataGridConfigurationService)
            .inRequestScope();
        bind<ILocationService>(SHARED_SERVICE_IDENTIFIER.ILOCATIONSERVICE)
            .to(LocationService)
            .inRequestScope();
        bind<ILoginFormExtensions>(SHARED_SERVICE_IDENTIFIER.ILOGINFORMEXTENSION)
            .to(NullLoginFormExtension)
            .inSingletonScope();
        });
