import {
    IApplication,
    IAuthenticationFeedback,
    IAuthenticationResult,
    IAuthenticationService,
    PasswordAuthenticationData,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react/text-box';
import { ValidationSummary } from 'devextreme-react/validation-summary';
import { inject, injectable } from 'inversify';
import { ContainerContext } from '@quino/ui';
import { PasswordTextField } from '../PasswordTextField/PasswordTextField';
import { useI18n } from '../../../lang/useI18n';
import classNames from 'classnames';
import { useTextBoxFocusRef } from '../../Util/Devextreme/DevextremeHelpers';
import { PopupButtonBar } from '../../Util/Devextreme/PopupButtonBar';

type TStyles = {
    authFormRoot: string;
    authFormForm: string;
    authFormButtonBar: string;
    authFormTextFields: string;
    authFormFieldPassword: string;
};

const styles: TStyles = require('../AuthForm.less');

interface IPerformaLoginComponentState {
    username?: string;
    password?: string;
    isAuthenticating: boolean;
    isLoggedIn: boolean;
    error?: string;
}

interface IPerformaLoginComponentProps {
    authenticationService: IAuthenticationService;
    onLoggedIn: () => void;
}

const PerformaLoginComponent: React.FunctionComponent<IPerformaLoginComponentProps> = (props) => {
    const [state, dispatch] = useState<IPerformaLoginComponentState>({
        isAuthenticating: false,
        isLoggedIn: true,
    });

    const i18n = useI18n();
    const initialFocusRef = useTextBoxFocusRef();

    useEffect(() => {
        props.authenticationService
            .isLoggedIn()
            .then((result) => {
                if (result) {
                    dispatch({ ...state, isLoggedIn: true });
                    props.onLoggedIn();
                } else {
                    dispatch({ ...state, isLoggedIn: false });
                }
            })
            .catch(() => dispatch({ ...state, isLoggedIn: false }));
    }, []);

    return (
        <Popup
            visible={!state.isLoggedIn}
            title={i18n.t('literal.CustomLiterals.Login.SessionExpired.Title')}
            showCloseButton={false}
            closeOnOutsideClick={false}
            dragEnabled={false}
            height={325}
            width={400}
        >
            <LoadPanel
                visible={state.isAuthenticating}
                closeOnOutsideClick={false}
                shadingColor={'#AAAAAAAA'}
                shading={true}
            />
            <div className={styles.authFormRoot}>
                <form className={styles.authFormForm}>
                    <TextBox
                        id="emailAddress"
                        className={styles.authFormTextFields}
                        value={state.username}
                        width={'100%'}
                        ref={initialFocusRef}
                        stylingMode={'filled'}
                        placeholder={i18n.t('literal.CustomLiterals.Login.EmailAddressPlaceholder')}
                        onInput={({ event }) =>
                            dispatch({
                                ...state,
                                username: (event as any).target.value || '',
                            })
                        }
                    />
                    <PasswordTextField
                        id="password"
                        className={classNames(
                            styles.authFormTextFields,
                            styles.authFormFieldPassword
                        )}
                        stylingMode={'filled'}
                        width={'100%'}
                        value={state.password}
                        placeholder={i18n.t('literal.CustomLiterals.Login.PasswordPlaceholder')}
                        onInput={({ event }) =>
                            dispatch({
                                ...state,
                                password: (event as any).target.value || '',
                            })
                        }
                    />
                    <ValidationSummary visible={state.error != null}>
                        {state.error}
                    </ValidationSummary>
                    <PopupButtonBar>
                        <Button
                            text={i18n.t('literal.CustomLiterals.Application.ToStartPage')}
                            stylingMode={'outlined'}
                            type={'default'}
                            onClick={() => window.location.assign('/')}
                        />
                        <Button
                            text={i18n.t('literal.CustomLiterals.Login.LoginButton')}
                            icon={'key'}
                            stylingMode={'contained'}
                            type={'default'}
                            disabled={state.username == null || state.username.length === 0}
                            onClick={() => {
                                dispatch({ ...state, isAuthenticating: true });
                                props.authenticationService
                                    .loginAsync(
                                        new PasswordAuthenticationData(
                                            state.username!,
                                            state.password!
                                        )
                                    )
                                    .then((result: IAuthenticationResult) => {
                                        if (result.success) {
                                            dispatch({
                                                ...state,
                                                error: undefined,
                                                isAuthenticating: false,
                                            });
                                            props.onLoggedIn();
                                        } else {
                                            dispatch({
                                                ...state,
                                                error: i18n.t(
                                                    'literal.CustomLiterals.Login.InvalidLogin.Simple'
                                                ),
                                                isAuthenticating: false,
                                            });
                                        }
                                    })
                                    .catch(() =>
                                        dispatch({
                                            ...state,
                                            error: 'Failed to authenticate.',
                                            isAuthenticating: false,
                                        })
                                    );
                            }}
                        />
                    </PopupButtonBar>
                </form>
            </div>
        </Popup>
    );
};

@injectable()
export class PerformaAuthenticationFeedback implements IAuthenticationFeedback {
    constructor(
        @inject(QuinoCoreServiceSymbols.IAuthenticationService)
        private authenticationService: IAuthenticationService,
        @inject(Symbol.for('IApplication')) private application: IApplication
    ) {
        this.element = document.createElement('div');
        this.element.style.width = '0px';
        this.element.style.height = '0px';
        this.element.style.position = 'absolute';
        document.body.appendChild(this.element);
    }

    requestLogin = (onResolve: () => void): void => {
        if (!this.isMounted) {
            const loginComponent = (
                <ContainerContext.Provider value={{ container: this.application }}>
                    <PerformaLoginComponent
                        authenticationService={this.authenticationService}
                        onLoggedIn={() => {
                            this.resolves.forEach((x) => x());
                            this.resolves.splice(0, this.resolves.length);
                            ReactDOM.unmountComponentAtNode(this.element);
                            this.isMounted = false;
                        }}
                    />
                </ContainerContext.Provider>
            );
            ReactDOM.render(loginComponent, this.element);
            this.isMounted = true;
        }

        this.resolves.push(onResolve);
    };

    private readonly element: HTMLElement;
    private readonly resolves: (() => void)[] = [];
    private isMounted: boolean;
}
