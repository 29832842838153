import { DocumentsStateHelper, IDocumentsStateHelper } from '../Components/Documents';
import { PNET_SERVICE_IDENTIFIER } from './pnetIdentifiers';
import { CrmContextSharePointUrlProvider, IDocumentsUrlProvider } from '../core/urlProviders';
import { IApplication } from '@quino/core';

export class PerformaNetModule {
    static use = (application: IApplication): void => {
        application.registerSingle<IDocumentsStateHelper>(
            PNET_SERVICE_IDENTIFIER.IDOCUMENTSSTATEHELPER,
            DocumentsStateHelper
        );
        application.registerSingle<IDocumentsUrlProvider>(
            PNET_SERVICE_IDENTIFIER.IDOCUMENTSURLPROVIDER,
            CrmContextSharePointUrlProvider
        );
    };
}
