import {
    II18n,
    IMetadataTree,
    IMetaLayout,
    LayoutType,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { ILayoutLoader } from './ILayoutLoader';
import { inject, injectable } from 'inversify';

@injectable()
export class LayoutLoader implements ILayoutLoader {
    public static readonly LIST_LAYOUT = LayoutType.List;
    public static readonly DETAIL_LAYOUT = LayoutType.Detail;

    private layoutCache: { [key: string]: IMetaLayout[] } = {};

    constructor(
        @inject(QuinoCoreServiceSymbols.II18N) i18n: II18n,
        @inject(QuinoCoreServiceSymbols.IMetadataTree) private metaDataTree: IMetadataTree
    ) {
        i18n.addUpdateListener(() => this.clearCache());
    }

    getLayouts(entityName: string, ofLayoutType: LayoutType): IMetaLayout[] {
        try {
            if (!this.layoutCache[entityName]) {
                this.layoutCache[entityName] = this.metaDataTree.getLayouts(entityName);
            }

            const entityElement = this.layoutCache[entityName];
            return (entityElement || []).filter(({ type }: IMetaLayout) => type === ofLayoutType);
        } catch {
            delete this.layoutCache[entityName];
            return [];
        }
    }

    private clearCache() {
        this.layoutCache = {};
    }
}
