import { IGenericObject } from '@quino/core';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { ICustomAction, ICustomActionNamesReader, ICustomActionParser } from '.';
import { IEntityActionsExtractor } from './IEntityActionsExtractor';

/**
 * Default implementation of @see IEntityActionsExtractor.
 */
@injectable()
export class EntityActionsExtractor implements IEntityActionsExtractor {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.ICUSTOMACTIONNAMESREADER)
        private customActionNamesReader: ICustomActionNamesReader,
        @inject(SHARED_SERVICE_IDENTIFIER.ICUSTOMACTIONPARSER)
        private customActionParser: ICustomActionParser
    ) {}

    /**
     * Gets the IEntityAction[] from the @param dataObj.
     * @param dataObj The data object to get the custom actions for.
     */
    async getEntityActionsAsync(dataObj: IGenericObject): Promise<ICustomAction[] | undefined> {
        const customActionDefinition = await this.customActionNamesReader.readAsync(dataObj);
        if (!customActionDefinition) {
            return undefined;
        }

        const customActions = this.customActionParser.parse(customActionDefinition);
        if (!customActions) {
            return undefined;
        }

        return customActions;
    }
}
