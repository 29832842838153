import { IAuthorizationInformation } from './IAuthorizationInformation';
import { IAuthorizationFactory } from './IAuthorizationFactory';
import { injectable } from 'inversify';
import { IAuthorization } from '@quino/core';

@injectable()
export class AuthorizationFactory implements IAuthorizationFactory {
    public fromAuthorizationInformation(
        authInformation: IAuthorizationInformation
    ): IAuthorization {
        return {
            canCreate: () => !!authInformation.allowCreate,
            canRead: () => !!authInformation.allowRead,
            canUpdate: () => !!authInformation.allowUpdate,
            canDelete: () => !!authInformation.allowDelete,
        };
    }
}
