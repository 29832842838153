import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
    IQuinoComponentProps,
    ObjectBookmark,
    QuinoLabeled,
    QuinoTextBox,
    useMetadata,
    useRerender,
    useValidation,
} from '@quino/ui';
import { SelectBox } from 'devextreme-react/select-box';
import { getAspectOrDefault, getElementPath, getMetaProperty } from '@quino/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { IZipPlaceAspect, ZipPlaceAspectIdentifier } from '../../meta/aspects/IZipPlaceAspect';
import dxSelectBox from 'devextreme/ui/select_box';
import { useTranslation } from '../../lang/useTranslation';

interface IZipPlace {
    postalCode: string;
    placeName: string;
    cantonCode: string;
    countryCode: string;
}

enum FieldType {
    zip,
    place,
    canton,
}

export const ZipPlaceLookup: React.FC<IQuinoComponentProps<ObjectBookmark>> = (props) => {
    const { element, bookmark, actions } = props;
    const metaProperty = getMetaProperty(element);
    const { description, name, caption } = metaProperty;
    const { readOnly, required, enabled } = useMetadata(metaProperty, bookmark.genericObject);
    const elementPath = getElementPath(metaProperty);

    const [internalValue, setInternalValue] = useState<any>();
    const [initialBookmarkValue, setInitialBookmarkValue] = useState<any>();
    const [currentBookmarkValue, setCurrentBookmarkValue] = useState<any>();

    const [searchValue, setSearchValue] = useState<string>();
    const [postalCodes, setPostalCodes] = useState<any[]>([]);
    const [zipPlace, setZipPlace] = useState<IZipPlace>();
    const [selectBox, setSelectBox] = useState<dxSelectBox>();
    const [isValid, errorMessages] = useValidation(bookmark, name);

    const rerender = useRerender();

    const baseUrl = 'https://secure.geonames.net/postalCodeSearchJSON';
    const username = 'performassl';
    const country = 'CH';
    const startsWithSearch = true;
    const noDataText = useTranslation('literal.CustomLiterals.ZipPlaceLookup.NoDataText');

    const searchValueIsNumber = searchValue && /^\d+$/.test(searchValue);

    const timerId = useRef<number | undefined>(undefined);

    const zipPlaceAspect = getAspectOrDefault<IZipPlaceAspect>(element, ZipPlaceAspectIdentifier);
    let fieldType: FieldType = FieldType.canton;
    if (zipPlaceAspect) {
        if (zipPlaceAspect.cityfield && zipPlaceAspect.zipfield) {
            fieldType = FieldType.canton;
        } else if (zipPlaceAspect.cityfield) {
            fieldType = FieldType.zip;
        } else if (zipPlaceAspect.zipfield) {
            fieldType = FieldType.place;
        }
    }

    useEffect(
        () => {
            if (zipPlace) {
                if (zipPlaceAspect) {
                    if (fieldType === FieldType.zip && zipPlaceAspect.cityfield) {
                        setCurrentBookmarkValue(zipPlace.postalCode);
                        bookmark.updateFieldValue(zipPlaceAspect.cityfield, zipPlace.placeName);
                    } else if (fieldType === FieldType.place && zipPlaceAspect.zipfield) {
                        setCurrentBookmarkValue(zipPlace.placeName);
                        bookmark.updateFieldValue(zipPlaceAspect.zipfield, zipPlace.postalCode);
                    }

                    if (zipPlaceAspect.cantonfield) {
                        bookmark.updateFieldValue(zipPlaceAspect.cantonfield, zipPlace.cantonCode);
                    }
                }
            }
        },
        [zipPlace]
    );

    useEffect(
        () => {
            const newValue =
                bookmark.genericObject[elementPath] != null
                    ? bookmark.genericObject[elementPath]
                    : undefined;

            setInitialBookmarkValue(newValue);
            setCurrentBookmarkValue(newValue);
            setInternalValue(newValue);
        },
        [bookmark.genericObject]
    );

    useEffect(
        () => {
            const hasInternalChanges =
                !(internalValue == null && initialBookmarkValue == null) &&
                internalValue !== initialBookmarkValue;

            bookmark.setHasInternalChanges(hasInternalChanges);
        },
        [internalValue]
    );

    useEffect(
        () => {
            const symbol = bookmark.subscribe((event) => {
                if (event.type === 'changed') {
                    setInternalValue(event.genericObject[elementPath]);
                }
            });

            return () => bookmark.unsubscribe(symbol);
        },
        [bookmark]
    );

    useEffect(
        () => {
            if (currentBookmarkValue !== undefined) {
                bookmark.updateFieldValue(elementPath, currentBookmarkValue);

                if (bookmark.fieldErrors.get(elementPath) != null) {
                    actions.validate(bookmark).then(rerender);
                }
            }
        },
        [currentBookmarkValue]
    );

    const dataSource = new DataSource({
        store: new CustomStore({
            key: ['postalcode', 'placename', 'adminCode1', 'countrycode'],
            loadMode: 'raw',
            cacheRawData: true,

            load: () => {
                return searchValue && searchValue.length > 0
                    ? fetch(
                          `${baseUrl}?${
                              searchValueIsNumber
                                  ? startsWithSearch
                                      ? 'postalcode_startsWith'
                                      : 'postalcode'
                                  : startsWithSearch
                                      ? 'placename_startsWith'
                                      : 'placename'
                          }=${searchValue}&country=${country}&username=${username}`
                      )
                          .then(async (response: Response) => {
                              setSearchValue(undefined);
                              const result = await response.json();
                              setPostalCodes(result.postalCodes);
                              selectBox && selectBox.open();
                              return result.postalCodes;
                          })
                          .catch(() => setSearchValue(undefined))
                    : postalCodes;
            },
        }),
    });

    const triggerDeferredSearch = (value: any) => {
        clearTimeout(timerId.current);
        timerId.current = window.setTimeout(setSearchValue, 400, value);
    };

    if (fieldType === FieldType.canton) {
        return <QuinoTextBox key={`canton_field_with_value_${internalValue}`} {...props} />;
    } else {
        return (
            <QuinoLabeled
                label={caption}
                required={required}
                hint={description}
                errorMessages={errorMessages}
            >
                <SelectBox
                    acceptCustomValue={true}
                    showDropDownButton={false}
                    showClearButton={true}
                    searchEnabled={false}
                    value={internalValue}
                    isValid={isValid}
                    dataSource={dataSource}
                    disabled={!enabled}
                    readOnly={readOnly}
                    focusStateEnabled={!readOnly}
                    valueChangeEvent={'input blur'}
                    noDataText={noDataText}
                    placeholder={''}
                    onInitialized={(e) => setSelectBox(e.component)}
                    displayExpr={(item: any) => {
                        let returnValue = !internalValue ? '' : `${internalValue}`;
                        if (item && zipPlaceAspect) {
                            if (item.postalCode && fieldType === FieldType.zip) {
                                returnValue = `${item.postalCode}`;
                            } else if (item.placeName && fieldType === FieldType.place) {
                                returnValue = `${item.placeName}`;
                            }
                        }

                        return returnValue;
                    }}
                    onValueChanged={(event) => {
                        setInternalValue(event.value);
                        triggerDeferredSearch(event.value);
                    }}
                    onFocusIn={() =>
                        internalValue &&
                        internalValue.length > 0 &&
                        (!postalCodes || postalCodes.length < 1) &&
                        triggerDeferredSearch(internalValue)
                    }
                    onFocusOut={() => setCurrentBookmarkValue(internalValue)}
                    itemTemplate={(itemData: any) =>
                        itemData &&
                        `${itemData.postalCode} ${itemData.placeName}, ${itemData.adminCode1} (${
                            itemData.countryCode
                        })`
                    }
                    onItemClick={(e) => {
                        e.itemData &&
                            setZipPlace({
                                postalCode: e.itemData.postalCode,
                                placeName: e.itemData.placeName,
                                cantonCode: e.itemData.adminCode1,
                                countryCode: e.itemData.countryCode,
                            });
                        selectBox && selectBox.blur();
                    }}
                />
            </QuinoLabeled>
        );
    }
};
