import React, { useContext } from 'react';
import { DataBrowserContext } from './DataBrowserContext';
import { useService } from '../../ioc/hook/useService';
import { IRouteHelper } from '../../navigation/IRouteHelper';
import { ILocationService } from '../../navigation/ILocationService';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { SelectedView } from './DataBrowserReducer';
import { useTranslation } from '../../lang/useTranslation';
import { useHistory } from 'react-router';
import { SiteMapContext } from '../SiteMapContext/SiteMapContext';
import { CrmContext } from '../../contexts/crmContext/CrmContext';
import { useI18n } from '../../lang/useI18n';
import { IDataService, IGenericObject, QuinoCoreServiceSymbols } from '@quino/core';

const style = require('./Breadcrumbs.less');

interface IBreadcrumbEntry {
    caption?: string;
    path?: string;
}

export const Breadcrumbs = () => {
    const routeHelper = useService<IRouteHelper>(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER);
    const locationService = useService<ILocationService>(
        SHARED_SERVICE_IDENTIFIER.ILOCATIONSERVICE
    );
    const dataService = useService<IDataService>(QuinoCoreServiceSymbols.IDataService);

    const history = useHistory();
    const i18n = useI18n();

    const { state, actions } = useContext(DataBrowserContext);
    const { activeCrmContext } = useContext(CrmContext);
    const { siteMap } = useContext(SiteMapContext);

    const breadcrumbSeparator = ' > ';
    const rootCaption = useTranslation('literal.CustomLiterals.Application.Title');

    const root = { caption: rootCaption, path: routeHelper.getStartUrl() };
    const list = {
        ...state.list,
        path: state.selectedView === SelectedView.List ? undefined : state.list.path,
    };
    const detail = state.selectedView !== SelectedView.List ? state.detail : undefined;
    const subDetails = state.selectedView === SelectedView.SubDetail ? state.subDetails : undefined;

    const createLink = ({ path, caption }: IBreadcrumbEntry) => {
        if (!path) {
            return caption;
        }

        const onClick = async (e: any) => {
            e.stopPropagation();
            await locationService.navigateToUrl(history, path);
        };

        return <a onClick={onClick}>{caption}</a>;
    };

    if ((!detail || !detail.caption) && (!list || !list.caption)) {
        const entityHistoryString = routeHelper.getParamsHistoryFromUrl();
        if (entityHistoryString.length < 2) {
            return <div className={style.breadcrumbs} />;
        }

        // list
        const siteMapPath = entityHistoryString[0];
        siteMap.nodes.forEach((node) => {
            node.items.forEach((item) => {
                if (item.path === siteMapPath) {
                    item && actions.setListCaption(i18n.t(item.translationKey as any));
                }
            });
        });
        activeCrmContext &&
            actions.setListPath(routeHelper.getUrl(activeCrmContext.context, siteMapPath));

        // detail
        const detailPrimaryKey = entityHistoryString[1];
        dataService
            .getObjectAsync<IGenericObject>(siteMapPath, detailPrimaryKey, null)
            .then((genericObject: IGenericObject) => {
                actions.setDetailCaption(genericObject.title);
            });

        activeCrmContext &&
            actions.setDetailPath(
                routeHelper.getUrl(activeCrmContext.context, siteMapPath, detailPrimaryKey)
            );

        // sub details
        const subDetails = entityHistoryString.slice(2, entityHistoryString.length);
        activeCrmContext &&
            subDetails.forEach((subDetailPrimaryKey) => {
                const subDetailEntity = subDetailPrimaryKey.split('id=')[0];
                dataService
                    .getObjectAsync<IGenericObject>(subDetailEntity, subDetailPrimaryKey, null)
                    .then((genericObject: IGenericObject) => {
                        actions.addSubDetailEntry({
                            caption: genericObject.title,
                            path: routeHelper.getUrl(
                                activeCrmContext.context,
                                genericObject.metaClass,
                                genericObject.primaryKey
                            ),
                        });
                    });
            });
    }

    return (
        <div className={style.breadcrumbs}>
            {createLink(root)}
            {breadcrumbSeparator}
            {createLink(list)}
            {detail && breadcrumbSeparator}
            {detail &&
                createLink({
                    path: state.selectedView === SelectedView.SubDetail ? detail.path : undefined,
                    caption: detail.caption,
                })}
            {subDetails &&
                subDetails.map((entry, index) => (
                    <>
                        {breadcrumbSeparator}
                        {createLink({
                            caption: entry.caption,
                            path: index < subDetails.length - 1 ? entry.path : undefined,
                        })}
                    </>
                ))}
        </div>
    );
};
