import { IRequestDecorator } from '@quino/core';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { ISessionService } from '../session/ISessionService';

@injectable()
export class LanguageRequestDecorator implements IRequestDecorator {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE) private sessionService: ISessionService
    ) {}

    decorate = (request: Request): void => {
        const language = this.sessionService.getSessionState().locale;

        if (language) {
            request.headers.set('ACCEPT-LANGUAGE', language);
        }
    };
}
