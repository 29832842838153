import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { TPerformaStore } from '../../redux/store';
import { apiActions } from '../../redux/api/apiStore';
import { IPreparedRequest, IResponseHandler } from '@quino/core';
import { isPerformaException } from '../../api/DTOs/IPerformaExceptionDTO';
import { PerformaServerError } from '../../api/PerformaServerError';
import {
    isHttpBadRequestObjectResult,
    PerformaBadRequestClientError,
} from '../../api/PerformaBadRequestClientError';

@injectable()
export class FailedRequestHandler implements IResponseHandler {
    constructor(@inject(SHARED_SERVICE_IDENTIFIER.IPERFORMASTORE) private store: TPerformaStore) {}

    async handleResponse(request: IPreparedRequest, response: Response): Promise<Response> {
        if (!response.ok) {
            this.store.dispatch(apiActions.requestHealthCheck());

            let responseBodyAsJson: any;
            try {
                responseBodyAsJson = await response.json();
            } catch (e) {
                throw new Error(`Unhandled Server Error (${response.status})`);
            }

            if (isPerformaException(responseBodyAsJson)) {
                throw new PerformaServerError(responseBodyAsJson);
            }

            if (isHttpBadRequestObjectResult(responseBodyAsJson, response.status)) {
                throw new PerformaBadRequestClientError(responseBodyAsJson);
            }

            throw new Error(`${response.statusText}: ${responseBodyAsJson}`);
        }

        return response;
    }
}
