import { IPerformaServer } from '../api';
import { IAuthorizationFactory } from './IAuthorizationFactory';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IAuthorization } from '@quino/core';
import { IPerformaAuthorizationService } from './IPerformaAuthorisationService';

type AuthMap = { [entityName: string]: IAuthorization };

@injectable()
export class PerformaAuthorizationService implements IPerformaAuthorizationService {
    private authorizations: AuthMap;

    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMASERVER) private server: IPerformaServer,
        @inject(SHARED_SERVICE_IDENTIFIER.IAUTHORIZATIONFACTORY)
        private authFactory: IAuthorizationFactory
    ) {
        this.authorizations = {};
    }

    async reload() {
        const authorizations = {};
        const response = await this.server.getAuthorizationAsync();
        response.forEach(
            (auth) =>
                (authorizations[
                    auth.name.toLowerCase()
                ] = this.authFactory.fromAuthorizationInformation(auth))
        );

        this.authorizations = authorizations;
    }

    getAuthorization(entityName: string): IAuthorization {
        const defaultAuth = {
            canCreate: () => false,
            canRead: () => false,
            canUpdate: () => false,
        };
        const entityAuth = this.authorizations[entityName.toLowerCase()] || {};

        return {
            ...defaultAuth,
            ...entityAuth,
        };
    }
}
