import { IMetaAspect } from '@quino/core';

/**
 * The identifier for retrieving a custom query dropdown aspect.
 */
export const CustomQueryDropDownAspectIdentifier = 'ControlParameters';

/**
 * custom query aspect which contains the querykey and semicolon separated parameters
 */
export interface ICustomQueryDropDownAspect extends IMetaAspect {
    querykey: string;
    parameters: string;
}
