import { inject, injectable } from 'inversify';
import { IQuinoDataGridFilterService } from '@quino/ui';
import {
    DataType,
    getElementPath,
    IFormatStringService,
    IMetaProperty,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import isDate from 'lodash/isDate';
import { formatDate } from 'devextreme/localization';
import { CrmContextSearchService } from '../Components/CrmContext/CrmContextSearchService';

@injectable()
export class PerformaDataGridFilterService implements IQuinoDataGridFilterService {
    constructor(
        @inject(QuinoCoreServiceSymbols.IFormatStringService)
        private dateFormatter: IFormatStringService
    ) {}

    calculateFilterExpression = (
        filterValue: any,
        selectedFilterOperation: string,
        target: 'filterRow' | 'headerFilter' | 'filterBuilder' | 'search',
        metaProperty: IMetaProperty,
        column: any
    ) => {
        if (target === 'search' || target === 'filterRow') {
            const format = this.dateFormatter.get(metaProperty);
            const formatToComparisonDate = (dateISOString: string | Date) =>
                formatDate(new Date(dateISOString), format!);

            const newFilterValue = isDate(filterValue)
                ? formatToComparisonDate(filterValue)
                : filterValue;

            return [
                [
                    (data: any) =>
                        PerformaDataGridFilterService.normalize(data[getElementPath(metaProperty)]),
                    'contains',
                    PerformaDataGridFilterService.normalize(filterValue),
                ],
                'or',
                [
                    (data: any) => formatToComparisonDate(data[getElementPath(metaProperty)]),
                    'contains',
                    newFilterValue,
                ],
            ];
        }

        if (target === 'headerFilter' && [DataType.Relation].includes(metaProperty.dataType)) {
            return [
                [
                    (data: any) => data[getElementPath(metaProperty)],
                    selectedFilterOperation,
                    filterValue,
                ],
            ];
        }

        return column.defaultCalculateFilterExpression.apply(column, [
            filterValue,
            selectedFilterOperation,
            target,
        ]);
    };

    static normalize(value: any): any {
        if (value && typeof value === 'string') {
            return CrmContextSearchService.normalize(value);
        }
        return value;
    }
}
