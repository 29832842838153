import React from 'react';
import { ProfileForm } from './ProfileForm';
import { IProfileFormState } from './IProfileFormState';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { lazyInject, QuinoCoreServiceSymbols } from '@quino/core';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { IProfileService } from '../../api/IProfileService';
import { Translation } from '@quino/ui';
import { tKey } from '../../lang/TranslationKeys';
import { ErrorMessage } from '../Util/ErrorMessage';
import { IPerformaI18n } from '../../lang/IPerformaI18n';

interface IProps {}

interface IState {
    loadError: boolean;
    initialFormState?: Partial<IProfileFormState>;
}

export class ProfilePage extends React.Component<IProps, IState> {
    @lazyInject(SHARED_SERVICE_IDENTIFIER.IPROFILESERVICE)
    private profileService: IProfileService;
    @lazyInject(QuinoCoreServiceSymbols.II18N)
    private i18n: IPerformaI18n;

    constructor(props: IProps) {
        super(props);

        this.state = { loadError: false };
    }

    async componentDidMount() {
        try {
            const profileData = await this.profileService.getProfileInformationAsync();
            this.setState({
                initialFormState: {
                    firstName: profileData.firstName,
                    lastName: profileData.lastName,
                    emailAddress: profileData.mailAddress,
                    showFieldDescriptions: profileData.showFieldDescriptions,
                    languageCode: profileData.languageCode,
                },
            });
        } catch (error) {
            this.setState({ loadError: true });
        }
    }

    private submitAsync = async (state: IProfileFormState) => {
        await this.profileService.updateProfileInformationAsync({
            firstName: state.firstName,
            lastName: state.lastName,
            mailAddress: state.emailAddress,
            showFieldDescriptions: state.showFieldDescriptions,
            languageCode: state.languageCode,
        });

        if (
            state.languageCode !== this.state.initialFormState!.languageCode ||
            state.showFieldDescriptions !== this.state.initialFormState!.showFieldDescriptions
        ) {
            window.location.reload();
        }
    };

    render() {
        const { initialFormState, loadError } = this.state;

        const onSuccess = () => undefined;

        if (loadError) {
            return (
                <ErrorMessage>
                    <Translation i18nKey={tKey('literal.CustomLiterals.Profile.LoadError')} />
                </ErrorMessage>
            );
        }

        if (!initialFormState) {
            return <LoadIndicator height={32} width={32} />;
        }

        return (
            <ProfileForm
                title={this.i18n.t(tKey('literal.CustomLiterals.Profile.Title'))}
                showSaveButtonIcon={true}
                showLeadInText={false}
                showLeadOutText={false}
                allowSetPassword={false}
                showDiscardButton={true}
                initialState={initialFormState}
                submitAsync={this.submitAsync}
                onSuccess={onSuccess}
                allowChangeEmail={false}
                allowChangeFieldDescription={true}
            />
        );
    }
}
