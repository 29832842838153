import { ISessionService } from './ISessionService';
import { IPerformaSessionState } from './IPerformaSessionState';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { TPerformaStore } from '../redux/store';
import { getSessionState, sessionActions } from './sessionStore';

@injectable()
export class SessionService implements ISessionService {
    constructor(@inject(SHARED_SERVICE_IDENTIFIER.IPERFORMASTORE) private store: TPerformaStore) {}

    getSessionState(): IPerformaSessionState {
        return getSessionState(this.store.getState());
    }

    updateSessionState(state: Partial<IPerformaSessionState>): void {
        this.store.dispatch(sessionActions.updateSessionState(state));
    }
}
