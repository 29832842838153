import React from 'react';
import { Route, Switch } from 'react-router';
import { LoginForm } from '../Auth';
import { PasswordReset } from '../Auth/PasswordReset/PasswordReset';
import { ForgotPassword } from '../Auth/ForgotPassword/ForgotPassword';
import { FloatingVersionInfo } from '../FloatingVersionInfo';
import { withAppConfig } from '../../contexts/appConfigContext/withAppConfig';
import { IAppConfig } from '../../contexts/appConfigContext/IAppConfig';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { IRouteHelper } from '../../navigation/IRouteHelper';
import { lazyInject } from '@quino/core';
import { ConfirmEmail } from '../Auth/ConfirmEmail/ConfirmEmail';
import { IRouteExtensions } from '../../navigation/IRouteExtensions';
import { UserRegistration } from '../Auth/UserRegistration/UserRegistration';

interface IProps extends IAppConfig {}

const styles: TStyles = require('./LoggedOutPageRoot.less');

type TStyles = {
    scrollPane: string;
};

export class LoggedOutPageRootComponent extends React.Component<IProps> {
    @lazyInject(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER)
    private routeHelper: IRouteHelper;
    @lazyInject(SHARED_SERVICE_IDENTIFIER.IROUTEEXTENSIONS)
    private routeExtensions: IRouteExtensions;

    render() {
        const { loggedOutPageDecorator } = this.props;

        const Decorator = loggedOutPageDecorator || React.Fragment;

        return (
            <div className={styles.scrollPane}>
                <Decorator>
                    {!loggedOutPageDecorator && <FloatingVersionInfo />}
                    <Switch>
                        <Route
                            path={this.routeHelper.getRoutePathForPasswordReset()}
                            component={PasswordReset}
                        />
                        <Route
                            path={this.routeHelper.getRoutePathForForgotPassword()}
                            component={ForgotPassword}
                        />
                        <Route
                            path={this.routeHelper.getRoutePathForConfirmEmail()}
                            component={ConfirmEmail}
                        />
                        <Route
                            path={this.routeHelper.getRegisterUserUrl()}
                            component={UserRegistration}
                        />

                        {this.routeExtensions.getExtensions().map((routeProps, index) => (
                            <Route key={`RouteExtension_${index}`} {...routeProps} />
                        ))}

                        <Route component={LoginForm} />
                    </Switch>
                </Decorator>
            </div>
        );
    }
}

export const LoggedOutPageRoot = withAppConfig(LoggedOutPageRootComponent);
