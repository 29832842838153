import { ILocationService } from './ILocationService';
import * as H from 'history';
import { inject, injectable } from 'inversify';
import { IBookmarkTrackChangesService, QuinoUIServiceSymbols } from '@quino/ui';

@injectable()
export class LocationService implements ILocationService {
    constructor(
        @inject(QuinoUIServiceSymbols.IBookmarkTrackChangesService)
        private bookmarkTrackChangesService: IBookmarkTrackChangesService
    ) {}

    navigateToUrl = async (history: H.History, url: string): Promise<void> => {
        if (await this.canChangeLocation()) {
            history.push(url);
        }
    };

    performAction = async (action: () => void): Promise<void> => {
        if (await this.canChangeLocation()) {
            action();
        }
    };

    canChangeLocation = async (): Promise<boolean> => {
        const canChangeLocation = !(await this.bookmarkTrackChangesService.pendingChanges());
        return canChangeLocation;
    };
}
