import { EntityActionEmphasis } from './EntityActionEmphasis';

export class EntityActionStyleHelper {
    static extractStyleChars = (entityName: string) => {
        const regExp = /[+\-#!]*$/;
        const match = regExp.exec(entityName);
        return match![0];
    };

    public static removeStyle = (entityName: string): string => {
        const styleChars = EntityActionStyleHelper.extractStyleChars(entityName);
        return entityName.substring(0, entityName.length - styleChars.length);
    };

    public static extractStyle = (entityName: string): EntityActionEmphasis[] => {
        const styleChars = EntityActionStyleHelper.extractStyleChars(entityName);

        const entityActionEmphasis: EntityActionEmphasis[] = [];

        for (let i = 0; i < styleChars.length; i++) {
            const style = styleChars.charAt(i);
            entityActionEmphasis.push(style as EntityActionEmphasis);
        }

        if (
            entityActionEmphasis.length === 0 ||
            (entityActionEmphasis.length === 1 &&
                [EntityActionEmphasis.Disabled, EntityActionEmphasis.Enabled].includes(
                    entityActionEmphasis[0]
                ))
        ) {
            entityActionEmphasis.push(EntityActionEmphasis.Medium);
        }

        return entityActionEmphasis;
    };
}
