import { IPerformaExceptionDTO } from './DTOs/IPerformaExceptionDTO';
import { PerformaBadRequestClientError } from './PerformaBadRequestClientError';

export class PerformaServerError extends Error {
    constructor(exception: IPerformaExceptionDTO) {
        super();
        this.name = 'PerformaServerError';
        this.message = exception.title;
    }
}

export function isPerformaServerError(object: any): object is PerformaServerError {
    return (
        object != null &&
        (object as PerformaBadRequestClientError).name !== undefined &&
        (object as PerformaBadRequestClientError).message !== undefined
    );
}
