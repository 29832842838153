import { ISiteMap } from './ISiteMap';
import { ISiteMapItem } from './ISiteMapItem';
import { ISiteMapNode } from './ISiteMapNode';

export const getSiteMapItem = (
    siteMap: ISiteMap,
    pathToFind?: string
): ISiteMapItem | undefined => {
    if (!pathToFind) {
        return undefined;
    }

    const flattenSiteMapItems = (result: ISiteMapItem[], element: ISiteMapNode) =>
        result.concat(element.items);

    const isMatching = (pathToFind: string) => (element: ISiteMapItem) => {
        return pathToFind === element.path;
    };

    return siteMap.nodes
        .reduce<ISiteMapItem[]>(flattenSiteMapItems, [])
        .find(isMatching(pathToFind));
};
