import React, { useContext } from 'react';
import { AuthorizationContext } from '../../../../contexts/authorizationContext/AuthorizationContext';
import { SitemapMenuItem } from './SitemapMenuItem';
import { ILocationService, IRouteHelper, ISiteMapItem, ISiteMapNode } from '../../../../navigation';
import { useHistory } from 'react-router';
import { SHARED_SERVICE_IDENTIFIER } from '../../../../ioc';
import { useService } from '../../../../ioc/hook/useService';
import { CrmContext } from '../../../../contexts/crmContext/CrmContext';
import { MenuSubheader } from './MenuSubheader';
import { useI18n } from '../../../../lang/useI18n';

type TStyles = {
    navList: string;
};

const styles: TStyles = require('./SiteMapNode.less');

interface IProps {
    node: ISiteMapNode;
    isWideMode: boolean;
}

export const SiteMapNode: React.FC<IProps> = (props) => {
    const authorization = useContext(AuthorizationContext);
    const crmContext = useContext(CrmContext);
    const history = useHistory();

    const routeHelper = useService<IRouteHelper>(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER);
    const locationService = useService<ILocationService>(
        SHARED_SERVICE_IDENTIFIER.ILOCATIONSERVICE
    );
    const i18n = useI18n();

    const handleMenuItemClick = async (item: ISiteMapItem) => {
        const { activeCrmContext } = crmContext;
        if (activeCrmContext) {
            const url = routeHelper.getUrl(activeCrmContext.context, item.path);
            await locationService.navigateToUrl(history, url);
        }
    };

    const isMenuItemVisible = (siteMapItem: ISiteMapItem): boolean => {
        return (
            !siteMapItem.isVisible ||
            siteMapItem.isVisible(siteMapItem, authorization, crmContext.activeCrmContext)
        );
    };

    const renderSubMenuHeader = () => {
        return (
            <MenuSubheader
                caption={props.node.translationKey ? i18n.t(props.node.translationKey as any) : ''}
                isWideMode={props.isWideMode}
            />
        );
    };

    return (
        <div className={styles.navList}>
            {renderSubMenuHeader()}
            {props.node.items.map(
                (siteMapItem, index: number) =>
                    isMenuItemVisible(siteMapItem) && (
                        <SitemapMenuItem
                            key={index}
                            siteMapItem={siteMapItem}
                            isWideMode={props.isWideMode}
                            onClick={handleMenuItemClick}
                        />
                    )
            )}
        </div>
    );
};
