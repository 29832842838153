import React, { Dispatch, SetStateAction } from 'react';
import { IProfileContext } from './IProfileContext';
import { FieldDescriptionVisibility } from '../../Components/Profile/IProfileFormState';

export const DefaultProfileContext: IProfileContext = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    showFieldDescriptions: FieldDescriptionVisibility.Always,
};

export const ProfileContext = React.createContext<
    [IProfileContext, Dispatch<SetStateAction<IProfileContext>> | undefined]
>([DefaultProfileContext, undefined]);
