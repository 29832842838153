import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
    IQuinoComponentProps,
    ObjectBookmark,
    QuinoLabeled,
    useLabelSettings,
    useMetadata,
    useMetaPropertyValueState,
    useOnBookmarkAnyEvent,
    useRerender,
    useService,
    useValidation,
} from '@quino/ui';
import {
    getAspectOrDefault,
    getElementPath,
    getMetaProperty,
    IRequestBuilder,
    ISearchModeAspect,
    QuinoCoreServiceSymbols,
    SearchModeAspectIdentifier,
} from '@quino/core';
import { SelectBox } from 'devextreme-react/select-box';
import {
    CustomQueryDropDownAspectIdentifier,
    ICustomQueryDropDownAspect,
} from '../../meta/aspects/ICustomQueryDropDownAspect';
import { IPerformaUrlManager } from '../../api/IPerformaUrlManager';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import toPairs from 'lodash/toPairs';

interface ICustomQueryListObject {
    title: string;
    primaryKey: string;
}

export function CustomQueryDropDown(
    props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>
) {
    const { bookmark, actions } = props;
    const metaProperty = getMetaProperty(props.element);
    const { description, name, caption } = metaProperty;
    const labelSettings = useLabelSettings(props.element);
    const { readOnly, required, enabled } = useMetadata(metaProperty, bookmark.genericObject);
    const elementPath = getElementPath(metaProperty, true);
    const [isValid, errorMessages] = useValidation(bookmark, name);
    const [customQueryValueList, setCustomQueryValueList] = useState<ICustomQueryListObject[]>();
    const [customQueryValueListParams, setCustomQueryValueListParams] = useState<object>({});
    const [value, setValue] = useMetaPropertyValueState<any>(metaProperty, bookmark);
    const rerender = useRerender();
    const urlManager = useService<IPerformaUrlManager>(
        SHARED_SERVICE_IDENTIFIER.IPERFORMAURLMANAGER
    );
    const requestBuilder = useService<IRequestBuilder>(QuinoCoreServiceSymbols.IRequestBuilder);

    const searchModeAspect = getAspectOrDefault<ISearchModeAspect>(
        metaProperty,
        SearchModeAspectIdentifier
    );

    const customQueryDropDownAspect = getAspectOrDefault<ICustomQueryDropDownAspect>(
        metaProperty,
        CustomQueryDropDownAspectIdentifier
    );

    const updateCustomQueryValueList = async () => {
        if (!customQueryDropDownAspect || readOnly || !enabled) {
            return;
        }
        const { querykey, parameters } = customQueryDropDownAspect;
        const params = parameters.split(';');
        const paramsObject: object = {};
        params.forEach((param) => {
            paramsObject[`${param}`] = bookmark.genericObject[param];
        });
        const url = urlManager.getCustomQueryListUrl(querykey, paramsObject);
        setCustomQueryValueList(
            await requestBuilder
                .create(url, 'get')
                .requiresAuthentication()
                .fetchJson<ICustomQueryListObject[]>()
        );
        setCustomQueryValueListParams(paramsObject);
    };

    useOnBookmarkAnyEvent(bookmark, () => {
        const hasChanges =
            toPairs(customQueryValueListParams).filter(([key, value]) => {
                return bookmark.genericObject[key] !== value;
            }).length > 0;
        hasChanges && updateCustomQueryValueList();
    });

    useEffect(() => {
        (async () => await updateCustomQueryValueList())();
    }, []);

    useEffect(
        () => {
            if (bookmark.fieldErrors.get(elementPath) != null) {
                actions.validate(bookmark).then(rerender);
            }
        },
        [value]
    );

    useEffect(
        () => {
            if (!customQueryValueList) {
                return;
            }
            const valueListContainsValue =
                customQueryValueList.filter((optionValue) => optionValue.primaryKey === value)
                    .length > 0;
            if (!valueListContainsValue) {
                setValue(undefined);
            }
        },
        [customQueryValueList]
    );

    return (
        <QuinoLabeled
            label={caption}
            required={required}
            hint={description}
            errorMessages={errorMessages}
        >
            <SelectBox
                searchEnabled={true}
                searchMode={
                    searchModeAspect && searchModeAspect.useContains ? 'contains' : 'startswith'
                }
                readOnly={readOnly != null ? readOnly : false}
                focusStateEnabled={!readOnly}
                disabled={!enabled}
                items={customQueryValueList}
                valueExpr={'primaryKey'}
                displayExpr={'title'}
                placeholder={''}
                value={value}
                hint={labelSettings.showHint ? description : ''}
                onValueChanged={(e) => setValue(e.value)}
                isValid={isValid}
            />
        </QuinoLabeled>
    );
}
