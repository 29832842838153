import React, { useEffect, useState } from 'react';
import { ICrmContext, IPerformaUrlManager } from '../..';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { Avatar } from '../Util/Avatar';
import { useService } from '../../ioc/hook/useService';
import { IImageFetcher } from '../../api/IImageFetcher';

export enum AvatarSize {
    Small,
    Normal,
}

interface IProps {
    avatarSize: AvatarSize;
    crmContext: ICrmContext;
}

export const CrmContextAvatar: React.FC<IProps> = (props) => {
    const { crmContext } = props;

    const urlManager = useService<IPerformaUrlManager>(
        SHARED_SERVICE_IDENTIFIER.IPERFORMAURLMANAGER
    );
    const imageFetcher = useService<IImageFetcher>(SHARED_SERVICE_IDENTIFIER.IIMAGEFETCHER);

    const [objectUrl, setObjectUrl] = useState<string>();

    const src = crmContext.hasImage && urlManager.getContextImageUrl(crmContext.context);

    const loadImage = async () => {
        if (src) {
            setObjectUrl(await imageFetcher.tryLoadObjectUrl(src));
        }
    };

    useEffect(
        () => {
            loadImage();
        },
        [src]
    );

    const label = props.crmContext.tinyCaption;

    const size = props.avatarSize === AvatarSize.Small ? 24 : undefined;

    return <Avatar label={label} src={objectUrl} size={size} />;
};
