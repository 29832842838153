import React, { useContext } from 'react';
import { UserSettingsDialog } from './UserSettingsDialog';
import { UserSettingsContext } from './UserSettingsContext/UserSettingsContext';
import { ConfigurationContext } from '../../contexts/configurationContext/ConfigurationContext';

const Inner: React.FunctionComponent = ({ children }) => {
    const userSettingsContext = useContext(UserSettingsContext);

    if (!!userSettingsContext) {
        const [userSettingsContextState, { reload: reloadUserSettings }] = userSettingsContext;
        if (userSettingsContextState.additionalDataEntryRequired) {
            return <UserSettingsDialog onSuccess={reloadUserSettings} />;
        }
    }
    return <>{children}</>;
};

export const UserSettingsDialogWrapper: React.FunctionComponent = ({ children }) => {
    const configuration = useContext(ConfigurationContext);
    const isSelfRegistrationAllowed = (): boolean => {
        return configuration.getBooleanValueOrDefault(
            'client.Registration.AllowSelfRegistration',
            false
        );
    };

    const isUserSettingsDialogRequired = isSelfRegistrationAllowed();

    if (!isUserSettingsDialogRequired) {
        return <>{children}</>;
    }

    return <Inner children={children} />;
};
