import React from 'react';
import { IAuthorizationContext } from '../../contexts/authorizationContext/IAuthorizationContext';
import { IWithCrmContext } from '../../contexts/crmContext/withCrmContext';
import { withCrmContext } from '../../contexts/crmContext/withCrmContext';
import { lazyInject, QuinoCoreServiceSymbols } from '@quino/core';
import { AuthorizationContext } from '../../contexts/authorizationContext/AuthorizationContext';
import { IPerformaAuthorizationService } from '../../authorization/IPerformaAuthorisationService';

interface IProps extends IWithCrmContext {}

interface IState extends IAuthorizationContext {}

class InnerComponent extends React.Component<IProps, IState> {
    @lazyInject(QuinoCoreServiceSymbols.IAuthorizationService)
    private readonly authorizationService: IPerformaAuthorizationService;

    private oldContextId: string | undefined;

    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: false,
            reloadAsync: this.reload,
            getAuthorization: this.getAuthorization,
        };
        this.oldContextId = this.getActiveContextId();
    }

    async componentDidMount() {
        if (this.props.crmContext.activeCrmContext) {
            await this.reload();
        }
    }

    async componentDidUpdate() {
        const newContextId = this.getActiveContextId();
        if (newContextId && this.oldContextId !== newContextId) {
            await this.reload();
            this.oldContextId = newContextId;
        }
    }

    private getActiveContextId() {
        const { activeCrmContext } = this.props.crmContext;
        return activeCrmContext && activeCrmContext.context.id;
    }

    private reload = async () => {
        this.setState({ isLoading: true });
        await this.authorizationService.reload();
        this.setState({ isLoading: false });
    };

    private getAuthorization = (entityName: string) =>
        this.authorizationService.getAuthorization(entityName);

    render() {
        return (
            <AuthorizationContext.Provider value={this.state}>
                {this.props.children}
            </AuthorizationContext.Provider>
        );
    }
}

export const AuthorizationContextWrapper = withCrmContext(InnerComponent);
