import React, { useContext, useEffect } from 'react';
import { CrmContext } from '../../../contexts/crmContext/CrmContext';
import { Route, RouteComponentProps } from 'react-router';
import { useService } from '../../../ioc/hook/useService';
import { IRouteHelper } from '../../../navigation/IRouteHelper';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { IContextRouteChecker } from '../../../navigation/IContextRouteChecker';

interface IRouterProps {
    crmContext?: string;
}

interface IOwnProps {}

interface IProps extends RouteComponentProps<IRouterProps>, IOwnProps {}

const Inner: React.FunctionComponent<IProps> = (props: IProps) => {
    const routeHelper = useService<IRouteHelper>(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER);
    const crmContext = useContext(CrmContext);
    const { activeCrmContext, availableCrmContexts } = crmContext;

    const contextRouteChecker = useService<IContextRouteChecker>(
        SHARED_SERVICE_IDENTIFIER.ICONTEXTROUTECHECKER
    );

    const setContextFromUrl = () => {
        if (availableCrmContexts.length > 0) {
            contextRouteChecker.changeCrmContextIfNecessaryAsync(
                crmContext,
                props.match.params.crmContext
            );
        }
    };

    const setUrlFromContext = () => {
        const { location, history } = props;
        const { pathname } = location;

        if (activeCrmContext && availableCrmContexts.length > 0) {
            if (
                routeHelper.matchesCrmContext(pathname, activeCrmContext.context) ||
                pathname === routeHelper.getProfileUrl()
            ) {
                return;
            }
            const newUrl = routeHelper.getUrl(activeCrmContext.context);
            history.replace(newUrl);
        }
    };

    useEffect(setContextFromUrl, [availableCrmContexts]);

    useEffect(setUrlFromContext, [activeCrmContext]);

    return null;
};

export const CrmContextUrlAdjuster: React.FunctionComponent = (props: IOwnProps) => {
    const routeHelper = useService<IRouteHelper>(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER);
    return (
        <Route path={routeHelper.getRoutePathWithOptionalCrmContext()}>
            {(routeProps: RouteComponentProps<IRouterProps>) => (
                <Inner {...routeProps} {...props} />
            )}
        </Route>
    );
};
