import * as React from 'react';
import { useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import {
    IQuinoMetaPanelExtensionProps,
    ObjectBookmark,
    useOnBookmarkAnyEvent,
    useRerender,
    useService,
} from '@quino/ui';
import {
    FieldValidationErrorCodes,
    IExpressionEvaluator,
    IFieldError,
    II18n,
    IMetaElement,
    isIMetaGroup,
    isIMetaProperty,
    IValidationResult,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { useWeakValidationMetaClasses } from '../../configuration/hook/useWeakValidationMetaClasses';
import { isPerformaBadRequestClientError } from '../../api/PerformaBadRequestClientError';
import { IUploadErrorRegistration } from '../Form/Upload/IUploadErrorRegistration';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';

export const PerformaMetaPanelSaveButton: React.FunctionComponent<IQuinoMetaPanelExtensionProps> = (
    props
) => {
    const translationService = useService<II18n>(QuinoCoreServiceSymbols.II18N);
    const expressionEvaluator = useService<IExpressionEvaluator>(
        QuinoCoreServiceSymbols.IExpressionEvaluator
    );
    const uploadErrorRegistrationService = useService<IUploadErrorRegistration>(
        SHARED_SERVICE_IDENTIFIER.IUPLOADERRORREGISTRATION
    );

    const [state, dispatch] = useState<{ isSaving: boolean; error?: string }>({ isSaving: false });
    const weakValidationMetaClasses = useWeakValidationMetaClasses();
    const rerender = useRerender();
    useOnBookmarkAnyEvent(props.bookmark, rerender);

    if (props.bookmark instanceof ObjectBookmark) {
        const objectBookmark = props.bookmark;

        const hasInvisibleElement = (
            element: IMetaElement,
            elementName: string,
            context: any
        ): boolean => {
            let anyVisibleElement = false;
            if (isIMetaGroup(element)) {
                element.elements.forEach((e) => {
                    if (isIMetaProperty(e) && !anyVisibleElement) {
                        const visible =
                            e.visible != null &&
                            expressionEvaluator.evaluate<boolean>(e.visible, context);
                        anyVisibleElement = e.name === elementName && !visible;
                    } else if (isIMetaGroup(e) && !anyVisibleElement) {
                        anyVisibleElement = hasInvisibleElement(e, elementName, context);
                    }
                });
            }

            return anyVisibleElement;
        };

        const handleSaveError = (error: any) => {
            if (error) {
                if (isPerformaBadRequestClientError(error)) {
                    for (const key of Object.keys(error.badRequestObjectResult.errors)) {
                        const fieldErrors: IFieldError[] = [];
                        error.badRequestObjectResult.errors[key].forEach((value: string) => {
                            if (Object.keys(objectBookmark.genericObject).includes(key)) {
                                fieldErrors.push({
                                    errorMessage: value,
                                    fieldName: key,
                                    errorCode: `VAL-DYN-${key}`,
                                });
                            } else {
                                objectBookmark.generalErrors.push(value);
                            }
                        });
                        objectBookmark.fieldErrors.set(key, fieldErrors);
                    }
                    props.updateMetaPanel!(objectBookmark);
                    dispatch({ isSaving: false });
                } else {
                    dispatch({ isSaving: false, error: error });
                }
            }
        };

        return (
            <>
                <Button
                    icon={'save'}
                    type={'default'}
                    disabled={state.isSaving || !objectBookmark.hasChanges()}
                    text={translationService.t('literal.CustomLiterals.Detail.SaveButton')}
                    onClick={() => {
                        dispatch({ isSaving: true });

                        let notVisibleFieldErrors: string[] = [];
                        const isAllowedToSave = (result: IValidationResult): boolean => {
                            if (
                                weakValidationMetaClasses.includes(
                                    objectBookmark.genericObject.metaClass
                                )
                            ) {
                                return (
                                    result.fieldErrors !== undefined &&
                                    result.fieldErrors.filter(
                                        (value) =>
                                            value.errorCode ===
                                                FieldValidationErrorCodes.BOUNDS_MIN_VALUE ||
                                            value.errorCode ===
                                                FieldValidationErrorCodes.BOUNDS_MAX_VALUE
                                    ).length === 0
                                );
                            }

                            return (
                                (result.fieldErrors == null || result.fieldErrors.length === 0) &&
                                (result.generalErrors == null || result.generalErrors.length === 0)
                            );
                        };

                        props.actions!.validate(objectBookmark).then((result) => {
                            if (isAllowedToSave(result)) {
                                return props
                                    .actions!.save(
                                        objectBookmark.genericObject,
                                        objectBookmark.originalObject,
                                        { ...props.context, bookmark: objectBookmark }
                                    )
                                    .then((genericObject) => {
                                        objectBookmark.onSaved(genericObject);
                                        props.updateMetaPanel!(objectBookmark);

                                        uploadErrorRegistrationService
                                            .hasUploadErrors()
                                            .then((hasUploadError) => {
                                                if (hasUploadError) {
                                                    props
                                                        .actions!.save(
                                                            objectBookmark.genericObject,
                                                            objectBookmark.originalObject,
                                                            {
                                                                ...props.context,
                                                                bookmark: objectBookmark,
                                                            }
                                                        )
                                                        .then((genericObject) => {
                                                            objectBookmark.onSaved(genericObject);
                                                            props.updateMetaPanel!(objectBookmark);
                                                        });
                                                }
                                            });

                                        dispatch({ isSaving: false });
                                    })
                                    .catch(handleSaveError);
                            } else {
                                if (result.fieldErrors) {
                                    notVisibleFieldErrors = result.fieldErrors
                                        .filter((value) =>
                                            hasInvisibleElement(
                                                objectBookmark.layout,
                                                value.fieldName,
                                                objectBookmark.genericObject
                                            )
                                        )
                                        .map((value) => value.fieldName);
                                }

                                props.updateMetaPanel!(objectBookmark);
                            }

                            return dispatch({
                                isSaving: false,
                                error:
                                    notVisibleFieldErrors.length > 0
                                        ? `Not visible elements with validation error: \n ${JSON.stringify(
                                              notVisibleFieldErrors
                                          )}`
                                        : undefined,
                            });
                        });
                    }}
                />
                <LoadPanel
                    message={translationService.t('literal.CustomLiterals.Detail.Saving')}
                    visible={state.isSaving}
                />
                <Popup
                    visible={state.error != null}
                    showTitle={true}
                    title={'Error'}
                    closeOnOutsideClick={true}
                    showCloseButton={true}
                    width={400}
                    height={250}
                    onHiding={() => dispatch({ ...state, error: undefined })}
                >
                    <span style={{ wordWrap: 'break-word' }}>
                        {state.error != null ? state.error.toString() : ''}
                    </span>
                </Popup>
            </>
        );
    }

    return <div />;
};
