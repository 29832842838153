import React from 'react';

const logo = require('./Logo_PerformaNET_rgb.png');

type TStyles = {
    logoRoot: string;
};
const styles: TStyles = require('./PnetLogo.less');

export const PnetLogo = () => <img src={logo} className={styles.logoRoot} alt="" />;
