import { inject, injectable } from 'inversify';
import { IGenericObject } from '@quino/core';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IConfigurationService } from '../configuration';
import { ICustomActionNamesReader } from './ICustomActionNamesReader';

@injectable()
export class CustomActionNamesReader implements ICustomActionNamesReader {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.ICONFIGURATIONSERVICE)
        private configurationService: IConfigurationService
    ) {}

    readAsync = async (dataObject: IGenericObject): Promise<string | undefined> => {
        const fieldName = await this.getCustomActionsFieldNameAsync();

        if (!fieldName || fieldName === '') {
            return undefined;
        }

        if (!dataObject.hasOwnProperty(fieldName)) {
            return undefined;
        }

        return dataObject[fieldName];
    };

    private async getCustomActionsFieldNameAsync(): Promise<string | undefined> {
        const config = await this.configurationService.getConfigurationAsync();
        return config.getStringValue('client.Forms.CustomActionsFieldName');
    }
}
