import React, { FunctionComponent } from 'react';
import { ISiteMapItem } from '../../navigation';
import { Button } from 'devextreme-react/button';
import { useTranslation } from '../../lang/useTranslation';

interface IProps {
    onNewRecord: (currentSiteMapItem?: ISiteMapItem) => void;
}

export const DataListCreateButton: FunctionComponent<IProps> = ({ onNewRecord }: IProps) => {
    const label = useTranslation('literal.CustomLiterals.DataList.New');

    return (
        <Button
            stylingMode={'contained'}
            type={'default'}
            onClick={() => onNewRecord()}
            icon={'add'}
            text={label}
        />
    );
};
