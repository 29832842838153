import React, { PropsWithChildren } from 'react';
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import { IPopupOptions } from 'devextreme-react/popup';

type TStyles = {
    scrollView: string;
    content: string;
};

const styles: TStyles = require('./ScrollablePopup.less');

export const ScrollablePopup = React.forwardRef<Popup, PropsWithChildren<IPopupOptions>>(
    ({ children, ...popupProps }, ref) => {
        return (
            <Popup {...popupProps} ref={ref !== null ? ref : undefined}>
                <ScrollView className={styles.scrollView}>
                    <div className={styles.content}>{children}</div>
                </ScrollView>
            </Popup>
        );
    }
);
