import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
    IBookmarkFactory,
    IObjectBookmark,
    LoadingPanel,
    QuinoMetaPanel,
    QuinoUIServiceSymbols,
    Translation,
    useOnBookmarkSavedEvent,
    useService,
} from '@quino/ui';
import { tKey } from '../../lang/TranslationKeys';
import { UserSettingsContext } from './UserSettingsContext/UserSettingsContext';
import { IDataService, IGenericObject, QuinoCoreServiceSymbols } from '@quino/core';
import { IPerformaI18n } from '../../lang/IPerformaI18n';
import { PerformaMetaPanelFooter } from '../DataBrowser/PerformaMetaPanelFooter';
import { ICustomFooterButton } from '../DataBrowser/CustomFooterButton';
import { LoginContext } from '../../contexts/loginContext';
import { ErrorMessage } from '../Util/ErrorMessage';

interface IProps {
    onDrillUp?: () => void;
    onSaveSuccess?: () => void;
    showLogoutButton: boolean;
}

export const UserSettingsForm: React.FunctionComponent<IProps> = (props: IProps) => {
    const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
    const dataService = useService<IDataService>(QuinoCoreServiceSymbols.IDataService);

    const [bookmark, setBookmark] = useState<IObjectBookmark>();
    const [hasError, setHasError] = useState(false);
    const userSettingsContext = useContext(UserSettingsContext);

    const i18n = useService<IPerformaI18n>(QuinoCoreServiceSymbols.II18N);

    useEffect(
        () => {
            (async () => {
                if (userSettingsContext) {
                    const [userSettingsContextState] = userSettingsContext;
                    const { entityName, entityObject, layoutName } = userSettingsContextState;

                    try {
                        const genericObject = await dataService.getObjectAsync<IGenericObject>(
                            entityName,
                            entityObject,
                            layoutName
                        );

                        const bookmark = bookmarkFactory.createObject(genericObject);
                        setBookmark(bookmark);
                    } catch (error) {
                        console.error('Failed to fetch user settings', error);
                        setHasError(true);
                    }
                }
            })();
        },
        [userSettingsContext]
    );

    useOnBookmarkSavedEvent(bookmark, props.onSaveSuccess);

    if (hasError) {
        return (
            <ErrorMessage>
                <Translation
                    i18nKey={tKey('literal.CustomLiterals.DataBrowser.ErrorCouldNotLoadData')}
                />
            </ErrorMessage>
        );
    }

    const loginContext = useContext(LoginContext);

    const handleSignOut = () => {
        loginContext.onTryLogoutAsync();
    };

    if (!bookmark) {
        return <LoadingPanel message={i18n.t('literal.CustomLiterals.DataBrowser.LoadingData')} />;
    }

    const footerButtons: ICustomFooterButton[] =
        (props.showLogoutButton && [
            {
                caption: i18n.t('literal.CustomLiterals.UserMenu.SignOut'),
                onClick: handleSignOut,
            },
        ]) ||
        [];

    return (
        <QuinoMetaPanel bookmark={bookmark}>
            <PerformaMetaPanelFooter
                position={'Footer'}
                customButtons={footerButtons}
                context={{ layout: bookmark.layout }}
            />
        </QuinoMetaPanel>
    );
};
