import { getAspectOrDefault, IGenericObject, IMetaLayout } from '@quino/core';
import { ILayoutCatalogManager } from './ILayoutCatalogManager';
import { inject, injectable } from 'inversify';
import { ILayoutLoader } from '../../data/ILayoutLoader';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { LayoutLoader } from '../../data/LayoutLoader';
import { DefaultLayoutMarkerAspectIdentifier } from '../../meta/aspects/IDefaultLayoutMarkerAspect';
import { IConfigurationService } from '../../configuration/IConfigurationService';

@injectable()
export class LayoutCatalogManager implements ILayoutCatalogManager {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.ILAYOUTLOADER) private layoutLoader: ILayoutLoader,
        @inject(SHARED_SERVICE_IDENTIFIER.ICONFIGURATIONSERVICE)
        private configurationService: IConfigurationService
    ) {}

    public getLayoutsAsync = async (entityName: string) => {
        const listLayoutRequest = this.layoutLoader.getLayouts(
            entityName,
            LayoutLoader.LIST_LAYOUT
        );

        const formLayoutRequest = this.layoutLoader.getLayouts(
            entityName,
            LayoutLoader.DETAIL_LAYOUT
        );

        const [listLayouts, formLayouts] = await Promise.all([
            listLayoutRequest,
            formLayoutRequest,
        ]);

        return {
            listLayouts,
            formLayouts,
            drillDownEnabled: formLayouts && formLayouts.length > 0,
        };
    };

    public getDefaultLayout(layouts: IMetaLayout[], defaultName?: string): IMetaLayout {
        const layoutByDefaultName =
            defaultName && layouts.find((layout) => layout.name === defaultName);
        const layoutByDefaultLayoutMarkerAspect = layouts.find(
            (layout) => getAspectOrDefault(layout, DefaultLayoutMarkerAspectIdentifier) !== null
        );
        return layoutByDefaultName || layoutByDefaultLayoutMarkerAspect || layouts[0];
    }

    public async getLayoutForObject(
        object: IGenericObject,
        layouts: IMetaLayout[],
        defaultName?: string
    ): Promise<IMetaLayout> {
        const formSelectionFieldName = await this.configurationService.getStringValueAsync(
            'client.Forms.FormSelectionFieldName'
        );

        const layoutName = formSelectionFieldName && object[formSelectionFieldName];

        const layout = layouts.find((layout) => layout.name === layoutName);

        return layout || this.getDefaultLayout(layouts, defaultName);
    }
}
