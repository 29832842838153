import { inject, injectable } from 'inversify';
import { IImageFetcher } from './IImageFetcher';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IRequestBuilder, QuinoCoreServiceSymbols } from '@quino/core';

type TWindow = {
    URL: {
        createObjectURL(object: any): string;
    };
};

@injectable()
export class ImageFetcher implements IImageFetcher {
    constructor(
        @inject(QuinoCoreServiceSymbols.IRequestBuilder) private requestBuilder: IRequestBuilder,
        @inject(SHARED_SERVICE_IDENTIFIER.WINDOW) private window: TWindow
    ) {}

    async tryLoadObjectUrl(src: string): Promise<string | undefined> {
        const response = await this.requestBuilder
            .create(src, 'get')
            .requiresAuthentication()
            .fetchImage();
        return this.window.URL.createObjectURL(response);
    }
}
