import { inject, injectable } from 'inversify';
import {
    defaultLabelSettings,
    ILabelSettings,
    IQuinoLabelConfigurationService,
    QuinoLabeledLabelPosition,
} from '@quino/ui';
import { FieldDescriptionVisibility } from '../Components/Profile/IProfileFormState';
import { IProfileService } from '../api/IProfileService';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IUserProfileDTO } from '../api/DTOs/IUserProfileDTO';
import { IPerformaAuthService } from '../authentication/IPerformaAuthService';

@injectable()
export class PerformaLabelConfigurationService implements IQuinoLabelConfigurationService {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPROFILESERVICE) private profileService: IProfileService,
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMAAUTHSERVICE)
        private performaAuthService: IPerformaAuthService
    ) {}

    private profileInformation?: IUserProfileDTO;
    private performaDefaultLabelPosition = QuinoLabeledLabelPosition.Top;

    getLabelSettings = async (): Promise<ILabelSettings> => {
        if (this.profileInformation === undefined) {
            if (await this.performaAuthService.isLoggedInAsync()) {
                this.profileInformation = await this.profileService.getProfileInformationAsync();
            } else {
                return { ...defaultLabelSettings, alignment: this.performaDefaultLabelPosition };
            }
        }

        return {
            alignment: this.performaDefaultLabelPosition,
            showBorder: false,
            showHint:
                this.profileInformation.showFieldDescriptions ===
                FieldDescriptionVisibility.OnHover,
            showDescription:
                this.profileInformation.showFieldDescriptions === FieldDescriptionVisibility.Always,
        };
    };
}
