/**
 * The identifier for retrieving a zip playce aspect.
 */
import { IMetaAspect } from '@quino/core';

export const ZipPlaceAspectIdentifier = 'ControlParameters';

/**
 * Aspect containing the matching zip or place field
 */
export interface IZipPlaceAspect extends IMetaAspect {
    zipfield?: string;
    cityfield?: string;
    cantonfield?: string;
}
