import { IApplication, IDefaultTranslations, QuinoCoreServiceSymbols } from '@quino/core';
import { defaultSharedTranslations, ISettings, setupSharedContainer } from 'performa-shared';
import { PerformaNetModule } from './PerformaNetModule';
import { defaultPerformaNetTranslations } from '../lang/TranslationKeys';

if (!process.env.BACKEND_SERVER_URL) {
    throw new Error('BACKEND_SERVER_URL is not configured');
}

let settings: ISettings = {
    backendServerUrl: process.env.BACKEND_SERVER_URL,
};

if (typeof window !== 'undefined') {
    settings = {
        ...settings,
        ...((window as any).clientConfig as ISettings),
    };
}

/**
 * Configure the IoC container for the application including Quino stuff.
 */
export const setUpPnetContainer = async () => {
    const PACKAGE = require('../../package.json');

    await setupSharedContainer(settings, PACKAGE.version, (application: IApplication) => {
        PerformaNetModule.use(application);
        application
            .bind<IDefaultTranslations>(QuinoCoreServiceSymbols.IDefaultTranslations)
            .toConstantValue({
                ...defaultSharedTranslations,
                ...defaultPerformaNetTranslations,
            });
    });
};
