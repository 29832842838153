import { IApplication } from '@quino/core';
import { PERFORMA_CONTROL_IDENTIFIER } from './PerformaControlIdentifier';
import { MarkdownDetail } from '../Components/Form/Markdown/MarkdownDetail';
import { LocalizedLabel } from '../Components/LocalizedLabel/LocalizedLabel';
import { EditableGridEditor, ReadonlyGridEditor } from '../Components/Form/GridEditor/GridEditor';
import { Upload } from '../Components/Form/Upload/Upload';
import { IComponentMappingProvider, QuinoUIServiceSymbols } from '@quino/ui';
import { Placeholder } from '../Components/Placeholder/Placeholder';
import { TabsOrVerticalList } from '../Components/TabsOrVerticalList/TabsOrVerticalList';
import { NoCaptionGroupBox } from '../Components/NoCaptionGroupBox/NoCaptionGroupBox';
import { ZipPlaceLookup } from '../Components/ZipPlaceSearchBox/ZipPlaceLookup';
import { FilteredDropDown } from '../Components/FilteredDropDown/FilteredDropdown';
import { CustomQueryDropDown } from '../Components/CustomQueryDropDown/CustomQueryDropDown';

export class PerformaControlModule {
    static use = (application: IApplication): void => {
        application.registerStartupAction(Symbol.for('performa_control_startup_action'), (app) => {
            const provider = app.get<IComponentMappingProvider>(
                QuinoUIServiceSymbols.IComponentMappingProvider
            );
            provider.register(
                {
                    controlIdentifier: PERFORMA_CONTROL_IDENTIFIER.GRID,
                    component: ReadonlyGridEditor,
                },
                Symbol.for(PERFORMA_CONTROL_IDENTIFIER.GRID)
            );
            provider.register(
                {
                    controlIdentifier: PERFORMA_CONTROL_IDENTIFIER.GRIDEDITOR,
                    component: EditableGridEditor,
                },
                Symbol.for(PERFORMA_CONTROL_IDENTIFIER.GRIDEDITOR)
            );
            provider.register(
                {
                    controlIdentifier: PERFORMA_CONTROL_IDENTIFIER.LOCALIZEDLABEL,
                    component: LocalizedLabel,
                },
                Symbol.for(PERFORMA_CONTROL_IDENTIFIER.LOCALIZEDLABEL)
            );
            provider.register(
                {
                    controlIdentifier: PERFORMA_CONTROL_IDENTIFIER.MARKDOWN,
                    component: MarkdownDetail,
                },
                Symbol.for(PERFORMA_CONTROL_IDENTIFIER.MARKDOWN)
            );
            provider.register(
                {
                    controlIdentifier: PERFORMA_CONTROL_IDENTIFIER.TABCONTAINER,
                    component: TabsOrVerticalList,
                },
                Symbol.for(PERFORMA_CONTROL_IDENTIFIER.TABCONTAINER)
            );
            provider.register(
                {
                    controlIdentifier: PERFORMA_CONTROL_IDENTIFIER.UPLOAD,
                    component: Upload,
                },
                Symbol.for(PERFORMA_CONTROL_IDENTIFIER.UPLOAD)
            );
            provider.register(
                {
                    controlIdentifier: PERFORMA_CONTROL_IDENTIFIER.PLACEHOLDER,
                    component: Placeholder,
                },
                Symbol.for(PERFORMA_CONTROL_IDENTIFIER.PLACEHOLDER)
            );
            provider.register(
                {
                    controlIdentifier: PERFORMA_CONTROL_IDENTIFIER.NOCAPTONGROUPBOX,
                    component: NoCaptionGroupBox,
                },
                Symbol.for(PERFORMA_CONTROL_IDENTIFIER.NOCAPTONGROUPBOX)
            );
            provider.register(
                {
                    controlIdentifier: PERFORMA_CONTROL_IDENTIFIER.ZIPPLACELOOKUP,
                    component: ZipPlaceLookup,
                },
                Symbol.for(PERFORMA_CONTROL_IDENTIFIER.ZIPPLACELOOKUP)
            );
            provider.register(
                {
                    controlIdentifier: PERFORMA_CONTROL_IDENTIFIER.FILTEREDDROPDOWN,
                    component: FilteredDropDown,
                },
                Symbol.for(PERFORMA_CONTROL_IDENTIFIER.FILTEREDDROPDOWN)
            );
            provider.register(
                {
                    controlIdentifier: PERFORMA_CONTROL_IDENTIFIER.CUSTOMQUERYDROPDOWN,
                    component: CustomQueryDropDown,
                },
                Symbol.for(PERFORMA_CONTROL_IDENTIFIER.CUSTOMQUERYDROPDOWN)
            );
        });
    };
}
