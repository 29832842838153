import { CrmContextSelector } from '../Components/CrmContext/CrmContextSelector';
import React, { ReactNode, useContext } from 'react';
import { CrmContext } from '../contexts/crmContext/CrmContext';

interface IProps {
    children: ReactNode[];
}

export const CrmContextDialogWrapper: React.FunctionComponent = (props: IProps) => {
    const crmContext = useContext(CrmContext);

    const isContextSelected = !crmContext.isLoading && crmContext.activeCrmContext;

    return (
        <>
            {crmContext.isContextSelectorVisible && <CrmContextSelector />}
            {isContextSelected && props.children}
        </>
    );
};
