import { TActionType } from '../../redux/types';
import { useReducer } from 'react';
import { bindActionCreators } from 'redux';

export enum SelectedView {
    SubDetail,
    Detail,
    List,
    None,
}

interface IEntry {
    caption?: string;
    path?: string;
}

// State

interface IState {
    list: IEntry;
    detail: IEntry;
    subDetails: IEntry[];
    selectedView: SelectedView;
}

export const initialState: IState = {
    list: {},
    detail: {},
    subDetails: [],
    selectedView: SelectedView.None,
};

// Actions

export const actions = {
    setListCaption: (caption: string) =>
        ({
            type: 'setListCaption',
            caption,
        } as const),
    setListPath: (path: string) =>
        ({
            type: 'setListPath',
            path,
        } as const),
    setDetailCaption: (caption: string) =>
        ({
            type: 'setDetailCaption',
            caption,
        } as const),
    setDetailPath: (path: string) =>
        ({
            type: 'setDetailPath',
            path,
        } as const),
    addSubDetailEntry: (entry: IEntry) =>
        ({
            type: 'addSubDetailEntry',
            entry,
        } as const),
    setSelectedView: (selectedView: SelectedView) =>
        ({
            type: 'setSelectedView',
            selectedView,
        } as const),
};

type TAction = TActionType<typeof actions>;

// Reducer

export const reducer = (state: IState = initialState, action: TAction): IState => {
    switch (action.type) {
        case 'setListCaption':
            return { ...state, list: { ...state.list, caption: action.caption } };
        case 'setListPath':
            return { ...state, list: { ...state.list, path: action.path } };
        case 'setDetailCaption':
            return { ...state, detail: { ...state.detail, caption: action.caption } };
        case 'setDetailPath':
            return { ...state, detail: { ...state.detail, path: action.path } };
        case 'addSubDetailEntry':
            return { ...state, subDetails: [...state.subDetails, action.entry] };
        case 'setSelectedView':
            return {
                ...state,
                subDetails: action.selectedView !== SelectedView.SubDetail ? [] : state.subDetails,
                selectedView: action.selectedView,
            };
        default:
            return { ...state };
    }
};

export const useDataBrowserReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const boundActionCreators = bindActionCreators(actions, dispatch as any);
    return { state, actions: boundActionCreators };
};
