export interface IHttpObjectResult {
    readonly status: number;
}

export interface IErrorMap {
    [key: string]: string[];
}

export interface IHttpBadRequestObjectResult extends IHttpObjectResult {
    readonly errors: IErrorMap;
    readonly type: string;
    readonly title: string;
}

export class PerformaBadRequestClientError extends Error {
    badRequestObjectResult: IHttpBadRequestObjectResult;

    constructor(objectResult: IHttpBadRequestObjectResult) {
        super();
        this.name = 'Bad Request';
        this.message = objectResult.title;
        this.badRequestObjectResult = objectResult;
    }
}

export function isHttpBadRequestObjectResult(
    object: any,
    httpStatus: number
): object is IHttpBadRequestObjectResult {
    return (
        object != null &&
        httpStatus === 400 &&
        (object as IHttpBadRequestObjectResult).errors !== undefined &&
        (object as IHttpBadRequestObjectResult).type !== undefined &&
        (object as IHttpBadRequestObjectResult).status !== undefined &&
        (object as IHttpBadRequestObjectResult).title !== undefined
    );
}

export function isPerformaBadRequestClientError(
    object: any
): object is PerformaBadRequestClientError {
    return (
        object != null &&
        (object as PerformaBadRequestClientError).name !== undefined &&
        (object as PerformaBadRequestClientError).message !== undefined &&
        (object as PerformaBadRequestClientError).badRequestObjectResult !== undefined
    );
}
