import { HelpTextVisibility, IHelpTextVisibilityStrategy } from '@quino/ui';
import { inject, injectable } from 'inversify';
import { FieldDescriptionVisibility } from '../Components/Profile/IProfileFormState';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IProfileService } from '../api/IProfileService';
import { IUserProfileDTO } from '../api/DTOs/IUserProfileDTO';
import { IMetaProperty } from '@quino/core';

@injectable()
export class ProfileHelpTextVisibilityStrategy implements IHelpTextVisibilityStrategy {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPROFILESERVICE) private profileService: IProfileService
    ) {}

    async getHelpTextVisibilityAsync(layoutElement?: IMetaProperty): Promise<HelpTextVisibility> {
        const userProfileDto = await this.profileService.getProfileInformationAsync();

        return ProfileHelpTextVisibilityStrategy.convertUserSettingToHelpTextVisibility(
            userProfileDto
        );
    }

    private static convertUserSettingToHelpTextVisibility(
        userProfileDto: IUserProfileDTO
    ): HelpTextVisibility {
        switch (userProfileDto.showFieldDescriptions) {
            case FieldDescriptionVisibility.Always:
                return HelpTextVisibility.Always;
            case FieldDescriptionVisibility.OnHover:
                return HelpTextVisibility.OnHover;
            case FieldDescriptionVisibility.Never:
                return HelpTextVisibility.Never;
        }
    }
}
