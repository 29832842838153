import React, { useEffect, useState } from 'react';
import { IRouteHelper } from '../../../navigation/IRouteHelper';
import { LoginContext } from '../../../contexts/loginContext';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { IPerformaAuthService } from '../../../authentication/IPerformaAuthService';
import { HOC } from '../../../util/types';
import { Route, RouteComponentProps } from 'react-router';
import { useService } from '../../../ioc/hook/useService';

interface IProps extends RouteComponentProps {}

const LoginWrapperComponent: React.FunctionComponent<IProps> = (props) => {
    const authService = useService<IPerformaAuthService>(
        SHARED_SERVICE_IDENTIFIER.IPERFORMAAUTHSERVICE
    );
    const routeHelper = useService<IRouteHelper>(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER);

    const [isLoggedIn, setIsLoggedIn] = useState<boolean>();

    const onTryLoginAsync = async (username: string, password: string) => {
        const success = await authService.loginAsync(username, password);
        setIsLoggedIn(success);
        return success;
    };

    const onTryLogoutAsync = async () => {
        const isLoggedOut = await authService.logoutAsync();
        setIsLoggedIn(!isLoggedOut);
        isLoggedOut && props.history.push(routeHelper.getStartUrl());
        return isLoggedOut;
    };

    const initFromSession = async () => {
        setIsLoggedIn(await authService.isLoggedInAsync());
    };

    useEffect(() => {
        initFromSession();
    }, []);

    const value = {
        isLoggedIn,
        onTryLoginAsync,
        onTryLogoutAsync,
    };

    return <LoginContext.Provider value={value}>{props.children}</LoginContext.Provider>;
};

export const withLoginWrapper: HOC = (InnerComponent) => (props) => (
    <Route>
        {(routeProps: RouteComponentProps) => (
            <LoginWrapperComponent {...routeProps}>
                <InnerComponent {...props} />
            </LoginWrapperComponent>
        )}
    </Route>
);
