import React, { useContext, useState } from 'react';
import { useI18n } from '../../../lang/useI18n';
import { ScrollablePopup } from '../../Util/Devextreme/ScrollablePopup';
import { Markdown } from '../../Util/Markdown/Markdown';
import { PopupButtonBar } from '../../Util/Devextreme/PopupButtonBar';
import { Button } from 'devextreme-react/button';
import { LoginContext } from '../../../contexts/loginContext';

export const UserSettingsErrorPopup: React.FC = () => {
    const i18n = useI18n();
    const loginContext = useContext(LoginContext);

    const popupContent = i18n.t('literal.CustomLiterals.Profile.UserSettings.Error.Message');

    const [isOpen, setIsOpen] = useState(!!popupContent);

    const closeDialogAndLogout = () => {
        setIsOpen(false);
        loginContext.onTryLogoutAsync();
    };

    return (
        <ScrollablePopup
            closeOnOutsideClick={true}
            dragEnabled={false}
            visible={isOpen}
            onHiding={closeDialogAndLogout}
            width={'auto'}
            maxWidth={'50%'}
            height={'auto'}
            title={i18n.t('literal.CustomLiterals.Profile.UserSettings.Error.Title')}
        >
            <Markdown>{popupContent}</Markdown>
            <PopupButtonBar>
                <Button
                    onClick={closeDialogAndLogout}
                    type={'default'}
                    text={i18n.t('literal.CustomLiterals.Confirm')}
                />
            </PopupButtonBar>
        </ScrollablePopup>
    );
};
