import { useEffect } from 'react';
import { useService } from '../../ioc/hook/useService';
import { ISessionService } from '../../session/ISessionService';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { ILanguageService, QuinoCoreServiceSymbols } from '@quino/core';

/**
 * This hook detects the user's preferred language and writes it into session state
 */
export const useLanguageDetector = (onSuccess: () => void) => {
    const sessionService = useService<ISessionService>(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE);
    const languageService = useService<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService);

    useEffect(() => {
        (async () => {
            const locale = await languageService.getCurrentLanguageAsync();
            sessionService.updateSessionState({ locale: locale });
            onSuccess();
        })();
    }, []);
};
