import { getAspectOrDefault, IFieldValidator, IMetaProperty } from '@quino/core';
import { injectable } from 'inversify';
import { PerformaValidationErrorCodes } from './PerformaValidationErrorCodes';
import { IValidationContext } from '@quino/core/dist/validations/IValidationContext';
import { IFieldValidationResult } from '@quino/core/dist/validations/IFieldValidationResult';
import {
    BooleanIsTrueAspectIdentifier,
    IBooleanIsTrueAspect,
} from '../../meta/aspects/IBooleanIsTrueAspect';

@injectable()
export class BooleanIsTrueValidator implements IFieldValidator {
    validate(
        field: IMetaProperty,
        value: any,
        context: IValidationContext
    ): IFieldValidationResult {
        const aspect = getAspectOrDefault<IBooleanIsTrueAspect>(
            field,
            BooleanIsTrueAspectIdentifier
        );

        if (aspect == null || value === true) {
            return {};
        }

        return {
            fieldErrors: [
                {
                    fieldName: field.name,
                    errorCode: PerformaValidationErrorCodes.BooleanNotTrue,
                    errorMessage: aspect.message,
                },
            ],
        };
    }
}
