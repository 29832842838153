import * as React from 'react';
import { useContext } from 'react';
import { ConfigurationContext } from '../../contexts/configurationContext/ConfigurationContext';
import { BrowserNotSupportedMessage } from './BrowserNotSupportedMessage';

interface IProps extends React.PropsWithChildren<{}> {
    pageDecorator?: React.FunctionComponent<any>;
}

/**
 * @param userAgent: The user agent string
 * @param configEntry: A string containing a regex of user agent strings to block
 * @returns true, if the browser should be blocked, false otherwise
 */
export const isBrowserBlocked = (userAgent: string, configEntry: string | undefined): boolean => {
    if (!configEntry) {
        return false;
    }

    try {
        return new RegExp(configEntry).test(userAgent);
    } catch (exception) {
        return false;
    }
};

export const BrowserCheck: React.FunctionComponent<IProps> = (props) => {
    const configuration = useContext(ConfigurationContext);
    const userAgent = window.navigator.userAgent;

    if (
        isBrowserBlocked(
            userAgent,
            configuration.getStringValue('client.Browsers.BlockedUserAgentsRegEx')
        )
    ) {
        return <BrowserNotSupportedMessage pageDecorator={props.pageDecorator} />;
    }

    return <>{props.children}</>;
};
