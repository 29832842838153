import React from 'react';
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import { TextBox } from 'devextreme-react/text-box';
import { lazyInject, QuinoCoreServiceSymbols } from '@quino/core';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { IPerformaServer } from '../../api/IPerformaServer';
import { IPerformaI18n } from '../../lang/IPerformaI18n';
import { ErrorMessage } from '../Util/ErrorMessage';
import { Typography } from '../Util/Devextreme/Typography';
import { QuinoLabeled, QuinoLabeledLabelPosition } from '@quino/ui';

interface IState {
    token: string;
    isFetching: boolean;
    message: string;
    success: boolean;
}

export class ServerRestart extends React.Component<{}, IState> {
    @lazyInject(SHARED_SERVICE_IDENTIFIER.IPERFORMASERVER)
    private performaServer: IPerformaServer;
    @lazyInject(QuinoCoreServiceSymbols.II18N)
    private i18n: IPerformaI18n;

    public state = { token: '', isFetching: false, message: '', success: false };

    private submit = async () => {
        this.setState({ isFetching: true });
        try {
            await this.performaServer.restartServerAsync({ token: this.state.token });
            this.setState({
                message: this.i18n.t('literal.CustomLiterals.ServerRestart.Success'),
                success: true,
            });
        } catch (errorMessage) {
            this.setState({
                message: this.i18n.t('literal.CustomLiterals.ServerRestart.Failure', {
                    errorMessage,
                }),
                success: false,
            });
        } finally {
            this.setState({ isFetching: false });
        }
    };

    private updateToken = (token: string) => {
        this.setState({ token });
    };

    render() {
        const { isFetching, message, success, token } = this.state;

        return (
            <div style={{ width: '100%', padding: '20px', boxSizing: 'border-box' }}>
                <QuinoLabeled
                    label={'Token'}
                    required={true}
                    labelPosition={QuinoLabeledLabelPosition.Top}
                >
                    <TextBox
                        value={token}
                        onInput={({ event }) => this.updateToken((event as any).target.value || '')}
                        stylingMode={'filled'}
                        width={'100%'}
                        style={{ marginBottom: '20px' }}
                    />
                </QuinoLabeled>
                <Button
                    disabled={isFetching}
                    stylingMode={'contained'}
                    type={'default'}
                    onClick={this.submit}
                    text={this.i18n.t('literal.CustomLiterals.ServerRestart.Submit')}
                    icon={'refresh'}
                />
                <LoadPanel visible={isFetching} />
                <div>
                    {success && <Typography>{message}</Typography>}
                    {!success && <ErrorMessage>{message}</ErrorMessage>}
                </div>
            </div>
        );
    }
}
