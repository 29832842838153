import { IPasswordResetStateHelper } from './IPasswordResetStateHelper';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { IProfileService } from '../../../api/IProfileService';
import { QuinoCoreServiceSymbols } from '@quino/core';
import { IPerformaI18n } from '../../../lang';
import flow from 'lodash/flow';
import { IPasswordValidator } from '../../../authentication/IPasswordValidator';

export interface IPasswordResetState {
    errorMessage?: string;
    username: string;
    password: string;
    passwordRepeat: string;
    isValid: boolean;
    processCompleted: boolean;
    usernameError?: string;
    passwordError?: string;
    passwordRepeatError?: string;
}

@injectable()
export class PasswordResetStateHelper implements IPasswordResetStateHelper {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPROFILESERVICE) private profileService: IProfileService,
        @inject(QuinoCoreServiceSymbols.II18N) private i18n: IPerformaI18n,
        @inject(SHARED_SERVICE_IDENTIFIER.IPASSWORDVALIDATOR)
        private passwordValidator: IPasswordValidator
    ) {}

    updateUsername(oldState: IPasswordResetState, username: string): IPasswordResetState {
        return {
            ...oldState,
            username,
        };
    }

    updatePassword(oldState: IPasswordResetState, password: string) {
        return {
            ...oldState,
            password,
        };
    }

    updatePasswordRepeat(oldState: IPasswordResetState, passwordRepeat: string) {
        return {
            ...oldState,
            passwordRepeat,
        };
    }

    async submitAsync(oldState: IPasswordResetState, secretToken: string) {
        const validatedState = await this.validateAsync(oldState);

        if (!validatedState.isValid) {
            return { ...validatedState };
        }

        try {
            await this.profileService.setPasswordAsync(
                validatedState.username,
                validatedState.password,
                secretToken
            );
            return { ...validatedState, processCompleted: true };
        } catch (e) {
            return {
                ...validatedState,
                errorMessage: this.i18n.t(
                    'literal.CustomLiterals.PasswordReset.ErrorResetTokenExpired'
                ),
                processCompleted: false,
            };
        }
    }

    private async validateAsync(oldState: IPasswordResetState): Promise<IPasswordResetState> {
        return flow([
            this.resetValidationState,
            this.validateUsername,
            this.validatePasswordRepeat,
            this.validatePasswordAsync,
        ])(oldState);
    }

    private resetValidationState = (state: IPasswordResetState): IPasswordResetState => {
        return {
            ...state,
            isValid: true,
            errorMessage: undefined,
            usernameError: undefined,
            passwordError: undefined,
            passwordRepeatError: undefined,
        };
    };

    private validateUsername = (state: IPasswordResetState): IPasswordResetState => {
        if (state.username.length > 0) {
            return {
                ...state,
            };
        }

        return {
            ...state,
            isValid: false,
            usernameError: this.i18n.t(
                'literal.CustomLiterals.PasswordReset.ErrorUsernameMustBeNotEmpty'
            ),
        };
    };

    private validatePasswordAsync = async (
        state: IPasswordResetState
    ): Promise<IPasswordResetState> => {
        const validationErrors = await this.passwordValidator.validateAsync(state.password);
        if (validationErrors.length === 0) {
            return {
                ...state,
            };
        }

        return {
            ...state,
            isValid: false,
            passwordError: validationErrors.join(' '),
        };
    };

    private validatePasswordRepeat = (state: IPasswordResetState): IPasswordResetState => {
        if (state.password === state.passwordRepeat) {
            return {
                ...state,
            };
        }

        return {
            ...state,
            isValid: false,
            passwordRepeatError: this.i18n.t(
                'literal.CustomLiterals.PasswordReset.ErrorPasswordsDontMatch'
            ),
        };
    };
}
