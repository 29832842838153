import React, { useEffect } from 'react';
import { Typography } from 'performa-shared';
import { tKey } from '../../lang/TranslationKeys';
import { Translation } from '@quino/ui';

interface IProps {
    url: string;
    target: string;
}

export const ExternalLink: React.FC<IProps> = (props) => {
    const { url, target } = props;

    useEffect(() => {
        window.open(url, target);
    }, []);

    return (
        <div>
            <Typography>
                <h5>
                    <Translation i18nKey={tKey('literal.CustomLiterals.PNet.Link.Heading')} />
                </h5>
            </Typography>
            <Typography>
                <Translation i18nKey={tKey('literal.CustomLiterals.PNet.Documents.Text')} />
                <a href={url} target={target}>
                    <Translation i18nKey={tKey('literal.CustomLiterals.PNet.Documents.LinkText')} />
                </a>
                .
            </Typography>
        </div>
    );
};
