import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionState, sessionActions } from './sessionStore';
import { useService } from '../ioc/hook/useService';
import {
    ISession as IQuinoSession,
    ISessionEventTarget as IQuinoSessionEventTarget,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { IPerformaSessionState } from './IPerformaSessionState';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { ISessionPersister } from './ISessionPersister';

export const SessionProvider: React.FC = (props) => {
    const sessionState = useSelector(getSessionState);
    const serializedSessionState = JSON.stringify(sessionState);
    const dispatch = useDispatch();

    function updateState(newState: Partial<IPerformaSessionState>) {
        dispatch(sessionActions.updateSessionState(newState));
    }

    const quinoSession = useService<IQuinoSession>(QuinoCoreServiceSymbols.ISession);
    const quinoSessionEventTarget = useService<IQuinoSessionEventTarget>(
        QuinoCoreServiceSymbols.ISessionEventTarget
    );

    const updateQuinoSession = () => {
        quinoSession.updateSession(sessionState);
    };

    const registerQuinoSessionListener = () => {
        quinoSessionEventTarget.addStateChangedListener(updateState);
        return () => {
            quinoSessionEventTarget.removeStateChangedListener(updateState);
        };
    };

    const sessionPersister = useService<ISessionPersister>(
        SHARED_SERVICE_IDENTIFIER.ISESSIONPERSISTER
    );

    const persistCurrentSession = () => {
        sessionPersister.setSession(sessionState);
    };

    const initFromStorage = () => {
        const newState = sessionPersister.getSession();
        updateState(newState);
    };

    useEffect(initFromStorage, []);
    useEffect(registerQuinoSessionListener, []);
    useEffect(updateQuinoSession, [serializedSessionState]);
    useEffect(persistCurrentSession, [serializedSessionState]);

    return <>{props.children}</>;
};
