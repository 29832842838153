import React from 'react';
import {
    IQuinoComponentProps,
    ObjectBookmark,
    QuinoLabeled,
    QuinoLabeledLabelPosition,
    useService,
} from '@quino/ui';

import { Markdown } from '../../Util/Markdown/Markdown';
import { getMetaProperty, IExpressionEvaluator, QuinoCoreServiceSymbols } from '@quino/core';

type TStyles = {
    markdownDetailRoot: string;
    markdownDetail: string;
};

const styles: TStyles = require('./MarkdownDetail.less');

export const MarkdownDetail: React.FunctionComponent<IQuinoComponentProps<ObjectBookmark>> = (
    props
) => {
    const { element, bookmark } = props;
    const metaProperty = getMetaProperty(element);
    const { description, name, caption } = metaProperty;

    const expressionEvaluator = useService<IExpressionEvaluator>(
        QuinoCoreServiceSymbols.IExpressionEvaluator
    );
    const context = bookmark.genericObject;
    const required =
        metaProperty.required != null &&
        expressionEvaluator.evaluate<boolean>(metaProperty.required, context);

    return (
        <div className={styles.markdownDetail}>
            <QuinoLabeled
                label={caption}
                required={required}
                hint={description}
                labelPosition={QuinoLabeledLabelPosition.Top}
            >
                <div className={styles.markdownDetailRoot}>
                    <Markdown>{bookmark.genericObject[name]}</Markdown>
                </div>
            </QuinoLabeled>
        </div>
    );
};
