import React from 'react';
import { useTranslation } from '../../../lang/useTranslation';
import { Markdown } from '../../Util/Markdown/Markdown';

export const LoginFormCustomText: React.FunctionComponent = () => {
    const customText = useTranslation('literal.CustomLiterals.Login.CustomText');

    if (customText.length === 0) {
        return null;
    }

    return <Markdown>{customText}</Markdown>;
};
