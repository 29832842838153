import * as React from 'react';
import { useContext } from 'react';
import { ConfigurationContext } from '../../contexts/configurationContext/ConfigurationContext';
import { VerticalList } from '../VerticalList/VerticalList';
import { IBookmark, IQuinoComponentProps, QuinoTabsContainer } from '@quino/ui';

export const TabsOrVerticalList: React.FunctionComponent<IQuinoComponentProps<IBookmark>> = (
    props
) => {
    const configuration = useContext(ConfigurationContext);
    const renderTabsAsVerticalLists = (): boolean => {
        return configuration.getBooleanValueOrDefault(
            'client.Forms.RenderTabsAsVerticalLists',
            false
        );
    };

    return (
        <>
            {renderTabsAsVerticalLists() ? (
                <VerticalList {...props} />
            ) : (
                <QuinoTabsContainer {...props} />
            )}
        </>
    );
};
