import React, { useState } from 'react';
import { ITextBoxOptions } from 'devextreme-react/text-box';
import { TextBox } from 'devextreme-react/text-box';
import DevExpress from 'devextreme/bundles/dx.all';
import { useI18n } from '../../../lang/useI18n';
import dxTextEditorButton = DevExpress.ui.dxTextEditorButton;

interface IProps extends ITextBoxOptions {}

export const PasswordTextField: React.FunctionComponent<IProps> = (props: IProps) => {
    const i18n = useI18n();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const handleOnClick = () => setPasswordVisible(!passwordVisible);

    const showHideButton: dxTextEditorButton = {
        location: 'after',
        name: 'showHidePassword',
        options: {
            icon: passwordVisible ? 'material-icons visibility' : 'material-icons visibility_off',
            onClick: handleOnClick,
            hint: i18n.t('literal.CustomLiterals.Login.ShowPassword'),
            tabIndex: -1,
        },
    };

    return (
        <TextBox
            {...props}
            mode={passwordVisible ? 'text' : 'password'}
            buttons={[showHideButton]}
        />
    );
};
