import * as React from 'react';
import { MainMenu } from '../MainMenu/MainMenu';
import { ISiteMap } from '../../../../navigation/ISiteMap';
import { Drawer } from 'devextreme-react/drawer';

interface IProps {
    siteMap: ISiteMap;
    open: boolean;
}

export const SideBarWidth = 240;

export const Sidebar: React.FC<IProps> = ({ siteMap, open, children }) => {
    const renderMenu = () => <MainMenu isWideMode={open} siteMap={siteMap} />;

    return (
        <Drawer
            position={'left'}
            revealMode={'expand'}
            minSize={48}
            maxSize={SideBarWidth}
            opened={open}
            render={renderMenu}
            animationDuration={200}
        >
            {children}
        </Drawer>
    );
};
