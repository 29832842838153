import React, { useContext } from 'react';
import { Translation } from '@quino/ui';
import { tKey } from '../../lang/TranslationKeys';
import { UserSettingsForm } from './UserSettingsForm';
import { LoginContext } from '../../contexts/loginContext';
import { CrmContext } from '../../contexts/crmContext/CrmContext';
import { Typography } from '../Util/Devextreme/Typography';
import { ScrollablePopup } from '../Util/Devextreme/ScrollablePopup';
import { useI18n } from '../../lang/useI18n';

interface IProps {
    onSuccess: () => void;
}

export const UserSettingsDialog: React.FunctionComponent<IProps> = (props: IProps) => {
    const i18n = useI18n();

    const { onSuccess } = props;

    const loginContext = useContext(LoginContext);
    const crmContext = useContext(CrmContext);

    const onDrillUp = () => {
        if (crmContext.availableCrmContexts.length > 1) {
            crmContext.trySetActiveCrmContextAsync(undefined);
            crmContext.showContextSelector();
        } else {
            loginContext.onTryLogoutAsync();
        }
    };

    return (
        <ScrollablePopup
            visible={true}
            title={i18n.t('literal.CustomLiterals.UserSettings.Dialog.Title')}
            showCloseButton={false}
            dragEnabled={false}
            closeOnOutsideClick={false}
        >
            <Typography>
                <Translation
                    i18nKey={tKey('literal.CustomLiterals.UserSettings.Dialog.IntroText')}
                />
            </Typography>
            <UserSettingsForm
                onSaveSuccess={onSuccess}
                onDrillUp={onDrillUp}
                showLogoutButton={true}
            />
        </ScrollablePopup>
    );
};
