import { IProfileService } from './IProfileService';
import { inject, injectable } from 'inversify';
import { IRequestBuilder, QuinoCoreServiceSymbols } from '@quino/core';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IPerformaUrlManager } from './IPerformaUrlManager';
import { IChangePasswordDTO } from './DTOs/IChangePasswordDTO';
import { IUserProfileDTO } from './DTOs/IUserProfileDTO';
import { IUserRegistrationDTO } from './DTOs/IUserRegistrationDTO';
import { IConfirmEmailDTO } from './DTOs/IConfirmEmailDTO';
import { ICurrentUserProfileCache } from './ICurrentUserProfileCache';

@injectable()
export class ProfileService implements IProfileService {
    constructor(
        @inject(QuinoCoreServiceSymbols.IRequestBuilder) private requestBuilder: IRequestBuilder,
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMAURLMANAGER)
        private urlManager: IPerformaUrlManager,
        @inject(SHARED_SERVICE_IDENTIFIER.ICURRENTUSERPROFILECACHE)
        private currentUserProfileCache: ICurrentUserProfileCache
    ) {}

    async setPasswordAsync(username: string, password: string, secret: string): Promise<void> {
        const url = this.urlManager.getSetPasswordUrl();
        await this.requestBuilder
            .create(url, 'post')
            .setJsonPayload({
                username,
                password,
                secret,
            })
            .fetch();
    }

    async resetPasswordAsync(username: string): Promise<void> {
        const url = this.urlManager.getResetPasswordUrl();
        await this.requestBuilder
            .create(url, 'post')
            .setJsonPayload({ username })
            .fetch();
    }

    async changePasswordAsync(data: IChangePasswordDTO): Promise<void> {
        const url = this.urlManager.getChangePasswordUrl();
        await this.requestBuilder
            .create(url, 'post')
            .requiresAuthentication()
            .setJsonPayload(data)
            .fetch();
    }

    async getProfileInformationAsync(): Promise<IUserProfileDTO> {
        const cachedProfile = this.currentUserProfileCache.getAsync();
        if (cachedProfile) {
            return cachedProfile;
        }

        const url = this.urlManager.getProfileUrl();
        const response = this.requestBuilder
            .create(url, 'get')
            .requiresAuthentication()
            .fetchJson<IUserProfileDTO>();

        this.currentUserProfileCache.populate(response);

        response.then(
            (value) => {
                return response;
            },
            (reason) => {
                this.currentUserProfileCache.clear();
                throw new Error(reason);
            }
        );

        return response;
    }

    async updateProfileInformationAsync(data: Partial<IUserProfileDTO>) {
        const url = this.urlManager.getProfileUrl();
        await this.requestBuilder
            .create(url, 'patch')
            .requiresAuthentication()
            .setJsonPayload(data)
            .fetch();
        this.currentUserProfileCache.clear();
    }

    async registerUserAsync(data: IUserRegistrationDTO) {
        const url = this.urlManager.getProfileUrl();
        await this.requestBuilder
            .create(url, 'post')
            .setJsonPayload(data)
            .fetch();

        this.currentUserProfileCache.clear();
    }

    async confirmEmailAsync(data: IConfirmEmailDTO) {
        const url = this.urlManager.getConfirmEmailUrl();
        await this.requestBuilder
            .create(url, 'post')
            .setJsonPayload(data)
            .fetch();

        this.currentUserProfileCache.clear();
    }
}
