import { TActionType } from '../types';
import { TRootReducerState } from '../store';

// State

export const initialApiState = {
    healthCheckRequested: false,
    serverReachable: true,
};

type TState = typeof initialApiState;

// Actions

export const apiActions = {
    requestHealthCheck: () =>
        ({
            type: 'api/REQUEST_HEALTH_CHECK',
        } as const),
    setServerReachable: (flag: boolean) =>
        ({
            type: 'api/SET_SERVER_REACHABLE',
            flag,
        } as const),
};

type TAction = TActionType<typeof apiActions>;

// Reducer

export const apiReducer = (state: TState = initialApiState, action: TAction): TState => {
    switch (action.type) {
        case 'api/REQUEST_HEALTH_CHECK':
            return {
                ...state,
                healthCheckRequested: true,
            };
        case 'api/SET_SERVER_REACHABLE':
            return {
                ...state,
                healthCheckRequested: false,
                serverReachable: action.flag,
            };
    }

    return state;
};

// Selectors

export const isServerReachable = (state: TRootReducerState) => state.api.serverReachable;

export const isHealthCheckRequested = (state: TRootReducerState) => state.api.healthCheckRequested;
