/**
 * The identifier for retrieving a settings upload aspect.
 */
import { IMetaAspect } from '@quino/core';

export const UploadSettingsAspectIdentifier = 'ControlParameters';

/**
 * Upload settings which contains the context, minimum and maximum files to upload
 */
export interface IUploadSettingsAspect extends IMetaAspect {
    context?: string;
    maxfiles?: number;
    minfiles?: number;
}
