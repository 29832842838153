import { useEffect, useRef } from 'react';
import { TextBox } from 'devextreme-react/text-box';
import DevExpress from 'devextreme/bundles/dx.all';
import EditorOptions = DevExpress.ui.EditorOptions;

/**
 * Hook to set initial focus to DevExpress TextBox
 *
 * Usage:
 * const initialFocusRef = useTextBoxFocusRef();
 * <TextBox ref={initialFocusRef} />
 */
export function useTextBoxFocusRef() {
    const ref = useRef<TextBox>(null);

    useEffect(() => {
        ref.current && ref.current.instance.focus();
    }, []);
    return ref;
}

/**
 * Generates props to mark a Widget as invalid, if the error message is not empty
 *
 * Usage:
 * <TextBox {...errorMessageProps(errorMessage)} />
 */
export function errorMessageProps(
    errorMessage: string | undefined
): Pick<EditorOptions, 'validationStatus' | 'validationError'> {
    const isValid = !errorMessage;
    return {
        validationStatus: isValid ? 'valid' : 'invalid',
        validationError: {
            validationCallback: () => isValid,
            message: errorMessage,
        },
    };
}
