import React from 'react';
import { Button } from 'devextreme-react/button';

type TStyles = {
    button: string;
    buttonContent: string;
    icon: string;
    listItemText: string;
};

const styles: TStyles = require('./UserMenuItem.less');

interface IProps {
    caption?: string;
    icon?: () => React.ReactElement;
    onClick?: () => unknown;
    disabled?: boolean;
}

export const UserMenuItem: React.FC<IProps> = (props) => {
    return (
        <Button
            disabled={props.disabled}
            className={styles.button}
            onClick={props.onClick}
            stylingMode={'text'}
        >
            <div className={styles.buttonContent}>
                <div className={styles.icon}>{props.icon && props.icon()}</div>
                <div className={styles.listItemText}>{props.children || props.caption}</div>{' '}
            </div>
        </Button>
    );
};
