import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { ILanguageMetadata, ILanguageService, QuinoCoreServiceSymbols } from '@quino/core';
import { QuinoLabeled } from '@quino/ui';
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox } from 'devextreme-react/text-box';
import { FieldDescriptionVisibility, IProfileFormState } from './IProfileFormState';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { IProfileFormStateHelper } from './IProfileFormStateHelper';
import { Markdown } from '../Util/Markdown/Markdown';
import { PasswordTextField } from '../Auth/PasswordTextField/PasswordTextField';
import { ProfileContext } from '../../contexts/profileContext';
import { useService } from '../../ioc/hook/useService';
import { useTranslation } from '../../lang/useTranslation';
import { ErrorMessage } from '../Util/ErrorMessage';
import { Typography } from '../Util/Devextreme/Typography';
import { errorMessageProps, useTextBoxFocusRef } from '../Util/Devextreme/DevextremeHelpers';
import { useI18n } from '../../lang/useI18n';

type TStyles = {
    profileFormRoot: string;
    profileFormForm: string;
    profileFormTextField: string;
    profileFormAction: string;
    profileFormSaveButton: string;
    profileFormDiscardButton: string;
};

const styles: TStyles = require('./ProfileForm.less');

interface IProps {
    title: string;
    saveButtonText?: string;
    showSaveButtonIcon: boolean;
    showLeadInText: boolean;
    showLeadOutText: boolean;
    allowSetPassword: boolean;
    allowChangeEmail: boolean;
    allowChangeFieldDescription: boolean;
    showDiscardButton: boolean;
    initialState: Partial<IProfileFormState>;
    submitAsync: (state: IProfileFormState) => Promise<void>;
    onSuccess: () => void;
    onLanguageChanged?: (language: string) => Promise<void>;
}

export const ProfileForm: React.FunctionComponent<IProps> = (props: IProps) => {
    const i18n = useI18n();
    const stateHelper = useService<IProfileFormStateHelper>(
        SHARED_SERVICE_IDENTIFIER.IPROFILEFORMSTATEHELPER
    );
    const languageService = useService<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService);

    const [profileData, setProfileData] = useContext(ProfileContext);

    const [state, setState] = useState<IProfileFormState>({
        inProgress: false,
        isValid: true,
        isEdited: false,
        allowSetPassword: props.allowSetPassword,
        firstName: '',
        lastName: '',
        emailAddress: '',
        showFieldDescriptions: FieldDescriptionVisibility.Always,
        languageCode: '',
        password: '',
        passwordConfirmation: '',
        ...props.initialState,
    });

    const [languages, setLanguages] = useState<ILanguageMetadata[]>();

    useEffect(
        () => {
            (async () => {
                const languages = await languageService.getLanguageListAsync();
                setLanguages(languages);
            })();
        },
        [languageService]
    );

    const initialFocusRef = useTextBoxFocusRef();

    const updateInput = (fieldName: keyof IProfileFormState, value: string) => {
        state[fieldName] = value;
        let isEdited = false;
        for (const key in profileData) {
            isEdited = profileData[key] !== state[key];
            if (isEdited) {
                break;
            }
        }
        setState({ ...state, isEdited: isEdited });
    };

    const updateInputField = (fieldName: keyof IProfileFormState) => ({ event }: any) =>
        updateInput(fieldName, event.target.value);

    const updateSelectBox = (fieldName: keyof IProfileFormState) => (event: any) =>
        updateInput(fieldName, event.value);

    const submit = async (event: any) => {
        event.preventDefault();
        setState((state) => ({ ...state, inProgress: true }));
        const validatedState = await stateHelper.validateProfileForm(state);
        setState((state) => ({ ...state, ...validatedState, inProgress: true }));
        try {
            if (validatedState.isValid) {
                await props.submitAsync(validatedState);
                props.onSuccess();
                setProfileData &&
                    setProfileData({
                        firstName: validatedState.firstName,
                        lastName: validatedState.lastName,
                        emailAddress: validatedState.emailAddress,
                        showFieldDescriptions: validatedState.showFieldDescriptions,
                        languageCode: validatedState.languageCode,
                    });
            }
        } catch (e) {
            setState((state) => ({ ...state, errorMessage: e.message }));
        } finally {
            setState((state) => ({ ...state, inProgress: false, isEdited: false }));
        }
    };

    function discard(): void {
        setState((state) => ({ ...state, ...profileData, isEdited: false }));
    }

    const { showLeadInText, showLeadOutText, showDiscardButton } = props;
    const {
        errorMessage,
        inProgress,
        allowSetPassword,
        firstName,
        firstNameError,
        lastName,
        lastNameError,
        emailAddress,
        emailAddressError,
        showFieldDescriptions,
        languageCode,
        languageCodeError,
        isEdited,
    } = state;

    const leadInText = showLeadInText
        ? i18n.t('literal.CustomLiterals.ProfileForm.LeadinText')
        : '';

    const leadOutText = showLeadOutText
        ? i18n.t('literal.CustomLiterals.ProfileForm.LeadoutText')
        : '';

    const renderPasswordFields = () => {
        const { password, passwordError, passwordConfirmation, passwordConfirmationError } = state;

        return (
            <>
                <QuinoLabeled
                    label={i18n.t('literal.CustomLiterals.Profile.Field.Password')}
                    required={true}
                >
                    <PasswordTextField
                        id="password"
                        value={password}
                        onInput={updateInputField('password')}
                        className={styles.profileFormTextField}
                        {...errorMessageProps(passwordError)}
                    />
                </QuinoLabeled>

                <QuinoLabeled
                    label={i18n.t('literal.CustomLiterals.Profile.Field.PasswordConfirmation')}
                    required={true}
                >
                    <PasswordTextField
                        id="passwordConfirmation"
                        value={passwordConfirmation}
                        onInput={updateInputField('passwordConfirmation')}
                        className={styles.profileFormTextField}
                        {...errorMessageProps(passwordConfirmationError)}
                    />
                </QuinoLabeled>
            </>
        );
    };

    const fieldDescriptionData = [
        {
            value: FieldDescriptionVisibility.Always,
            label: i18n.t('literal.CustomLiterals.Profile.ShowDesciptions.Always'),
        },
        {
            value: FieldDescriptionVisibility.OnHover,
            label: i18n.t('literal.CustomLiterals.Profile.ShowDesciptions.Hover'),
        },
        {
            value: FieldDescriptionVisibility.Never,
            label: i18n.t('literal.CustomLiterals.Profile.ShowDesciptions.Never'),
        },
    ];

    return (
        <div className={styles.profileFormRoot}>
            <form onSubmit={submit} className={styles.profileFormForm} noValidate>
                <Typography>
                    <h5>{props.title}</h5>
                </Typography>

                {showLeadInText && (
                    <div>
                        <Markdown>{leadInText}</Markdown>
                    </div>
                )}

                <QuinoLabeled
                    label={useTranslation('literal.CustomLiterals.Profile.Field.Language')}
                    required={true}
                >
                    <SelectBox
                        id="language"
                        dataSource={languages}
                        displayExpr={'caption'}
                        valueExpr={'name'}
                        value={languageCode}
                        onValueChanged={async (event) => {
                            updateSelectBox('languageCode')(event);
                            props.onLanguageChanged && (await props.onLanguageChanged(event.value));
                        }}
                        stylingMode={'underlined'}
                        className={styles.profileFormTextField}
                        {...errorMessageProps(languageCodeError)}
                    />
                </QuinoLabeled>

                <QuinoLabeled
                    label={i18n.t('literal.CustomLiterals.Profile.Field.EmailAddress')}
                    required={true}
                >
                    <TextBox
                        id="emailAddress"
                        value={emailAddress}
                        stylingMode={'underlined'}
                        onInput={updateInputField('emailAddress')}
                        className={styles.profileFormTextField}
                        {...errorMessageProps(emailAddressError)}
                        disabled={!props.allowChangeEmail}
                        ref={props.allowChangeEmail ? initialFocusRef : undefined}
                    />
                </QuinoLabeled>

                <QuinoLabeled
                    label={i18n.t('literal.CustomLiterals.Profile.Field.FirstName')}
                    required={true}
                >
                    <TextBox
                        id="firstName"
                        value={firstName}
                        stylingMode={'underlined'}
                        onInput={updateInputField('firstName')}
                        className={styles.profileFormTextField}
                        {...errorMessageProps(firstNameError)}
                    />
                </QuinoLabeled>

                <QuinoLabeled
                    label={i18n.t('literal.CustomLiterals.Profile.Field.LastName')}
                    required={true}
                >
                    <TextBox
                        id="lastName"
                        value={lastName}
                        stylingMode={'underlined'}
                        onInput={updateInputField('lastName')}
                        className={styles.profileFormTextField}
                        {...errorMessageProps(lastNameError)}
                    />
                </QuinoLabeled>

                {props.allowChangeFieldDescription && (
                    <QuinoLabeled
                        label={i18n.t('literal.CustomLiterals.Profile.Field.ShowFieldDescriptions')}
                        required={true}
                    >
                        <SelectBox
                            id="showFieldDescriptions"
                            dataSource={fieldDescriptionData}
                            displayExpr={'label'}
                            valueExpr={'value'}
                            value={showFieldDescriptions}
                            onValueChanged={updateSelectBox('showFieldDescriptions')}
                            stylingMode={'underlined'}
                            className={styles.profileFormTextField}
                        />
                    </QuinoLabeled>
                )}

                {allowSetPassword && renderPasswordFields()}
                {showLeadOutText && (
                    <div>
                        <Markdown>{leadOutText}</Markdown>
                    </div>
                )}

                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

                <div className={styles.profileFormAction}>
                    {showDiscardButton && (
                        <Button
                            className={styles.profileFormDiscardButton}
                            type={'normal'}
                            disabled={!isEdited}
                            onClick={discard}
                            icon={'undo'}
                            text={i18n.t('literal.CustomLiterals.Profile.Discard')}
                        />
                    )}
                    <Button
                        className={styles.profileFormSaveButton}
                        useSubmitBehavior={true}
                        stylingMode={'contained'}
                        type={'default'}
                        disabled={inProgress || !isEdited}
                        icon={(props.showSaveButtonIcon && 'save') || undefined}
                        text={props.saveButtonText || i18n.t('literal.CustomLiterals.Profile.Save')}
                    />
                    <LoadPanel visible={inProgress} />
                </div>
            </form>
        </div>
    );
};
