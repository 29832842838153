export interface IPerformaExceptionDTO {
    title: string;
    detail: string;
    userMessage: string;
    errorCode: string;
}

export function isPerformaException(object: any): object is IPerformaExceptionDTO {
    return (
        object != null &&
        (object as IPerformaExceptionDTO).title !== undefined &&
        (object as IPerformaExceptionDTO).detail !== undefined &&
        (object as IPerformaExceptionDTO).errorCode !== undefined
    );
}
