import { IRouteHelper } from './IRouteHelper';
import { injectable } from 'inversify';
import { ICrmContextIdentifier } from '../crmContext';
import { CrmContextIdentifierSerializer } from '../crmContext/CrmContextIdentifierSerializer';
import { matchPath } from 'react-router';

export const NEW_RECORD_URL_SEGMENT = 'new';
export const ROUTE_NS_USER = 'user';

@injectable()
export class RouteHelper implements IRouteHelper {
    getLoginUrl = () => '/';

    getStartUrl = () => '/';

    getForgotPasswordUrl = (username = '') => `/forgot-password/${username}`;
    getProfileUrl = () => `/${ROUTE_NS_USER}/profile/`;
    getSettingsUrl = () => `/${ROUTE_NS_USER}/settings/`;
    getRegisterUserUrl = () => `/${ROUTE_NS_USER}/register/User/`;
    getRegisterPVUrl = () => `/${ROUTE_NS_USER}/register/PV/`;

    getRoutePathWithOptionalCrmContext = () => this.calculateRoute(':crmContext([^\\/]+-[^\\/]+)?');

    getRoutePathForRoot = () => this.calculateRoute();
    getRoutePathForRootWithCrmContext = () => this.calculateRoute(':crmContext');
    getRoutePathForList = () => this.calculateRoute(':crmContext', ':siteMapPath');
    getRoutePathForDetail = () => this.calculateRoute(':crmContext', ':siteMapPath', ':id');
    getRoutePathForHistory = () =>
        this.calculateRoute(':crmContext', ':siteMapPath', ':id', ':history');

    getRoutePathForPasswordReset = () => this.calculateRoute('password-reset', ':token');
    getRoutePathForForgotPassword = () => this.calculateRoute('forgot-password', ':username?');
    getRoutePathForConfirmEmail = () =>
        this.calculateRoute(ROUTE_NS_USER, 'confirm-email', ':token');

    getUrl = (
        crmContextIdentifier: ICrmContextIdentifier,
        metaClass?: string,
        id?: string,
        history?: [string],
        isNew = false
    ): string => {
        const crmContext = CrmContextIdentifierSerializer.serialize(crmContextIdentifier);

        if (!metaClass) {
            return `/${crmContext}`;
        }

        if (id || isNew) {
            const url = `/${crmContext}/${metaClass}/${isNew ? NEW_RECORD_URL_SEGMENT : id}`;
            if (history) {
                return `${url}/${history.join(',')}`;
            }
            return url;
        }

        return `/${crmContext}/${metaClass}`;
    };

    matchesCrmContext = (pathToMatch: string, crmContextIdentifier: ICrmContextIdentifier) => {
        const serializedCrmContext = CrmContextIdentifierSerializer.serialize(crmContextIdentifier);
        const result = this.getRoutePathWithOptionalCrmContext();
        const match = matchPath(pathToMatch, { path: result });
        return (match && match.params['crmContext'] === serializedCrmContext) || false;
    };

    getParamsSiteMapPathFromUrl = () => {
        const getSiteMapPathRouteMatch = () => {
            return matchPath(location.pathname, {
                path: this.getRoutePathForList(),
            });
        };

        const match = getSiteMapPathRouteMatch();
        return match && match.params['siteMapPath'];
    };

    isDetailPage = (): boolean => {
        const paramsId = this.getParamsIdFromUrl();
        return !!paramsId;
    };

    getParamsIdFromUrl = () => {
        const getDetailRouteMatch = () => {
            return matchPath(location.pathname, {
                path: this.getRoutePathForDetail(),
            });
        };

        const match = getDetailRouteMatch();
        return match && match.params['id'];
    };

    getParamsHistoryFromUrl = () => {
        const getHistoryRouteMatch = () => {
            return matchPath(location.pathname, {
                path: this.getRoutePathForHistory(),
            });
        };

        const match = getHistoryRouteMatch();
        const historyString: string | undefined = match && match.params['history'];
        return (historyString && historyString.split(',')) || [];
    };

    private calculateRoute = (...segments: string[]) => '/' + segments.join('/');
}
