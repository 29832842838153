import React from 'react';
import { useDataBrowserReducer } from './DataBrowserReducer';
import { HOC } from '../../util/types';

export const DataBrowserContext = React.createContext<ReturnType<typeof useDataBrowserReducer>>(
    {} as any
);

export const withDataBrowserContextProvider: HOC = (InnerComponent: any) => (props) => {
    // tslint:disable-next-line:react-hooks-nesting
    const value = useDataBrowserReducer();

    return (
        <DataBrowserContext.Provider value={value}>
            <InnerComponent {...props} />
        </DataBrowserContext.Provider>
    );
};
