/**
 * The identifier for retrieving a translation key aspect.
 */
import { IMetaAspect } from '@quino/core';

export const TranslationKeyAspectIdentifier = 'ControlParameters';

/**
 * Translation key aspect containing the key which should be used to search for a translation
 */
export interface ITranslationKeyAspect extends IMetaAspect {
    key: string;
}
