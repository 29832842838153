import * as React from 'react';
import { useEffect, useState } from 'react';

import { IQuinoMetaPanelExtensionProps, ListBookmark, ObjectBookmark, useService } from '@quino/ui';
import {
    ILogger,
    IMetaLayout,
    LayoutType,
    NewGenericObjectPrimaryKey,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { ISiteMapItem } from '../../navigation/ISiteMapItem';
import { DataListHeaderListSelection } from './DataListHeaderListSelection';
import { DataListCreateButton } from './DataListCreateButton';
import { TTranslationKey } from '../../lang/TranslationKeys';
import { Markdown } from '../Util/Markdown/Markdown';
import { Typography } from '../Util/Devextreme/Typography';
import { useI18n } from '../../lang/useI18n';
import { IConfigurationService } from '../../configuration';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { PerformaMetaPanelExportButton } from './PerformaMetaPanelExportButton';

type TStyles = {
    titleBox: string;
};

const styles: TStyles = require('./PerformaMetaPanelHeader.less');

interface IOwnProps {
    listLayouts?: IMetaLayout[];
    allowCreate: boolean;
    hideTitle: boolean;
    onLayoutSelected: (layoutName: string) => void;
    onNewRecord: (currentSiteMapItem?: ISiteMapItem) => void;
}

export const PerformaMetaPanelHeader: React.FunctionComponent<
    IQuinoMetaPanelExtensionProps & IOwnProps
> = (props) => {
    const { listLayouts, onLayoutSelected, allowCreate, onNewRecord, bookmark } = props;
    const [hasExportButton, setHasExportButton] = useState(false);
    const configurationService = useService<IConfigurationService>(
        SHARED_SERVICE_IDENTIFIER.ICONFIGURATIONSERVICE
    );
    const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
    const isList = bookmark instanceof ListBookmark;
    const layout = bookmark && bookmark.layout;
    const isDetail = layout ? layout.type === LayoutType.Detail : false;

    const i18n = useI18n();

    let customHeaderText = '';
    if (layout && layout.name) {
        const customLiteralsKey = ('literal.CustomLiterals.Header.' +
            layout.name +
            (isList ? '.List' : '.Detail') +
            '.Markdown') as TTranslationKey;
        customHeaderText = i18n.t(customLiteralsKey);
    }

    let title = layout ? layout.caption : '';
    if (bookmark instanceof ObjectBookmark) {
        if (
            bookmark.genericObject.primaryKey &&
            bookmark.genericObject.primaryKey === NewGenericObjectPrimaryKey
        ) {
            title = i18n.t('literal.CustomLiterals.DataList.New');
        } else if (bookmark.genericObject.title) {
            title = bookmark.genericObject.title;
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const hasButton = await configurationService.getBooleanValueOrDefaultAsync(
                    'client.Detail.useExport',
                    false
                );
                setHasExportButton(hasButton);
            } catch (error) {
                logger.logError(error);
                setHasExportButton(false);
            }
        })();
    }, []);

    return (
        <>
            <Typography>
                <div className={styles.titleBox}>
                    <h5>
                        {title}
                        {isList && (
                            <DataListHeaderListSelection
                                layoutName={layout && layout.name}
                                listLayouts={listLayouts}
                                onLayoutSelected={onLayoutSelected}
                            />
                        )}
                    </h5>
                    <PerformaMetaPanelExportButton
                        visible={hasExportButton && isDetail}
                        {...props}
                    />
                </div>
            </Typography>
            {allowCreate &&
                isList && (
                    <div
                        style={{
                            paddingBottom: '20px',
                            paddingTop: listLayouts && listLayouts.length < 2 ? '12px' : 0,
                        }}
                    >
                        <DataListCreateButton onNewRecord={onNewRecord} />
                    </div>
                )}

            {customHeaderText && <Markdown>{customHeaderText}</Markdown>}
        </>
    );
};
