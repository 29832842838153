import * as React from 'react';
import { useState } from 'react';
import { Markdown } from '../Util/Markdown/Markdown';
import { useTranslation } from '../../lang/useTranslation';
import { useTranslationLoader } from '../PageRoot/useTranslationLoader';
import { AppLoadingScreen } from '../PageRoot/AppLoadingScreen';
import { useLanguageDetector } from '../PageRoot/useLanguageDetector';

interface IProps {
    pageDecorator?: React.FunctionComponent<any>;
}

export const BrowserNotSupportedMessage: React.FunctionComponent<IProps> = ({ pageDecorator }) => {
    const [translationsLoaded, setTranslationsLoaded] = useState(false);
    const [languageDetected, setLanguageDetected] = useState(false);

    useLanguageDetector(() => setLanguageDetected(true));
    useTranslationLoader(() => setTranslationsLoaded(true));

    const errorMessage = useTranslation('literal.CustomLiterals.BlockedBrowserMessage.Markdown');

    if (!translationsLoaded || !languageDetected) {
        <AppLoadingScreen />;
    }

    const Decorator = pageDecorator || React.Fragment;
    return (
        <Decorator>
            <div style={{ margin: 40 }}>
                <Markdown>{errorMessage}</Markdown>
            </div>
        </Decorator>
    );
};
