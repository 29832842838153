import { ICrmContext } from '../../../crmContext/ICrmContext';
import { ICrmContextState } from '../../../contexts/crmContext/ICrmContextState';
import { IPerformaI18n } from '../../../lang/IPerformaI18n';
import { IPerformaServer } from '../../../api/IPerformaServer';
import sortBy from 'lodash/sortBy';
import { ICrmContextWrapperStateHelper } from './ICrmContextWrapperStateHelper';
import { QuinoCoreServiceSymbols } from '@quino/core';
import { ISessionService } from '../../../session/ISessionService';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';

export interface ICrmContextWrapperState extends ICrmContextState {
    errorMessage?: string;
    formerActiveContext?: ICrmContext;
}

@injectable()
export class CrmContextWrapperStateHelper implements ICrmContextWrapperStateHelper {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMASERVER) private server: IPerformaServer,
        @inject(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE) private sessionService: ISessionService,
        @inject(QuinoCoreServiceSymbols.II18N) private i18n: IPerformaI18n
    ) {}

    getInitialState(): Pick<
        ICrmContextState,
        'activeCrmContext' | 'availableCrmContexts' | 'isLoading' | 'isContextSelectorVisible'
    > {
        const activeCrmContext = this.sessionService.getSessionState().activeCrmContext;
        return {
            activeCrmContext,
            isLoading: true,
            availableCrmContexts: [],
            isContextSelectorVisible: !activeCrmContext,
        };
    }

    startLoading(state: ICrmContextWrapperState): ICrmContextWrapperState {
        return {
            ...state,
            isLoading: true,
            errorMessage: undefined,
        };
    }

    stopLoading(state: ICrmContextWrapperState): ICrmContextWrapperState {
        return {
            ...state,
            isLoading: false,
        };
    }

    loadAvailableCrmContextsAsync = async (
        state: ICrmContextWrapperState,
        forceReload?: boolean
    ): Promise<ICrmContextWrapperState> => {
        let response: any;

        try {
            response = await this.server.getContextListAsync(forceReload);
        } catch (e) {
            return {
                ...state,
                errorMessage: this.i18n.t(
                    'literal.CustomLiterals.CrmContextWrapper.ErrorCouldNotLoadFetchContexts',
                    {
                        errorMessage: e.message,
                    }
                ),
            };
        }

        if (response.errors && response.errors.length > 0) {
            return CrmContextWrapperStateHelper.handleErrors(state, response.errors);
        }

        return this.autoSetActiveCrmContextAsync({
            ...state,
            availableCrmContexts: sortBy(response, (o: ICrmContext) => o.caption.toLowerCase()),
        });
    };

    setActiveCrmContextAsync(
        state: ICrmContextWrapperState,
        crmContext: ICrmContext
    ): ICrmContextWrapperState {
        this.sessionService.updateSessionState({
            activeCrmContext: crmContext,
        });

        return {
            ...state,
            activeCrmContext: crmContext,
            isContextSelectorVisible: !crmContext,
        };
    }

    private autoSetActiveCrmContextAsync = (
        state: ICrmContextWrapperState
    ): ICrmContextWrapperState => {
        if (state.availableCrmContexts.length === 1) {
            return this.setActiveCrmContextAsync(state, state.availableCrmContexts[0]);
        } else {
            return state;
        }
    };

    private static handleErrors(state: ICrmContextWrapperState, errors: Error[]) {
        const errorMessages = errors.map((error) => error.message);
        return {
            ...state,
            errorMessage: errorMessages.join(', '),
        };
    }
}
