import * as React from 'react';
import { Button } from 'devextreme-react/button';

export interface ICustomFooterButton {
    caption: string;
    onClick: () => void;
}

interface IProps {
    buttonProps: ICustomFooterButton;
}

export const CustomFooterButton: React.FunctionComponent<IProps> = ({ buttonProps }) => {
    return <Button type={'normal'} text={buttonProps.caption} onClick={buttonProps.onClick} />;
};
