import { IPasswordValidator } from './IPasswordValidator';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { injectable, multiInject } from 'inversify';
import { IPasswordPolicy } from './PasswordPolicy/IPasswordPolicy';

@injectable()
export class PasswordValidator implements IPasswordValidator {
    constructor(
        @multiInject(SHARED_SERVICE_IDENTIFIER.IPASSWORDPOLICY)
        private passwordPolicies: IPasswordPolicy[]
    ) {}

    async validateAsync(password: string): Promise<string[]> {
        const appliedPolicies = this.passwordPolicies.map((policy) => policy.applyAsync(password));

        return (await Promise.all(appliedPolicies)).reduce((a, b) => a.concat(b));
    }
}
