import React, { useEffect, useState } from 'react';
import { useService } from '../../../ioc/hook/useService';
import { IPerformaStorage } from '../../../storage/IPerformaStorage';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import notify from 'devextreme/ui/notify';
import { FileManager } from 'devextreme-react/file-manager';
import { useI18n } from '../../../lang/useI18n';
import { IFileMetadata } from '../../../api/DTOs/IFileMetadata';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import { dxElement } from 'devextreme/core/element';

type TStyles = {
    performaFileManager: string;
    errorField: string;
};

const styles: TStyles = require('./PerformaFileManager.less');

interface IPerformaFileManagerProps {
    readonly: boolean;
    files?: IFileMetadata[];
    onDeleteFile: (id: string) => void;
    isValid: boolean;
    errorMessage?: any;
}

export const PerformaFileManager: React.FC<IPerformaFileManagerProps> = React.memo(
    (props) => {
        const { readonly, files, onDeleteFile, isValid, errorMessage } = props;

        const performaStorage = useService<IPerformaStorage>(
            SHARED_SERVICE_IDENTIFIER.IPERFORMASTORAGE
        );
        const i18n = useI18n();

        const [dxFileManager, setDxFileManager] = useState<dxElement>();
        const [eventHandlers] = useState<{ [id: string]: () => void }>({});

        const downloadFile = async (id: string, name: string) => {
            const url = await performaStorage.tryLoadStorageObjectUrlAsync(id);
            if (url) {
                // taken from https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                link.parentNode && link.parentNode.removeChild(link);
            } else {
                notify(i18n.t('literal.CustomLiterals.Upload.DownLoadError'), 'error', 3000);
            }
        };

        const updateThumbnailsClickListener = () => {
            if (dxFileManager) {
                const thumbnails = dxFileManager.getElementsByClassName(
                    'dx-filemanager-thumbnails-item'
                );
                for (let i = 0; i < thumbnails.length; i++) {
                    const thumbnail = thumbnails.item(i);
                    const file = files && i < files.length && files[i];
                    if (thumbnail && file) {
                        const eventHandler = function() {
                            downloadFile(file.id, file.fileName);
                        };
                        thumbnail.removeEventListener('click', eventHandlers[file.id]);
                        thumbnail.addEventListener('click', eventHandler);
                        eventHandlers[file.id] = eventHandler;
                    }
                }
            }
        };
        const delayedUpdateThumbnailsClickListener = () => {
            setTimeout(updateThumbnailsClickListener, 500);
        };
        useEffect(delayedUpdateThumbnailsClickListener, [props.files, dxFileManager]);

        const customFileSystemProvider = new CustomFileSystemProvider({
            getItems: () => {
                return (
                    (files &&
                        files.map((metadata) => {
                            return {
                                fileId: metadata.id,
                                name: metadata.fileName,
                                size: metadata.sizeInBytes,
                            };
                        })) ||
                    []
                );
            },
            deleteItem: (e: { dataItem: any }) => {
                const { fileId } = e.dataItem;
                onDeleteFile(fileId);
            },
            downloadItems: (e: any[]) => {
                e.forEach((fileItem) => {
                    const { fileId, name } = fileItem.dataItem;
                    downloadFile(fileId, name);
                });
            },
        });

        return (
            <>
                <FileManager
                    className={styles.performaFileManager}
                    height={100} // optimized for 1 row of files
                    selectionMode={'single'}
                    permissions={{ delete: !readonly, download: true }}
                    fileSystemProvider={customFileSystemProvider}
                    toolbar={{ fileSelectionItems: [], items: [] }}
                    contextMenu={{ items: ['delete', 'download'] }}
                    itemView={{ mode: 'thumbnails', showFolders: false, showParentFolder: false }}
                    onInitialized={(e) => setDxFileManager(e.element)}
                />
                {!isValid && (
                    <div className={styles.errorField}>
                        <div className="dx-invalid-message">
                            <div className="dx-overlay-content">
                                {errorMessage && errorMessage.message}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    },
    (prevProps, nextProps) => {
        return (
            (!nextProps.files ||
                JSON.stringify(prevProps.files) === JSON.stringify(nextProps.files)) &&
            prevProps.isValid === nextProps.isValid &&
            JSON.stringify(prevProps.errorMessage) === JSON.stringify(nextProps.errorMessage)
        );
    }
);
