import { inject, injectable } from 'inversify';
import {
    IQuinoDataGridConfiguration,
    IQuinoDataGridConfigurationService,
    TSelectionMode,
} from '@quino/ui';
import { IAuthorizationService, IMetaLayout, QuinoCoreServiceSymbols } from '@quino/core';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { IConfigurationService } from '../../configuration/IConfigurationService';

/**
 * Data grid configuration properties which have a corresponding setting in IConfiguration
 */
type TConfigurableProps =
    | 'useExport'
    | 'useGrouping'
    | 'useHeaderFilter'
    | 'useHeaderSearch'
    | 'useMultipleSorting'
    | 'usePaging'
    | 'useSearch'
    | 'useSorting'
    | 'showDelete'
    | 'itemsPerPage';

@injectable()
export class PerformaDataGridConfigurationService implements IQuinoDataGridConfigurationService {
    constructor(
        @inject(QuinoCoreServiceSymbols.IAuthorizationService)
        private authorizationService: IAuthorizationService,
        @inject(SHARED_SERVICE_IDENTIFIER.ICONFIGURATIONSERVICE)
        private configurationService: IConfigurationService
    ) {}

    getMetaDataGridConfiguration = async (
        layout: IMetaLayout,
        metaClass: string
    ): Promise<IQuinoDataGridConfiguration> => {
        const config = await this.configurationService.getConfigurationAsync();

        // prettier-ignore
        const configValues: Pick<IQuinoDataGridConfiguration, TConfigurableProps> = {
            useExport: config.getBooleanValueOrDefault('client.DataGrid.useExport', true),
            useGrouping: config.getBooleanValueOrDefault('client.DataGrid.useGrouping', true),
            useHeaderFilter: config.getBooleanValueOrDefault('client.DataGrid.useHeaderFilter', true),
            useHeaderSearch: config.getBooleanValueOrDefault('client.DataGrid.useHeaderSearch', false),
            useMultipleSorting: config.getBooleanValueOrDefault('client.DataGrid.useMultipleSorting', true),
            usePaging: config.getBooleanValueOrDefault('client.DataGrid.usePaging', true),
            useSearch: config.getBooleanValueOrDefault('client.DataGrid.useSearch', true),
            useSorting: config.getBooleanValueOrDefault('client.DataGrid.useSorting', true),
            showDelete: config.getBooleanValueOrDefault('client.DataGrid.showDelete', true),
            itemsPerPage: config.getNumberValueOrDefault('client.DataGrid.itemsPerPage', 20),
        };

        const showDelete =
            configValues.showDelete &&
            this.authorizationService.getAuthorization(metaClass).canDelete();

        const selectionMode: TSelectionMode =
            showDelete || configValues.useExport ? 'Multiple' : 'Single';

        return {
            ...configValues,
            selectionMode,
            showDelete,
            useColumnFixing: false,
            drillDownMode: 'All',
        };
    };
}
