import { IConfiguration } from './IConfiguration';
import { IConfigurationService } from './IConfigurationService';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { Configuration } from './Configuration';
import { IConfigurationLoader } from './IConfigurationLoader';
import { TBooleanConfigEntries, TNumberConfigEntries, TStringConfigEntries } from './types';

@injectable()
export class ConfigurationService implements IConfigurationService {
    private configuration?: IConfiguration;

    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.ICONFIGURATIONLOADER)
        private configurationLoader: IConfigurationLoader
    ) {}

    public async getConfigurationAsync(): Promise<IConfiguration> {
        if (!this.configuration) {
            this.configuration = new Configuration(
                await this.configurationLoader.loadConfigurationMapAsync()
            );
        }

        return this.configuration;
    }

    /**
     * Retrieve a value from the config
     * @param key
     * @returns the config value for `key`, or undefined, if the config does not have a corresponding value
     */
    async getStringValueAsync(key: TStringConfigEntries): Promise<string | undefined> {
        const configuration = await this.getConfigurationAsync();
        return configuration.getStringValue(key);
    }

    /**
     * Retrieve a value from the config
     * @param key
     * @param defaultValue
     * @returns the config value for `key`, or `defaultValue`, if the config does not have a corresponding value
     */
    async getStringValueOrDefaultAsync(
        key: TStringConfigEntries,
        defaultValue: string
    ): Promise<string> {
        const configuration = await this.getConfigurationAsync();
        return configuration.getStringValueOrDefault(key, defaultValue);
    }

    /**
     * Retrieve a value from the config
     * @param key
     * @returns the config value for `key`, or undefined, if the config does not have a corresponding value
     */
    async getBooleanValueAsync(key: TBooleanConfigEntries): Promise<boolean | undefined> {
        const configuration = await this.getConfigurationAsync();
        return configuration.getBooleanValue(key);
    }

    /**
     * Retrieve a value from the config
     * @param key
     * @param defaultValue
     * @returns the config value for `key`, or `defaultValue`, if the config does not have a corresponding value
     */
    async getBooleanValueOrDefaultAsync(
        key: TBooleanConfigEntries,
        defaultValue: boolean
    ): Promise<boolean> {
        const configuration = await this.getConfigurationAsync();
        return configuration.getBooleanValueOrDefault(key, defaultValue);
    }

    /**
     * Retrieve a value from the config
     * @param key
     * @returns the config value for `key`, or undefined, if the config does not have a corresponding value
     */
    async getNumberValueAsync(key: TNumberConfigEntries): Promise<number | undefined> {
        const configuration = await this.getConfigurationAsync();
        return configuration.getNumberValue(key);
    }

    /**
     * Retrieve a value from the config
     * @param key
     * @param defaultValue
     * @returns the config value for `key`, or `defaultValue`, if the config does not have a corresponding value
     */
    async getNumberValueOrDefaultAsync(
        key: TNumberConfigEntries,
        defaultValue: number
    ): Promise<number> {
        const configuration = await this.getConfigurationAsync();
        return configuration.getNumberValueOrDefault(key, defaultValue);
    }
}
