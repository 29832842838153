import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { IPasswordResetState } from './PasswordResetStateHelper';
import { IPasswordResetStateHelper } from './IPasswordResetStateHelper';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { IRouteHelper } from '../../../navigation';
import { Translation } from '@quino/ui';
import { tKey } from '../../../lang/TranslationKeys';
import { PasswordTextField } from '../PasswordTextField/PasswordTextField';
import { ErrorMessage } from '../../Util/ErrorMessage';
import { Typography } from '../../Util';
import { errorMessageProps, useTextBoxFocusRef } from '../../Util/Devextreme/DevextremeHelpers';
import { useService } from '../../../ioc/hook/useService';
import { useI18n } from '../../../lang/useI18n';
import { ScrollablePopup } from '../../Util/Devextreme/ScrollablePopup';
import { PopupButtonBar } from '../../Util/Devextreme/PopupButtonBar';

type TStyles = {
    authFormRoot: string;
    authFormForm: string;
    authFormButtonBar: string;
    authFormTextFields: string;
};

const styles: TStyles = require('../AuthForm.less');

export const PasswordReset = () => {
    const stateHelper = useService<IPasswordResetStateHelper>(
        SHARED_SERVICE_IDENTIFIER.IPASSWORDRESETSTATEHELPER
    );
    const routeHelper = useService<IRouteHelper>(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER);
    const i18n = useI18n();

    const history = useHistory();

    const { params } = useRouteMatch();
    const secretToken = params['token'];

    const initialFocusRef = useTextBoxFocusRef();

    const [state, setState] = useState<IPasswordResetState>({
        username: '',
        password: '',
        passwordRepeat: '',
        isValid: true,
        processCompleted: false,
    });

    const [inProgress, setInProgress] = useState(false);

    const updateUsername = (username: string) => {
        setState((oldState) => stateHelper.updateUsername(oldState, username));
    };

    const updatePassword = (password: string) => {
        setState((oldState) => stateHelper.updatePassword(oldState, password));
    };

    const updatePasswordRepeat = (passwordRepeat: string) => {
        setState((oldState) => stateHelper.updatePasswordRepeat(oldState, passwordRepeat));
    };

    const submit = async (event: any) => {
        event.preventDefault();
        setInProgress(true);
        setState(await stateHelper.submitAsync(state, secretToken));
        setInProgress(false);
    };

    const goToLogin = () => {
        history.push(routeHelper.getLoginUrl());
    };

    const renderErrorMessage = (errorMessage?: string) => {
        if (!errorMessage) {
            return null;
        }

        return <ErrorMessage>{errorMessage}</ErrorMessage>;
    };

    const {
        username,
        password,
        passwordRepeat,
        errorMessage,
        passwordError,
        passwordRepeatError,
        usernameError,
    } = state;

    return (
        <div className={styles.authFormRoot}>
            <form autoComplete="off" className={styles.authFormForm} onSubmit={submit}>
                <Typography>
                    <h5>
                        <Translation i18nKey={tKey('literal.CustomLiterals.PasswordReset.Title')} />
                    </h5>
                </Typography>

                <TextBox
                    id="userId"
                    className={styles.authFormTextFields}
                    value={username}
                    width={'100%'}
                    ref={initialFocusRef}
                    stylingMode={'underlined'}
                    placeholder={i18n.t(
                        'literal.CustomLiterals.PasswordReset.UserNamePlaceholderText'
                    )}
                    onInput={({ event }) => updateUsername((event as any).target.value || '')}
                    {...errorMessageProps(usernameError)}
                />

                <PasswordTextField
                    id="password"
                    className={styles.authFormTextFields}
                    width={'100%'}
                    value={password}
                    placeholder={i18n.t(
                        'literal.CustomLiterals.PasswordReset.PasswordPlaceholderText'
                    )}
                    onInput={({ event }) => updatePassword((event as any).target.value || '')}
                    {...errorMessageProps(passwordError)}
                />

                <PasswordTextField
                    id="passwordRepeat"
                    className={styles.authFormTextFields}
                    width={'100%'}
                    value={passwordRepeat}
                    placeholder={i18n.t(
                        'literal.CustomLiterals.PasswordReset.Password2PlaceholderText'
                    )}
                    onInput={({ event }) => updatePasswordRepeat((event as any).target.value || '')}
                    {...errorMessageProps(passwordRepeatError)}
                />

                {renderErrorMessage(errorMessage)}

                <div className={styles.authFormButtonBar}>
                    <Button
                        useSubmitBehavior={true}
                        stylingMode={'contained'}
                        type={'default'}
                        disabled={inProgress}
                        text={i18n.t('literal.CustomLiterals.PasswordReset.SetNewPasswordButton')}
                        icon={'arrowright'}
                    />
                    <LoadPanel visible={inProgress} />
                </div>
                <p>
                    <a href={'#'} onClick={goToLogin}>
                        <Translation
                            i18nKey={tKey('literal.CustomLiterals.PasswordReset.BackButton')}
                        />
                    </a>
                </p>
            </form>
            <ScrollablePopup
                visible={state.processCompleted}
                title={i18n.t('literal.CustomLiterals.PasswordReset.Dialog.Title')}
                width={'auto'}
                height={'auto'}
                showCloseButton={false}
                closeOnOutsideClick={false}
                dragEnabled={false}
            >
                <Translation i18nKey={tKey('literal.CustomLiterals.PasswordReset.Dialog.Text')} />
                <PopupButtonBar>
                    <Button
                        onClick={goToLogin}
                        type={'default'}
                        text={i18n.t(
                            tKey('literal.CustomLiterals.PasswordReset.Dialog.BackButton')
                        )}
                    />
                </PopupButtonBar>
            </ScrollablePopup>
        </div>
    );
};
