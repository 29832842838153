import { IPasswordPolicy } from './IPasswordPolicy';
import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { IPasswordValidatorConfig } from '../IPasswordValidatorConfig';
import { QuinoCoreServiceSymbols } from '@quino/core';
import { IPerformaI18n } from '../../lang';

@injectable()
export class NumberPresencePolicy implements IPasswordPolicy {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPASSWORDVALIDATORCONFIG)
        private config: IPasswordValidatorConfig,
        @inject(QuinoCoreServiceSymbols.II18N) private i18n: IPerformaI18n
    ) {}

    async applyAsync(password: string): Promise<string[]> {
        if ((await this.config.needsNumberCharacterAsync()) && password.search(/\d/) === -1) {
            return [
                this.i18n.t(
                    'literal.CustomLiterals.PasswordPolicy.Errors.MissingNumberCharacter',
                    {}
                ),
            ];
        }
        return [];
    }
}
