import { IUserProfileDTO } from './DTOs/IUserProfileDTO';
import { injectable } from 'inversify';
import { ICurrentUserProfileCache } from './ICurrentUserProfileCache';

/**
 * Default in-memory cache implementation of {@link ICurrentUserProfileCache}.
 * @implements ICurrentUserProfileCache
 */
@injectable()
export class CurrentUserProfileCache implements ICurrentUserProfileCache {
    private cachedPromise: Promise<IUserProfileDTO> | undefined;

    /** @inheritdoc */
    clear(): void {
        this.cachedPromise = undefined;
    }

    /** @inheritdoc */
    getAsync(): Promise<IUserProfileDTO> | undefined {
        return this.cachedPromise;
    }

    /** @inheritdoc */
    populate(currentUserProfilePromise: Promise<IUserProfileDTO>): void {
        this.cachedPromise = currentUserProfilePromise;
    }
}
