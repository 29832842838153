import * as React from 'react';
import { useEffect, useState } from 'react';
import { IQuinoMetaPanelExtensionProps, ObjectBookmark, useService } from '@quino/ui';
import { IExportExcelService, ILogger, QuinoCoreServiceSymbols } from '@quino/core';
import { Button } from 'devextreme-react/button';

import { useTranslation } from '../../lang/useTranslation';
import { ErrorMessage } from '../Util/ErrorMessage';
import { Popup } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import { formatMessage } from 'devextreme/localization';

interface IOwnProps {
    visible: boolean;
}

type TStyles = {
    exportButtonBlock: string;
};

const styles: TStyles = require('./PerformaMetaPanelExportButton.less');

export const PerformaMetaPanelExportButton: React.FunctionComponent<
    IQuinoMetaPanelExtensionProps & IOwnProps
> = (props) => {
    const [isExcelExporting, setIsExcelExporting] = useState(false);
    const [isExportFailed, setIsExportFailed] = useState(false);
    const excelRenderService = useService<IExportExcelService>(
        QuinoCoreServiceSymbols.IExportExcelService
    );
    const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

    const exportButtonHint = formatMessage('dxDataGrid-exportToExcel', '');
    const exportFailedMessage: string = useTranslation(
        'literal.CustomLiterals.DataBrowser.Detail.ExportFailed'
    );
    const exportFailedTitleMessage: string = useTranslation(
        'literal.CustomLiterals.DataBrowser.Detail.ExportFailed.Title'
    );

    useEffect(
        () => {
            (async () => {
                if (isExcelExporting) {
                    if (props.bookmark) {
                        const objectBookmark = props.bookmark as ObjectBookmark;
                        const layout = objectBookmark.layout;
                        const genericObject = objectBookmark.genericObject;
                        try {
                            await excelRenderService.export(genericObject, layout);
                            setIsExcelExporting(false);
                            setIsExportFailed(false);
                        } catch (error) {
                            logger.logError(error);
                            setIsExcelExporting(false);
                            setIsExportFailed(true);
                        }
                    } else {
                        setIsExcelExporting(false);
                    }
                }
            })();
        },
        [isExcelExporting]
    );

    return (
        <div hidden={!props.visible}>
            {isExportFailed && (
                <Popup
                    visible={isExportFailed}
                    title={exportFailedTitleMessage}
                    showCloseButton={true}
                    closeOnOutsideClick={true}
                    onHidden={() => setIsExportFailed(false)}
                    height={200}
                    width={250}
                >
                    <ErrorMessage>{exportFailedMessage}</ErrorMessage>
                </Popup>
            )}
            <div className={styles.exportButtonBlock}>
                <Button
                    icon={'xlsxfile'}
                    type={'normal'}
                    hint={exportButtonHint}
                    disabled={isExcelExporting}
                    onClick={() => setIsExcelExporting(true)}
                />
            </div>
            <LoadPanel visible={isExcelExporting} />
        </div>
    );
};
