import React, { useEffect, useState } from 'react';

type TStyles = {
    avatar: string;
};

const styles: TStyles = require('./Avatar.less');

interface IProps {
    label: string;
    src?: string;
    size?: number;
}

export const Avatar: React.FC<IProps> = (props) => {
    const [image, setImage] = useState<HTMLImageElement>();

    useEffect(
        () => {
            if (!props.src) {
                setImage(undefined);
                return;
            }

            const imageObject = new Image();
            imageObject.src = props.src;
            imageObject.onload = () => {
                setImage(imageObject);
            };

            return () => {
                imageObject.onload = null;
            };
        },
        [props.src]
    );

    const size = props.size || 40;
    const fontSizeRem = (size / 40) * 1.25;

    const avatarStyles = { height: size, width: size, fontSize: `${fontSizeRem}rem` };

    return (
        <div className={styles.avatar} style={avatarStyles}>
            {image && <img src={image.src} alt={props.label} />}
            {!image && <div>{props.label}</div>}
        </div>
    );
};
