import { inject, injectable } from 'inversify';
import { SHARED_SERVICE_IDENTIFIER } from '../ioc/sharedIdentifiers';
import { IPerformaUrlManager } from './IPerformaUrlManager';
import { IRequestBuilder, QuinoCoreServiceSymbols } from '@quino/core';
import { IStorageService } from './IStorageService';
import { IFileMetadata } from './DTOs/IFileMetadata';
import { IImageFetcher } from './IImageFetcher';

@injectable()
export class StorageService implements IStorageService {
    constructor(
        @inject(SHARED_SERVICE_IDENTIFIER.IPERFORMAURLMANAGER)
        private urlManager: IPerformaUrlManager,
        @inject(QuinoCoreServiceSymbols.IRequestBuilder) private requestBuilder: IRequestBuilder,
        @inject(SHARED_SERVICE_IDENTIFIER.IIMAGEFETCHER) private imageFetcher: IImageFetcher
    ) {}

    tryLoadPersistentStorageObjectUrlAsync(id: string): Promise<string | undefined> {
        const url = this.urlManager.getPersistentStorageDownloadUrl(id);
        return this.imageFetcher.tryLoadObjectUrl(url);
    }

    async deletePersistentStorageAsync(id: string): Promise<any> {
        const url = this.urlManager.deletePersistentStorageUrl(id);
        return await this.requestBuilder
            .create(url, 'delete')
            .requiresAuthentication()
            .fetch();
    }

    async getStorageMetadataAsync(byId: string): Promise<IFileMetadata> {
        const url = this.urlManager.getStorageMetadataUrl(byId);
        return await this.requestBuilder
            .create(url, 'get')
            .requiresAuthentication()
            .fetchJson();
    }

    async findStorageMetadataAsync(
        ownerKey: string,
        ownerValue: string,
        entityKey: string,
        entityValue: string
    ): Promise<IFileMetadata[]> {
        const url = this.urlManager.findStorageMetadataUrl(
            ownerKey,
            ownerValue,
            entityKey,
            entityValue
        );
        return await this.requestBuilder
            .create(url, 'get')
            .requiresAuthentication()
            .fetchJson();
    }

    async setCustomAttributesToStorageMetadata(
        id: string,
        customMetadata: { [key: string]: string }
    ): Promise<any> {
        const url = this.urlManager.setCustomAttributesToStorageMetadataUrl(id);
        return await this.requestBuilder
            .create(url, 'post')
            .requiresAuthentication()
            .setJsonPayload(customMetadata)
            .fetch();
    }
}
